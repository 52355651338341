import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  createTheme,
} from "@mui/material";
import css from "./Events.module.css";
import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { eventdetails } from "./EventsDetails";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AppSlider } from "./AppSlider";
import { blue, blueGrey, grey } from "@mui/material/colors";
import {
  Link,
  unstable_HistoryRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import { get } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";

import moment from "moment";

const theme = createTheme();

const TypographyMain = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: "25px",
  fontSize: "40px",
  color: "#f86f03",
  fontWeight: "bold",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    // textAlign:"center",
    // margin:0,
    // padding:"25px"
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    margin: 0,
    padding: "0",
  },
}));

const Btn = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff6d34",
  border: "1px solid #ff6d34",
  color: " black",
  fontSize: "12px",
  fontFamily: "serif",
  marginRight: "13px",
  [theme.breakpoints.down("md")]: {
    marginRight: "0px",
  },
}));

const Title = styled(Box)(() => ({
  color: "#1976D2",
  fontSize: "17px",
  fontWeight: "bold",
  "&:hover": { color: "#ef6c00" },
}));

const Founder = styled(Box)(() => ({
  color: grey[500],
  fontSize: "14px",
  "&:hover": { color: "#ff6d34" },
}));

const Random = styled(Box)(() => ({
  display: "flex",
  gap: 5,
}));

const Content = styled(Typography)(() => ({
  fontSize: "20px",
  marginTop: "3%",
  color: "#2c2c2c",
  fontFamily: "Muli",
  lineHeight: "30px",
  fontWeight: "800",
  gap: "5%",
  "&:hover": { color: "#ef6c00" },
}));

const Cardcontent = styled(CardContent)(({ theme }) => ({
  paddingLeft: "0px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CardImageWrapper = styled(Box)(({ theme }) => ({
  width: "95%",
  position: "relative",
  "&:hover": {
    position: "relative",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 2.5)",
  },
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ViewMore = styled(Box)(() => ({
  // fontSize: 'large',
  fontFamily: "Muli",
  color: "#ef6c00",
  marginTop: "5%",
  "&:hover": {
    color: "#1976D2",
  },
  "&:after": {
    width: "20%",
    marginTop: "-1%",
    content: '""',
    borderBottom: "2px solid #dfdfdf",
    display: "block",
    transition: "border-color 0.35s ease",
  },
}));

const AnchorBox = styled(Box)({
  "&::after": {
    content: '""',

    height: "3px",

    marginTop: "4px",

    backgroundColor: "#dfdfdf",

    display: "block",

    fontSize: "1rem",

    transform: "scale(0,1)",

    transition: "transform 0.35s ease",
  },

  "&:hover::after": {
    width: "100%",

    backgroundColor: "#1976D2",

    transform: "scale(1,1)",
  },
});

const Our = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  textTransform: "uppercase",
  fontSize: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));
const Random1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "15px",
  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("xs")]: {
    marginTop: "10px",
  },
}));

const Month = styled(Typography)(({ theme }) => ({
  marginTop: "8px",
  fontSize: "14px",
  marginLeft: "10px",
  paddingLeft: "15%",

  color: "white",

  // marginTop:'5%',

  [theme.breakpoints.down("md")]: {
    paddingLeft: "30%",
  },

  [theme.breakpoints.down("sm")]: {
    paddingLeft: "25%",
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: "30%",
  },
}));

const Arc = styled(Box)(({ theme }) => ({
  position: "absolute",

  right: 0.1,

  // borderRadius:'0 5px 0 90%',

  clipPath: "circle(85.8% at 100% 0);",

  paddingBottom: "10%",

  backgroundColor: "#00BDA6",

  // padding:'11px 0 23px',

  zIndex: "2",

  [theme.breakpoints.down("md")]: {
    paddingBottom: "13%",
  },

  [theme.breakpoints.down("sm")]: {
    paddingBottom: "13%",
  },
}));
export const CardComponent = ({
  elem,
  makeStyle = { minWidth: "30%", paddingLeft: "35%" },
}) => {
  const [showFull, setShowFull] = useState(false);
  const navigate = useNavigate();
  const handleReadMoreClick = () => {
    navigate(`/events/${elem._id}`, { state: elem });
    if (showFull) {
      navigate("/");
    } else {
      setShowFull(!showFull);
    }
  };
  useEffect(() => {
    const currentPath = window.location.pathname;
    const isFullContentPage = currentPath === `/${elem.id}`;
    setShowFull(isFullContentPage);
  }, [elem.id]);

  let onlyDay = elem.fromDate;

  const date = moment(onlyDay);
  const specificDate = date.format("Do");

  const specificMonth = date.format("MMMM ,YYYY");

  const time = moment(elem.fromDate);

  const specificTime = time.format("h:mm A");

  return (
    <ThemeProvider theme={theme}>
      <CardImageWrapper>
        <Arc minWidth={makeStyle.minWidth}>
          <Month fontSize="0.7rem" className={css.month}>
            {specificMonth}
          </Month>
          <Typography fontSize="1.5rem" className={css.date}>
            {specificDate}
          </Typography>
        </Arc>
        <CardImage
          className={css.image}
          component="img"
          image={elem?.image?.link}
          alt="Live from space album cover"
        />
      </CardImageWrapper>
      <Cardcontent>
        <Random>
          <Title>{elem.eventTitle}</Title>
        </Random>
        <Content>
          {showFull
            ? elem.shortEvent
            : elem.shortEvent.substring(0, 40) + "..."}
        </Content>
        <Button
          variant="p"
          mt={6}
          onClick={handleReadMoreClick}
          sx={{
            color: "#ef6c00",
            padding: "0px 0px",
            borderBottom: "2px solid #dfdfdf",
            paddingTop: "5%",
            "&:hover": {
              color: "#1976D2",
            },
          }}
        >
          <AnchorBox className={css.after}>
            {showFull ? "View Less" : "View More"}
          </AnchorBox>
        </Button>
      </Cardcontent>
    </ThemeProvider>
  );
};

const Events = () => {
  const { selectedSetting } = useContext(SettingContext);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [totalEvents, setTotalEvents] = useState(0);

  const [events, setEvents] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    sliderRef.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.slickNext();
  };
  let sliderRef;

  const getAllEvents = async () => {
    try {
      const { data } = await get(urls.events.getEventListHome);
      console.log(data.result.data, "compo 2 events");
      setEvents(data.result.data);
      setTotalEvents(data.result.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <>
      <Container sx={{ marginTop: "8rem" }}>
        <ThemeProvider theme={theme}>
          <TypographyMain variant="h5" mt={2}>
            OUR EVENTS
          </TypographyMain>
          <Random1>
            <ChevronLeftIcon
              onClick={handlePrevClick}
              style={{ border: "1px solid black", marginRight: "15px" }}
            />
            <ChevronRightIcon
              onClick={handleNextClick}
              style={{ border: "1px solid black" }}
            />
          </Random1>
        </ThemeProvider>

        <AppSlider
          {...settings}
          ref={(c) => (sliderRef = c)}
          sx={{ justifyContent: "space-between" }}
        >
          {events.map((elem, index) => (
            <CardComponent key={index} elem={elem} />
          ))}
        </AppSlider>
      </Container>
    </>
  );
};
export default Events;
