import React, { useState } from "react";
import {
  Box,
  Card,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
  FormHelperText,
  Typography,
  styled,
  Container,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CloudUploadRounded } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavTopnav from "../components/NavTopnav/NavTopnav";

let styles = {
  card: {
    padding: 15,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
    // marginLeft:"2%"
  },
};

const style = (theme) => ({
  field: {
    margin: "10px 0",
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const AboutTitle = styled(Box)(({ theme }) => ({
  // color: "#F86F03",
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const FounderTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(/admissionbg1.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "250px",
}));

const Admission = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavTopnav />
      <OuterBox>
        <Typography
          variant="h4"
          color="#F86F03"
          textAlign="center"
          fontWeight="700"
          marginBottom="30px"
        >
          ADMISSION FORM
        </Typography>
        <Container>
          <form>
            <div style={{ margin: "15px" }}>
              <Card style={styles.card}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3} lg={3}>
                      <h4 style={{ color: "#1b3779", fontSize: "18px" }}>
                        Basic Information:
                      </h4>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Name"
                        name="name"
                        required
                        style={styles.textfield}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date of Birth"
                          inputFormat="DD-MM-YYYY"
                          required
                          renderInput={(params) => (
                            <TextField
                              style={styles.textfield}
                              variant="standard"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl
                        variant="standard"
                        style={styles.textfield}
                        required
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="gender"
                        >
                          <MenuItem
                            value={"male"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            Male
                          </MenuItem>
                          <MenuItem
                            value={"female"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            Female
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl variant="standard" style={styles.textfield}>
                        <InputLabel id="demo-simple-select-filled-label">
                          Blood Group
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="bloodGroup"
                        >
                          <MenuItem
                            value={"a+"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            A+
                          </MenuItem>
                          <MenuItem
                            value={"a-"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            A-
                          </MenuItem>
                          <MenuItem
                            value={"b+"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            B+
                          </MenuItem>
                          <MenuItem
                            value={"b-"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            B-
                          </MenuItem>
                          <MenuItem
                            value={"o+"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            O+
                          </MenuItem>
                          <MenuItem
                            value={"o-"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            O-
                          </MenuItem>
                          <MenuItem
                            value={"ab+"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            AB+
                          </MenuItem>
                          <MenuItem
                            value={"ab-"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            AB-
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Religion"
                        name="religion"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Caste"
                        name="caste"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Caste Income Certificate Number"
                        name="cicn"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Mother Tongue"
                        name="motherTongue"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Birth Place"
                        name="birthPlace"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        label="Aadhar No"
                        type="number"
                        id="filled-"
                        name="aadharNo"
                        variant="standard"
                        InputProps={{
                          inputProps: {
                            pattern: "^\\d{12}$",
                          },
                        }}
                        style={styles.textfield}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
              <Card style={styles.card}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3} lg={3}>
                        <h4
                          style={{
                            color: "#1b3779",
                            marginLeft: "16px",
                            marginTop: "16px",
                            fontSize: "18px",
                            fontSize: "18px",
                          }}
                        >
                          Contact Information:
                        </h4>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Contact Number"
                        name="contactNumber"
                        type="number"
                        required
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Guardian Name"
                        name="guardianName"
                        required
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        variant="standard"
                        name="guardianPhone"
                        type="number"
                        required
                        label="Guardian Number"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        variant="standard"
                        name="guardianPhone2"
                        type="number"
                        style={styles.textfield}
                        label="Alternate Number"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl variant="standard" style={styles.textfield}>
                        <InputLabel id="demo-simple-select-filled-label">
                          Relation With Guardian
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="guardianRelation"
                        >
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            Father
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            Mother
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            Sister
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            Brother
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            Uncle
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            Maternal Uncle
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            Other Relative
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="National ID"
                        name="nationId"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Present Address"
                        name="presentAddress"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        label="Permanent Address"
                        name="permanentAddress"
                        variant="standard"
                        style={styles.textfield}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
              <Card style={styles.card}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3} lg={3}>
                        <h5
                          style={{
                            color: "#1b3779",
                            marginLeft: "16px",
                            marginTop: "16px",
                            fontSize: "18px",
                            fontSize: "18px",
                          }}
                        >
                          Academic Information:
                        </h5>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl
                        variant="standard"
                        style={styles.textfield}
                        required
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Class
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="class"
                        >
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            6 th
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            7 th
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            8 th
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl
                        variant="standard"
                        style={styles.textfield}
                        required
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Section
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="section"
                        >
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            A
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            B
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 12, fontWeight: 500 }}>
                            C
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Roll No"
                        name="rollNo"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}></Grid>
                  </Grid>
                </Box>
              </Card>
              <Card style={styles.card}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <h5
                          style={{
                            color: "#1b3779",
                            marginLeft: "16px",
                            marginTop: "16px",
                            fontSize: "18px",
                            fontSize: "18px",
                          }}
                        >
                          Previous School Information:
                        </h5>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Previous School"
                        name="prevSchool"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Previous Class"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="TC Number"
                        name="tcNo"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <div
                          style={{
                            marginLeft: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontSize: 12, color: "#666" }}>
                            Transfer Certificate:
                          </div>
                          <div>
                            <IconButton
                              sx={{
                                color: "#1b3779",
                                ":hover": { color: "#1b3779" },
                              }}
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                            >
                              <input
                                hidden
                                label="Transfer Certificate"
                                accept=".pdf, .doc, .docx, .txt"
                                type="file"
                                name="transferCertificate"
                              />
                              <CloudUploadRounded />
                            </IconButton>
                          </div>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
              <Card style={styles.card}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <h5
                          style={{
                            color: "#1b3779",
                            marginLeft: "16px",
                            marginTop: "16px",
                            fontSize: "18px",
                          }}
                        >
                          Father Information:
                        </h5>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Father Name"
                        name="fatherName"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Father Phone"
                        name="fatherPhone"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Father Education"
                        name="fatherEdu"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Father Profession"
                        name="fatherProfession"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Father Designation"
                        name="fatherDesig"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <div
                          style={{
                            marginLeft: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontSize: 12, color: "#666" }}>
                            Father Photo
                          </div>
                          <div>
                            <IconButton
                              sx={{
                                color: "#1b3779",
                                ":hover": { color: "#1b3779" },
                              }}
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                            >
                              <input
                                hidden
                                label="Father Photo"
                                accept=".jpg, .jpeg, .png, .gif"
                                name="fatherPhoto"
                                type="file"
                              />
                              <CloudUploadRounded />
                            </IconButton>
                          </div>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
              <Card style={styles.card}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <h5
                          style={{
                            color: "#1b3779",
                            marginLeft: "16px",
                            marginTop: "16px",
                            fontSize: "18px",
                          }}
                        >
                          Mother Information:
                        </h5>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Mother Name"
                        name="motherName"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Mother Phone"
                        name="motherPhone"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Mother Education"
                        name="motherEdu"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Mother Profession"
                        name="motherProfession"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Mother Designation"
                        name="motherDesignation"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <div
                          style={{
                            marginLeft: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontSize: 12, color: "#666" }}>
                            Mother Photo
                          </div>
                          <div>
                            <IconButton
                              sx={{
                                color: "#1b3779",
                                ":hover": { color: "#1b3779" },
                              }}
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                            >
                              <input
                                name="motherPhoto"
                                hidden
                                label="Mother Photo"
                                accept=".jpg, .jpeg, .png, .gif"
                                type="file"
                              />
                              <CloudUploadRounded />
                            </IconButton>
                          </div>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
              <Card style={styles.card}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <h5
                          style={{
                            color: "#1b3779",
                            marginLeft: "16px",
                            marginTop: "16px",
                            fontSize: "18px",
                          }}
                        >
                          Other Information:
                        </h5>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        type="email"
                        name="email"
                        label="Email"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Health Condition"
                        name="healthCondition"
                        style={styles.textfield}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl variant="standard" style={styles.textfield}>
                        <InputLabel id="demo-simple-select-filled-label">
                          Hostel
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="hosteMember"
                        >
                          <MenuItem
                            value={"yes"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            Yes
                          </MenuItem>
                          <MenuItem
                            value={"no"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            No
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl variant="standard" style={styles.textfield}>
                        <InputLabel id="demo-simple-select-filled-label">
                          Transport
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem
                            value={"yes"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            Yes
                          </MenuItem>
                          <MenuItem
                            value={"no"}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            No
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                      <TextField
                        id="filled-"
                        variant="standard"
                        label="Other Info"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <div
                          style={{
                            marginLeft: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontSize: 12, color: "#666" }}>
                            Student Photo
                          </div>
                          <div>
                            <IconButton
                              sx={{
                                color: "#1b3779",
                                ":hover": { color: "#1b3779" },
                              }}
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                            >
                              <input
                                hidden
                                accept=".jpg, .jpeg, .png, .gif"
                                type="file"
                                name="studentPhoto"
                              />
                              <CloudUploadRounded />
                            </IconButton>
                          </div>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
              <Card style={styles.card}>
                <Box sx={{ flexGrow: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 5,
                    }}
                  >
                    <Button variant="outlined">Cancel</Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={styles.Button}
                    >
                      Submit
                    </Button>
                  </div>
                </Box>
              </Card>
            </div>
          </form>
        </Container>
      </OuterBox>
    </>
  );
};

export default Admission;
