import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled, Box, Container, Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { grey, green, blue } from "@mui/material/colors";

import css from "./Custom.module.css";
import moment from "moment";
import { useEffect } from "react";

const Random = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const Times = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  gap: "5px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

const Para = styled(Typography)(() => ({
  fontSize: "15px",
  lineHeight: "25px",
  color: grey[700],
}));
const TimeIcon = styled(AccessTimeIcon)(() => ({
  fontSize: "0.7rem",
  marginTop: "2px",
  color: "#f86f03",
}));
const Time = styled(Typography)(() => ({
  fontSize: "0.7rem",
}));

const Date = styled(Typography)(() => ({
  fontSize: "15px",
  textAlign: "center",
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  marginTop: "30px",
  width: "90%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomCard = styled(Card)(({ theme }) => ({
  minWidth: 450,
  maxWidth: 545,
  boxShadow: "2px 2px 2px 2px gray",
  [theme.breakpoints.down(600)]: {
    minWidth: 400,
  },
  [theme.breakpoints.down(450)]: {
    minWidth: 350,
  },
  [theme.breakpoints.down(400)]: {
    minWidth: 300,
  },
}));

const CustomEventDetails = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onlyDay = data.fromDate;

  const date = moment(onlyDay);
  const specificDate = date.format("Do");

  const specificMonth = date.format("MMMM ,YYYY");

  const time = moment(data.fromDate);
  const specificTime = time.format("h:mm A");

  return (
    <>
      <Container>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CustomCard className={css.container}>
            <Typography
              variant="h4"
              sx={{ textAlign: "center" }}
              className={css.date}
            >
              {specificDate}
            </Typography>
            <Date> {specificMonth}</Date>
            <CardMedia sx={{ height: 400 }} image={data?.image?.link} />
            <CardContent>
              <Typography
                gutterBottom
                sx={{ textAlign: "center" }}
                className={css.title}
              >
                {data.eventTitle}
              </Typography>
              <Random>
                <Times>
                  <TimeIcon></TimeIcon>
                  <Time className={css.time}>{specificTime}</Time>
                </Times>
              </Random>
              <br />
              <Para>{data.shortEvent}</Para>
            </CardContent>
          </CustomCard>
        </Box>
      </Container>
    </>
  );
};
export default CustomEventDetails;
