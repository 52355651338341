import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  TableContainer,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Menu, Search } from "@mui/icons-material";
import SectionSubHeader from "../../SectionSubHeader";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { setMonth } from "date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import Overview from "../Student/Overview";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { makeStyles } from "@mui/styles";
import { BsBox2, BsBoxArrowDown } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "1px solid #e0e0e0",
    fontSize: "15px",
    fontWeight: 600,
    color: "grey",
    padding: "4px",
    marginRight: "4px", // Add margin for space
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 460,
  bgcolor: "background.paper",
  p: 4,
};
const ItemInventory = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [fromDate, setFromDate] = useState(dayjs(Date.now()));
  const [toDate, setToDate] = useState(dayjs(Date.now()));
  const [findLoader, setFindLoader] = useState(false);
  const [stock, setStock] = useState([]);
  const [overView, setOverView] = useState([]);
  const [totalPurpose, setTotalPurpose] = useState([]);
  const [totalInvenCount, setTotalInventCount] = useState([]);
  const [totalInvenAmount, setTotalInventAmount] = useState([]);
  const [issueAmount, setIssueAmount] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const classes = useStyles();

  console.log(overView, "kkkkl");

  const navigate = useNavigate();
  let { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabValue(1);
    }
  }, []);
  const styles = {
    button: {
      backgroundColor: "Primary",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  let handleNavigate = () => {
    navigate("/dashboard/vendor/", { state: "inventory" });
  };

  let handleNavigateItem = () => {
    navigate("/dashboard/item", { state: "item" });
  };
  let handleNavigateSell = () => {
    navigate("/dashboard/sold", { state: "sold" });
  };
  let handleNavigateIssue = () => {
    navigate("/dashboard/issueDetails", { state: "issue" });
  };

  let handleNavigateStock = () => {
    navigate("/dashboard/transaction", { state: "stock" });
  };

  const handleSearchHistory = async (e) => {
    setFindLoader(true);

    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.stockList.getAll, {
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setStock(searchHistory.data.data);
      setOverView(searchHistory.data.purposesTotalPrice);
      setTotalPurpose(searchHistory.data.totalAllPurposeTotalPrice);
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  const handleTotalInventoryCount = async (e) => {
    setFindLoader(true);

    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.transaction.totalInventoryCount, {
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setTotalInventCount(searchHistory.data);
      console.log(searchHistory.data, "setTotalInventCount");
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };
  const handleTotalInventoryAmount = async (e) => {
    setFindLoader(true);

    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.totalInventoryAmount.get, {
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setTotalInventAmount(searchHistory.data.data);
      setIssueAmount(searchHistory.data.totalIssueQuantity);
      console.log(searchHistory.data.totalIssueQuantity, "totalIssueQuantity");
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  console.log(issueAmount, "issueAmount");

  return (
    <div>
      <SectionSubHeader title="Item Stock" style={{ margin: "15px" }} />
      <div>
        <div style={{ margin: "15px" }}>
          <Card style={{ padding: "10px", margin: "10px 0px" }}>
            <div style={styles.cardButton}>
              <Button variant="contained">Excel</Button>

              <Button variant="contained">Pdf</Button>
            </div>
          </Card>

          <Paper sx={{ mt: 3 }}>
            <Grid container spacing={1} sx={{ ml: 0.5 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "Raleway",
                  }}
                >
                  Select By Date Range
                </Typography>
              </Grid>
            </Grid>

            <Box>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearchHistory();
                  handleTotalInventoryCount();
                  handleTotalInventoryAmount();
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5} lg={4} mt={1.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From Date"
                        inputFormat="DD-MM-YYYY"
                        value={fromDate}
                        onChange={(newValue) => {
                          setFromDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={5} lg={4} mt={1.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // variant="standard"
                        label="To Date"
                        inputFormat="DD-MM-YYYY"
                        value={toDate}
                        onChange={(newValue) => {
                          setToDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    lg={2}
                    sx={{ mt: { md: 1.5, lg: 1.5 } }}
                  >
                    <LoadingButton
                      loading={findLoader}
                      variant="contained"
                      // size="small"
                      type="submit"
                    >
                      Find
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>

              <Grid container spacing={2} p={2}>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Button
                    variant="contained"
                    onClick={() => handleNavigateSell()}
                    sx={{ width: "100%" }}
                  >
                    Sell
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Button
                    variant="contained"
                    onClick={() => handleNavigateIssue()}
                    sx={{ width: "100%" }}
                  >
                    Issue
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Button
                    variant="contained"
                    onClick={() => handleNavigateItem()}
                    sx={{ width: "100%" }}
                  >
                    Add Item
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Button
                    variant="contained"
                    onClick={() => handleNavigate()}
                    sx={{ width: "100%" }}
                  >
                    Add vendor
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          {/* <Paper>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={6} sm={2} md={3} lg={1}>
                <Button
                  variant="contained"
                  onClick={() => handleNavigateSell()}
                >
                  Sell
                </Button>
              </Grid>
              <Grid item xs={6} sm={2} md={3} lg={1}>
                <Button
                  variant="contained"
                  onClick={() => handleNavigateIssue()}
                >
                  Issue
                </Button>
              </Grid>
              <Grid item xs={5} sm={3} md={3} lg={1.3}>
                <Button
                  variant="contained"
                  onClick={() => handleNavigateItem()}
                >
                  Add Item
                </Button>
              </Grid>

              <Grid item xs={7} sm={4} md={3} lg={2}>
                <Button
                  // sx={{ mr: 3 }}
                  variant="contained"
                  onClick={() => handleNavigate()}
                >
                  Add vendor
                </Button>
              </Grid>
            </Grid>
          </Paper> */}
          <Paper>
            <Box
              sx={{
                overflow: {
                  xs: "scroll",
                  sm: "visible",
                },
                marginTop: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  mt: "5px",
                  textAlign: "center",
                  marginBottom: "10px",
                  fontFamily: "Raleway",
                }}
              >
                OverView
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    md: "space-around",
                    xs: "flex-start",
                    sm: "space-around",
                  },

                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={2}>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: "8px 15px",
                        color: "grey",
                      }}
                    >
                      <Typography style={{ fontSize: "15px", fontWeight: 600 }}>
                        Total Purchase
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid transparent",
                        padding: "8px 15px",
                      }}
                    >
                      <CurrencyRupeeIcon fontSize="small" />
                      {overView.Purchase ? overView.Purchase : 0}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: "8px 15px",
                        color: "grey",
                      }}
                    >
                      <Typography style={{ fontSize: "15px", fontWeight: 600 }}>
                        {" "}
                        Total Issue
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid transparent",
                        padding: "8px 15px",
                      }}
                    >
                      <CurrencyRupeeIcon fontSize="small" />{" "}
                      {issueAmount.length !== 0 ? issueAmount : 0}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: "8px 15px",
                        color: "grey",
                      }}
                    >
                      <Typography style={{ fontSize: "15px", fontWeight: 600 }}>
                        Total Return
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid transparent",
                        padding: "8px 15px",
                      }}
                    >
                      <CurrencyRupeeIcon fontSize="small" />
                      {overView.Return ? overView.Return : 0}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: "8px 15px",
                        color: "grey",
                      }}
                    >
                      <Typography style={{ fontSize: "15px", fontWeight: 600 }}>
                        Total Inhouse
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid transparent",
                        padding: "8px 15px",
                      }}
                    >
                      <CurrencyRupeeIcon fontSize="small" />
                      {overView.Inhouse ? overView.Inhouse : 0}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: "8px 15px",
                        color: "grey",
                      }}
                    >
                      <Typography style={{ fontSize: "15px", fontWeight: 600 }}>
                        Total Damage
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid transparent",
                        padding: "8px 15px",
                      }}
                    >
                      <CurrencyRupeeIcon fontSize="small" />
                      {overView.Damage ? overView.Damage : 0}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: "8px 15px",
                        color: "grey",
                      }}
                    >
                      <Typography style={{ fontSize: "15px", fontWeight: 600 }}>
                        Total Sell
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        border: "1px solid transparent",
                        padding: "8px 15px",
                      }}
                    >
                      <CurrencyRupeeIcon fontSize="small" />
                      {overView.Sell ? overView.Sell : 0}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box align="center" className={classes.tableCell}>
                      Total Inventory Quantity
                    </Box>
                    <Box align="center">
                      {totalInvenCount.length !== 0 ? totalInvenCount.data : 0}
                      {"  "}Qty
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {" "}
                    <Box align="center" className={classes.tableCell}>
                      Total Inventory Amount
                    </Box>
                    <Box align="center">
                      {issueAmount.length !== 0 ? issueAmount : 0}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ mt: 2 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                margin: "10px",
                fontFamily: "Raleway",
              }}
            >
              List of Stocks
            </Typography>
            <Table>
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Name
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Description
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Total Price
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Total Stock
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stock.map((data, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{data.name}</TableCell>
                    <TableCell align="center">{data.description}</TableCell>
                    <TableCell align="center">
                      <CurrencyRupeeIcon fontSize="small" />
                      {data.totalPrice}
                    </TableCell>
                    <TableCell align="center">{data.totalStock}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!stock.length && stock && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No Data Found
              </Typography>
            )}
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default ItemInventory;
