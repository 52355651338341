import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { Delete } from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const data = [
  {
    name: "6th std",
    male: 6000,
    female: 2600,
    amt: 2400,
  },
  {
    name: "7th std",
    male: 5500,
    female: 3000,
    amt: 2400,
  },
  {
    name: "8th std",
    male: 4000,
    female: 2400,
    amt: 2400,
  },
];

const combineData = (data) => {
  const combinedData = data.map((item) => {
    return {
      name: item.name,
      male: item.male,
      female: item.female,
      total: item.male + item.female,
    };
  });

  return combinedData;
};

const StudentReport = () => {
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [academicYears, setAcademicYears] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const combinedData = combineData(data);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const styles = {
    cardsMargin: {
      marginTop: "15px",
    },
    cardStyle: {
      margin: "15px",
      padding: "10px",
    },
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
    },
    card: {
      padding: 15,
      marginBottom: 20,
      width: "100%",
      marginTop: "15px",
    },
    textfield: {
      width: "100%",
      label: { fontSize: 12 },
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
      marginLeft: "10px",
      marginTop: "5px",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };
  return (
    <div>
      <div style={{ margin: "15px" }}>
        <Card sx={{ padding: "10px" }}>
          <span
            style={{
              color: "red",
              fontSize: "large",
              fontFamily: "cursive",
            }}
          >
            Student Report:
          </span>
        </Card>
        <Card style={styles.cardsMargin}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Tabular Report" {...a11yProps(0)} />
              <Tab label="Grapical Report" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Card>
        <TabPanel value={tabValue} index={0}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                      required
                    >
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        variant="standard"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                    >
                      <InputLabel required sx={{ fontSize: 12 }}>
                        Group By Data
                      </InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Group By Data"
                      >
                        <MenuItem value={"daily"}>Daily</MenuItem>
                        <MenuItem value={"weekly"}>Weekly</MenuItem>
                        <MenuItem value={"monthly"}>Monthly</MenuItem>
                        <MenuItem value={"yearly"}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Stack spacing={2} direction="row">
                  <Button variant="contained" style={styles.Button}>
                    Find
                  </Button>
                  <Button variant="contained" style={styles.Button}>
                    Print
                  </Button>
                </Stack>
              </form>
            </Box>
          </Card>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Academic Year
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Class Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Male
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Female
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">1</TableCell>

                <TableCell align="center">2023</TableCell>

                <TableCell align="center"></TableCell>
                <TableCell align="center">5</TableCell>
                <TableCell align="center">4</TableCell>
                <TableCell align="center">1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="start" colSpan={5}>
                  Total:
                </TableCell>

                <TableCell colSpan={10} align="center">
                  1
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                      required
                    >
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        variant="standard"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                    >
                      <InputLabel required sx={{ fontSize: 12 }}>
                        Group By Data
                      </InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Group By Data"
                      >
                        <MenuItem value={"gender"}>Gender</MenuItem>
                        <MenuItem value={"vehicle"}>Vehicle</MenuItem>
                        <MenuItem value={"library"}>Library</MenuItem>
                        <MenuItem value={"hostel"}>Hostel</MenuItem>
                        <MenuItem value={"class"}>Class</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Stack spacing={2} direction="row">
                  <Button variant="contained" style={styles.Button}>
                    Find
                  </Button>
                  <Button variant="contained" style={styles.Button}>
                    Print
                  </Button>
                </Stack>
              </form>
            </Box>
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card sx={{ maxWidth: 420, mt: 3 }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src="/Logo.png" height={30} width={30} />
                  <Typography
                    gutterBottom
                    sx={{ color: "red", fontSize: "7px", mt: 1.2, ml: 0.5 }}
                  >
                    Excellent English Medium Primary & High School
                  </Typography>
                </div>
                <Typography
                  gutterBottom
                  variant="h7"
                  sx={{ textAlign: "center", mt: 2 }}
                  component="div"
                >
                  Excellent English Medium Primary & High School
                </Typography>
                <Typography
                  variant="h11"
                  sx={{ textAlign: "center" }}
                  component="div"
                >
                  Ittangihal road, Vijaypur, Karnataka 586103
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3px",
                  }}
                >
                  <AssessmentIcon style={{ color: "#1b3779" }} />
                  <Typography
                    style={{ color: "red" }}
                    variant="h8"
                    component="div"
                  >
                    Student Report
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>

          <Typography variant="h5" sx={{ textAlign: "center", mt: 3 }}>
            Student Report
          </Typography>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <BarChart
              width={450}
              height={300}
              data={combinedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="male" fill="#8884d8" />
              <Bar dataKey="female" fill="#82ca9d" />
              <Bar dataKey="total" fill="#ff7f50" />
            </BarChart>
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

export default StudentReport;
