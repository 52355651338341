import React, { useContext } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import style from "../styles2/footer.module.css";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import SettingContext from "../context/SettingsContext";
import { Link } from "react-router-dom";

const FooterContainer = styled(Grid)(({ theme }) => ({
  marginTop: "6rem",
  padding: "40px",
  height: "auto",
  backgroundColor: "#12141b",
  color: "#CDCDCD",
  [theme.breakpoints.down("sm")]: {
    padding: "30px",
  },
}));

const ContentHeading = styled(Typography)(({ theme }) => ({
  color: "white",
  padding: "20px 0",
  fontWeight: "700",
  [theme.breakpoints.down("md")]: {
    padding: "10px 0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 0",
    fontWeight: "700",
    fontSize: "20px",
  },
}));

const CopyRightContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#090a0e",
  color: "#ffffff",
  minHeight: "50px",
  display: "flex",
  alignItems: "center",
  padding: "0 30px",
}));


const Footer = () => {

  const { selectedSetting } = useContext(SettingContext);
      
  return (
    <>
      <FooterContainer container spacing={1}>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContentHeading variant="h5">Contact us</ContentHeading>
          <Box>
            <div style={{ display: "flex" }}>
              <Typography sx={{ color: "#ff8f0b", fontWeight: "500" }}>
                Address:&nbsp;
                <span style={{ color: "#CDCDCD" }}>
                  Kotnur-D, Dhariyapur Ring Road, <br />
                  Kalaburagi - 585102 Karnataka.
                </span>
              </Typography>
            </div>
            <Typography>
              <span style={{ color: "#ff8f0b", fontWeight: "500" }}>
                Email:
              </span>
              &nbsp;
              <span variant="body2" className={style.address}>

              {selectedSetting ? selectedSetting.email : "abc@gmail.com"}

              </span>
            </Typography>
            <Typography>
              <span style={{ color: "#ff8f0b", fontWeight: "500" }}>
                Phone:
              </span>
              &nbsp;
              <span>+91 {selectedSetting ? selectedSetting.phone : "9878798777" }</span>
            </Typography>
            <Box sx={{ display: "flex", gap: 3, padding: "5px 0" }}>


              {/* <a href="www.facebook.com" className={style.facebook}>
                <span>
                  <FacebookIcon />
                </span>
              </a> */}



              {selectedSetting.facebookUrl  ? <span>
               <Link className={style.facebook} to={selectedSetting.facebookUrl} target="_blank"> <FacebookIcon/> </Link>
                </span> : null}


              {/* <a href="www.twitter.com" className={style.twitter}>
                <TwitterIcon />
              </a> */}


              {selectedSetting.twitterUrl  ? <span>
               <Link className={style.twitter} to={selectedSetting.twitterUrl} target="_blank">    
                    <TwitterIcon />
               </Link>
                </span> : null}


              {/* <a href="www.instagram.com" className={style.instagram}>
                <InstagramIcon />
              </a> */}


              {selectedSetting.instagramUrl  ? <span>
               <Link className={style.instagram} to={selectedSetting.instagramUrl} target="_blank">    
                    <InstagramIcon />
               </Link>
                </span> : null}


            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ContentHeading variant="h5">Quick Links</ContentHeading>
          <Box>
            <Typography>
              <Link to="/about/overview" className={style.link}>
                <span>OverView</span>
              </Link>
            </Typography>
            <Typography>
              <Link to="/about/founder" className={style.link}>
                <span>About Founder</span>
              </Link>
            </Typography>
            <Typography>
              <Link to="/results" className={style.link}>
                <span>Results</span>
              </Link>
            </Typography>
            <Typography>
              <Link to="/home-gallery" className={style.link}>
                <span>Gallery</span>
              </Link>
            </Typography>
            <Typography>
              <Link to="/about/visionandmission" className={style.link}>
                <span> Vision and Mission</span>
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <ContentHeading variant="h5" textAlign="center">
            Find Us Here
          </ContentHeading>
          <Box>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3809.3515684513527!2d76.82305417433884!3d17.298578305359133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc8bfef6212dedd%3A0x55e6b3ba1c3fb8df!2sKAYAKA%20FOUNDATION%20EDUCATION%20TRUST!5e0!3m2!1sen!2sin!4v1684737433559!5m2!1sen!2sin"
              width="100%"
              height="150"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              style={{ borderRadius: "10px", filter: "grayscale(80%)" }}
            />
          </Box>
        </Grid>
      </FooterContainer>
      <CopyRightContainer
        sx={{
          display: "flex",
          justifyContent: { sm: "space-between", xs: "flex-start" },
          alignItems: "center",
          flexDirection: { sm: "row", xs: "column" },
        }}
      >
        <Typography>{selectedSetting.websiteFooter}</Typography>
        <Typography>Powered by eCampusStreet</Typography>
      </CopyRightContainer>
    </>
  );
};

export default Footer;
