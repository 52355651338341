import * as React from "react";
// import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
  styled,
  IconButton,
  Box,
  Grid,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState, useContext } from "react";
import { useEffect } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   // width: 800,
//   // height: 550,
//   bgcolor: "background.paper",
//   p: 4,
// };

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "650px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ListContainer = styled(Box)(() => ({
  flexWrap: "nowrap",
  overflowY: "hidden",
  overflowX: "auto",
  flexDirection: "row",
  display: "flex",
  paddingLeft: "10px",
}));
const ListItem = styled(Typography)(() => ({
  fontSize: "14px",
  paddingRight: "5px",
  paddingLeft: "5px",
}));

const ListItemContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#f9f9f9",
  marginRight: "10px",
  alignItems: "center",
  borderRadius: "10@s",
  minWidth: "150px",
}));
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};
const AddSubjectModal = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const [subjectName, setSubjectName] = useState("");
  const [subjectCode, setSubjectCode] = useState("");
  const [subjectType, setSubjectType] = useState("");
  const [subjectAuthor, setSubjectAuthor] = useState("");
  const [subjectGroup, setSubjectGroup] = useState("");
  const [subjectTeacher, setSubjectTeacher] = useState("");
  const [subjectTeachers, setSubjectTeachers] = useState([]);
  const [subjectClass, setSubjectClass] = useState("");
  const [subjectNote, setSubjectNote] = useState("");
  const [classes, setClasses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  useEffect(() => {
    if (props.updateSubject) {
      setSubjectName(props.updateSubject.subjectName);
      setSubjectTeachers(props.updateSubject.subjectTeachers || []);
      setSubjectCode(props.updateSubject.subjectCode);
      setSubjectType(props.updateSubject.subjectType);
      setSubjectAuthor(props.updateSubject.subjectAuthor);
      setSubjectGroup(props.updateSubject.subjectGroup);
      setSubjectTeacher(props.updateSubject.subjectTeacher);
      setSubjectClass(props.updateSubject.class);
      setSubjectNote(props.updateSubject.note);
    }
  }, [props.updateSubject]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const response = await get(url.class.getAllClass);
        setClasses(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const response = await get(url.employee.getAllEmployee);
        setEmployees(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployee();
  }, []);

  const handleSubjectName = (e) => {
    setSubjectName(e.target.value);
  };
  const handleSubjectCode = (e) => {
    setSubjectCode(e.target.value);
  };
  const handleSubjectType = (e) => {
    setSubjectType(e.target.value);
  };

  const handleSubjectAuthor = (e) => {
    setSubjectAuthor(e.target.value);
  };

  const handleGroup = (e) => {
    setSubjectGroup(e.target.value);
  };
  const handleSubjectTeacher = (e) => {
    setSubjectTeacher(e.target.value);

    if (subjectTeachers.filter((t) => t == e.target.value)[0]) {
      let newList = subjectTeachers.filter((t) => t != e.target.value);
      setSubjectTeachers(newList);
    } else {
      let itemToAdd = employees.filter((emp) => emp._id == e.target.value)[0];
      let newList = [...subjectTeachers, itemToAdd._id];
      setSubjectTeachers(newList);
    }
  };

  const handleRemoveSubjectTeacher = (emp) => {
    let newList = subjectTeachers.filter((t) => t != emp);
    setSubjectTeachers(newList);
  };
  const handleSubjectClass = (e) => {
    setSubjectClass(e.target.value);
  };
  const handleSubjectNote = (e) => {
    setSubjectNote(e.target.value);
  };
  const handleSubmit = async (e) => {
    if (props.updateSubject) {
      await props.handleUpdate(
        {
          subjectName,
          subjectCode,
          subjectType,
          subjectAuthor,
          subjectGroup,
          subjectTeacher,
          class: subjectClass,
          note: subjectNote,
          subjectTeachers,
        },
        props.updateSubject._id
      );
    } else {
      await props.handleSubmit(e, {
        subjectName,
        subjectCode,
        subjectType,
        subjectAuthor,
        subjectGroup,
        subjectTeacher,
        class: subjectClass,
        note: subjectNote,
        subjectTeachers,
      });
    }
    setSubjectName("");
    setSubjectCode("");
    setSubjectType("");
    setSubjectAuthor("");
    setSubjectGroup("");
    setSubjectTeacher("");
    setSubjectClass("");
    setSubjectNote("");
    setSubjectTeachers([]);
    props.handleClose();
  };

  console.log();

  const handleCloseModal = () => {
    setSubjectName("");
    setSubjectCode("");
    setSubjectType("");
    setSubjectAuthor("");
    setSubjectGroup("");
    setSubjectTeacher("");
    setSubjectClass("");
    setSubjectNote("");
    setSubjectTeachers([]);
    props.handleClose();
  };

  const handleClose = () => {
    setSubjectName("");
    setSubjectCode("");
    setSubjectType("");
    setSubjectAuthor("");
    setSubjectGroup("");
    setSubjectTeacher("");
    setSubjectClass("");
    setSubjectNote("");
    setSubjectTeachers([]);
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Subject
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Subject Name"
                  value={subjectName}
                  onChange={handleSubjectName}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  value={subjectCode}
                  onChange={handleSubjectCode}
                  label="Subject Code"
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Author"
                  value={subjectAuthor}
                  onChange={handleSubjectAuthor}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  required
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Subject type
                  </InputLabel>
                  <Select
                    label="Subject Type"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={subjectType}
                    onChange={handleSubjectType}
                  >
                    <MenuItem
                      value={"mandatory"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Mandatory
                    </MenuItem>
                    <MenuItem
                      value={"optional"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Optional
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Group
                  </InputLabel>
                  <Select
                    label="Group"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={subjectGroup}
                    onChange={handleGroup}
                  >
                    <MenuItem
                      value={"scholastic"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Scholastic
                    </MenuItem>
                    <MenuItem
                      value={"co-scholastic"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Co-scholastic
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    label="Class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={subjectClass}
                    onChange={handleSubjectClass}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Select Subject teachers
                  </InputLabel>
                  <Select
                    label="Select Subject Teachers"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={""}
                    onChange={handleSubjectTeacher}
                  >
                    {employees &&
                      employees.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.basicInfo.empName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1} md={1} lg={1} mt={1}>
                {subjectTeachers.length === 0 ? "" : subjectTeachers.length}{" "}
              </Grid>

              <Grid item xs={10} md={10} lg={10}>
                {subjectTeachers.length ? (
                  <ListContainer>
                    {subjectTeachers.map((t) => (
                      <ListItemContainer key={t}>
                        <ListItem component="span">
                          {employees.filter((e) => e._id == t)[0]
                            ? employees.filter((e) => e._id == t)[0].basicInfo
                                .empName
                            : ""}
                        </ListItem>
                        <IconButton
                          onClick={() => handleRemoveSubjectTeacher(t)}
                        >
                          <CloseRoundedIcon fontSize="small" />
                        </IconButton>
                      </ListItemContainer>
                    ))}
                  </ListContainer>
                ) : null}
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={subjectNote}
                  onChange={handleSubjectNote}
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    // margin: 8,
                    padding: 10,
                    height: 70,
                    overflow: "auto",
                    borderRadius: "5px",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", xs: "center" },
                  }}
                >
                  <>
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        size="medium"
                        type="button"
                        color="error"
                        sx={{
                          m: 1,
                          color: "#fff",
                        }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <LoadingButton
                        loading={props.loader}
                        disabled={!(subjectName && subjectCode && subjectType)}
                        variant="contained"
                        size="medium"
                        type="submit"
                        style={{ background: "#1b3779", color: "#fff" }}
                      >
                        Submit
                      </LoadingButton>
                    </Tooltip>
                  </>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default AddSubjectModal;
