// import React from "react";
// import {
//   Box,
//   Button,
//   Grid,
//   Container,
//   Divider,
//   Typography,
// } from "@material-ui/core";

// import { FaFacebookF, FaTwitter } from "react-icons/fa";
// import { BiLogoLinkedin } from "react-icons/bi";
// import { GrGooglePlus } from "react-icons/gr";
// import { BsYoutube } from "react-icons/bs";
// import logo from "../../assets/Images/kayaka-logo.png";

// import style from "./footer.module.css";
// import styled from "@emotion/styled";

// const FooterNav = styled(Box)(({ theme }) => ({
//   color: "white",
//   fontFamily: "sans-serif",
//   fontSize: "1rem",
//   margin: "10px 0px 10px",
// }));

// const Footer = () => {
//   return (
//     <Box style={{ backgroundColor: "black" }}>
//       <Container>
//         <Grid
//           container
//           style={{
//             backgroundColor: "black",
//             display: "flex",
//             justifyContent: "space-evenly",
//             alignItems: "center",
//             marginTop: "8rem",
//           }}
//         >
//           <Grid item xs={12} lg={4} md={6} sm={6}>

//             <img src={logo} alt="logo" height={120} width={130} />
//             <p className={style.firstgridSecond}>FOLLOW US</p>

//             <Box className={style.logobox}>
//               <span>
//                 <FaFacebookF className={style.logo} size={20} />
//               </span>
//               <span>
//                 <FaTwitter className={style.logo} size={20} />
//               </span>
//               <span>
//                 <BiLogoLinkedin className={style.logo} size={25} />
//               </span>
//               <span>
//                 <GrGooglePlus className={style.logo} size={25} />
//               </span>
//               <span>
//                 <BsYoutube className={style.logo} size={20} />
//               </span>
//             </Box>
//           </Grid>

//           <Grid item xs={12} lg={4} md={6} sm={6}>
//             <p
//               style={{
//                 marginTop: "2rem",
//                 fontSize: "1.4rem",
//                 fontWeight: "bold",
//                 color: "#F86F03",
//               }}
//             >
//               USEFULL LINKS
//             </p>
//             <Box style={{ display: "flex", gap: "2rem" }}>
//               <Box className={style.usefull}>
//                 <a href="/"> Home </a>
//                 <a href="#"> About </a>
//                 {/* <a href="#"> Admission </a> */}
//               </Box>

//               <Box className={style.usefull}>
//                 <a href="/home-gallery"> Gallery </a>
//                 <a href="#"> Facilities </a>
//                 <a href="/results"> Result </a>
//               </Box>
//             </Box>
//           </Grid>

//           <Grid item xs={12} lg={3} md={4}>
//             <p
//               style={{
//                 marginTop: "3.5rem",
//                 fontSize: "1.4rem",
//                 fontWeight: "bold",
//                 color: "#F86F03",
//               }}
//             >
//               RECENT POST
//             </p>
//             <Box>
//               {imageData.map((value, index) => {
//                 return (
//                   <Box
//                     key={index}
//                     style={{
//                       display: "flex",
//                       gap: "2rem",
//                       alignItems: "center",
//                       marginTop: "1.5rem",
//                     }}
//                   >
//                     <img src={value.image} alt="image" width={60} height={60} />
//                     <Box style={{ display: "flex", flexDirection: "column" }}>
//                       <p className={style.title1}>{value.title1}</p>
//                       <p className={style.title2}>{value.title2}</p>
//                     </Box>
//                   </Box>
//                 );
//               })}
//             </Box>
//           </Grid>

//           <Grid item xs={12} lg={4} md={6} sm={6}>
//             <p
//               style={{
//                 marginTop: "3rem",
//                 fontSize: "1.4rem",
//                 fontWeight: "bold",
//                 color: "#F86F03",
//               }}
//             >
//               QUICK CONTACTS
//             </p>
//             <Box style={{ marginTop: "2rem" }}>

//               <Typography
//                 style={{
//                   fontSize: "1.2rem",
//                   fontWeight: "bold",
//                   color: "#F86F03",
//                   margin: "22px 0px 8px",
//                   fontFamily: "serif",
//                   borderBottom: "2px solid #F86F03",
//                   display: "inline",
//                 }}
//               >
//                 ADDRESS
//               </Typography>
//               <FooterNav>Kayaka Foundation Education Trust (R),</FooterNav>
//               <FooterNav>Kotnur-D, Dhariyapur Ring Road,</FooterNav>
//               <FooterNav>Kalaburgi - 585102, Karnataka </FooterNav>
//             </Box>
//           </Grid>
//         </Grid>
//         <Divider style={{ marginBottom: "8vh", borderBottomWidth: "red" }} />

//         <Grid container>
//           <Grid item xs={12} md={6} lg={8} className={style.copyright}>
//             <Typography style={{ color: "white" }}>
//               Copyright © 2023. Kayaka All Rights Reserved.
//             </Typography>
//           </Grid>

//           <Grid
//             item
//             xs={12}
//             md={6}
//             lg={4}
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Box className={style.footerLink}>
//               <a href="#">Testimonial</a>
//               <a href="#">Services</a>
//               <a href="#">Policy</a>
//               <a href="#">Contact</a>
//             </Box>
//           </Grid>
//         </Grid>
//       </Container>
//     </Box>
//   );
// };

// export default Footer;

import React, { useContext } from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";

import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";
import { GrGooglePlus } from "react-icons/gr";
import { BsYoutube } from "react-icons/bs";
import { BiLogoInstagram } from "react-icons/bi";

import logo from "../../assets/Images/kayaka-logo.png";

import style from "./footer.module.css";
import { styled, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SettingContext from "../../context/SettingsContext";

const FooterNav = styled(Box)(({ theme }) => ({
  color: "white",
  fontFamily: "sans-serif",
  fontSize: "1rem",
  margin: "10px 0px 10px",
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: "white",
  marginBottom: "2rem",
  [theme.breakpoints.down(960)]: {
    marginBottom: "1rem",
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "white",
  transition: "1s",
}));

const Footer = () => {
  const { selectedSetting } = useContext(SettingContext);

  return (
    <Box style={{ backgroundColor: "black", marginTop: "3rem" }}>
      <Container>
        <Grid
          container
          spacing={2}
          style={{
            backgroundColor: "black",
          }}
        >
          <Grid
            item
            xs={12}
            lg={3}
            md={6}
            sm={6}
            sx={{ marginBottom: "2rem", marginTop: "2rem" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={logo} alt="logo" height={220} width={230} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            lg={3}
            md={6}
            sm={6}
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
          >
            <p
              style={{
                fontSize: "1.4rem",
                fontWeight: "bold",
                color: "#F86F03",
                textAlign: "center",
              }}
            >
              USEFULL LINKS
            </p>
            <Box
              style={{ display: "flex", gap: "2rem", justifyContent: "center" }}
            >
              <Box className={style.usefull}>
                <Link to="/"> Home </Link>
                <Link to="/about/overview"> About </Link>
                <Link to="/home-gallery"> Gallery </Link>
              </Box>

              <Box className={style.usefull}>
                <Link to="/results"> Result </Link>
                <Link to="/facilities/library"> Facilities </Link>
                <Link to="/contact-us"> Contact us </Link>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            lg={2}
            md={6}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "2rem",
              marginTop: "2rem",
            }}
          >
            <Box>
              <p className={style.firstgridSecond}>FOLLOW US</p>
            </Box>

            <Box className={style.logobox}>


              {selectedSetting.facebookUrl  ? <span>
               <Link to={selectedSetting.facebookUrl} target="_blank"> <FaFacebookF className={style.logo} size={20} /> </Link>
                </span> : null}

              
              {selectedSetting.twitterUrl ? 
                <span>
               <Link to={selectedSetting.twitterUrl} target="_blank" >  <FaTwitter className={style.logo} size={20} /> </Link>
                </span> : null }



                {selectedSetting.instagramUrl ? 
                <span>
              <Link to={selectedSetting.instagramUrl} target="_blank" >  <BiLogoInstagram className={style.logo} size={25} /> </Link>
                </span> : null }


              {selectedSetting.youtubeUrl ? 
                <span>
               <Link to={selectedSetting.gplusUrl} target="_blank" > <GrGooglePlus className={style.logo} size={25} /> </Link>
                </span> : null }

              {selectedSetting.gplusUrl ? 
                <span>
              <Link to={selectedSetting.youtubeUrl} target="_blank" >  <BsYoutube className={style.logo} size={20} /> </Link>
                </span> : null }

                {selectedSetting.linkedinUrl ? 
                <span>
                <Link to={selectedSetting.linkedinUrl} target="_blank" > <BiLogoLinkedin className={style.logo} size={25} /> </Link>
                </span> : null }


            </Box>
            
          </Grid>

          <Grid item xs={12} lg={4} md={6} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginLeft: "1rem",
                marginTop: "2rem",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  color: "#F86F03",
                  margin: "0px 0px 8px",
                  fontFamily: "sans-serif",
                  display: "inline",
                }}
              >
                ADDRESS
              </Typography>
              <FooterNav>Kayaka Foundation Education Trust (R),</FooterNav>
              <FooterNav>Kotnur-D, Dhariyapur Ring Road,</FooterNav>
              <FooterNav>Kalaburgi - 585102, Karnataka </FooterNav>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: { lg: "2rem", xs: "1rem" } }}>
          <Grid item xs={12} md={6} lg={8} className={style.copyright}>
            <FooterText>{selectedSetting.websiteFooter}</FooterText>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FooterText>
              <Link to="https://ecampusstreet.com/" target="_blank">
                <span className={style.ecampusheading}>
                  Powered by{" "}
                  <span className={style.ecampus}>ecampusstreet.com</span>
                </span>
              </Link>
            </FooterText>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
