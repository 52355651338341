// import React, { useContext, useEffect, useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import {
//   Box,
//   Button,
//   Card,
//   Fab,
//   FormControl,
//   FormControlLabel,
//   Grid,
//   IconButton,
//   Input,
//   InputAdornment,
//   InputLabel,
//   MenuItem,
//   Modal,
//   Radio,
//   RadioGroup,
//   Select,
//   Tab,
//   Tabs,
//   TextField,
//   Tooltip,
//   Typography,
//   styled,
// } from "@mui/material";
// import { Add, Delete, Edit, Search } from "@mui/icons-material";
// import { urls as url } from "../../../services/urlConstant";
// import { del, get, post, put } from "../../../services/apis";
// import { FormLabel } from "@mui/material";
// import SettingContext from "../../../context/SettingsContext";
// import appendStringToUrls from "../../../utils/appendString";
// import SectionSubHeader from "../../SectionSubHeader";
// import Wrapper from "../../Wrapper";
// import DeleteModal from "../Academic/DeleteModal";

// const ContainerBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   maxWidth: "600px",
//   borderRadius: "5px",
//   height: "auto",
//   backgroundColor: "white",
//   padding: "20px",
//   // border:"solid red",
//   [theme.breakpoints.down("md")]: {
//     overflow: "auto",
//     width: "90%",
//   },
//   [theme.breakpoints.between(819, 821)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(767, 769)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(911, 913)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1023, 1025)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1279, 1281)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//     overflow: "auto",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

// const ListContainer = styled(Box)(() => ({
//   flexWrap: "nowrap",
//   overflowY: "hidden",
//   overflowX: "auto",
//   flexDirection: "column",
//   display: "flex",
//   paddingLeft: "10px",
// }));
// const ListItem = styled(Typography)(() => ({
//   fontSize: "14px",
//   paddingRight: "5px",
//   paddingLeft: "5px",
// }));

// const ListItemContainer = styled(Box)(() => ({
//   display: "flex",
//   justifyContent: "space-between",
//   backgroundColor: "#f9f9f9",
//   marginRight: "10px",
//   alignItems: "center",
//   borderRadius: "10@s",
//   minWidth: "150px",
// }));

// const useStyles = makeStyles({
//   table: {
//     // minWidth: 650,
//   },
// });

// function LeaveType() {
//   const { selectedSetting } = useContext(SettingContext);
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [schoolDepartment, setSchoolDepartment] = useState([]);
//   const [searchFilter, setSearchFilter] = useState([]);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const [leaveTypeData, setLeaveTypeData] = useState([]);
//   const [editLeaveType, setEditLeaveType] = useState();
//   const [search, setSearch] = useState("");
//   const [filter, setFilter] = useState("");
//   const [multipleDepartments, setMultipleDepartments] = useState([]);
//   const [deleteModal, setDeleteModal] = React.useState(false);

//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

//   const [state, setState] = useState({
//     name: "",
//     leaveType: "",
//     departments: "",
//     autoEarned: false,
//     total: 0,
//     isSpecial: false,
//     canResetCarryForward: false,
//     carryForwardCount: 0,
//     autoEarnCount: 0,
//   });
//   let {
//     name,
//     leaveType,
//     departments,
//     autoEarned,
//     total,
//     isSpecial,
//     canResetCarryForward,
//     carryForwardCount,
//     autoEarnCount,
//   } = state;
//   // let handleChange = (e) => {
//   //   setState({ ...state, [e.target.name]: e.target.value });
//   // };

//   let handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "departments") {
//       setMultipleDepartments(value);
//     } else {
//       setState({ ...state, [name]: value });
//     }
//   };

//   useEffect(() => {
//     const getSchoolDepartment = async () => {
//       try {
//         const res = await get(urls.department.getDepartment);
//         // console.log(res.data)
//         setSchoolDepartment([...res.data.data]);
//         setSearchFilter([...res.data.data]);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getSchoolDepartment();
//   }, [selectedSetting._id]);

//   let handleCancel = () => {
//     setOpen(false);
//     setState({});
//     setMultipleDepartments([]);
//     setEditLeaveType(null);
//   };

//   let handleSubmit = async (e) => {
//     e.preventDefault();
//     let payload = {
//       name,
//       leaveType,
//       departments: multipleDepartments,
//       autoEarned,
//       total,
//       isSpecial,
//       canResetCarryForward,
//       carryForwardCount,
//       autoEarnCount,
//     };
//     console.log(payload);
//     if (!editLeaveType) {
//       try {
//         // console.log(payload)
//         let { data } = await post(`${urls.leaveType.create}/new`, payload);
//         let newList = [...leaveTypeData, data.data];
//         setLeaveTypeData(newList);
//         // console.log(data);
//       } catch (error) {
//         console.log(error);
//       }
//     } else {
//       // console.log(payload,editLeaveType, "iiiiiiiiiiiiii")
//       const response = await put(
//         `${urls.leaveType.update}/${editLeaveType}`,
//         "",
//         payload
//       );
//       setLeaveTypeData(
//         leaveTypeData.map((e) => {
//           if (e._id === response.data.data._id) {
//             return response.data.data;
//           } else {
//             return e;
//           }
//         })
//       );
//     }
//     setState({
//       name: "",
//       leaveType: "",
//       departments: "",
//       autoEarned: false,
//       total: 0,
//       isSpecial: "",
//       canResetCarryForward: false,
//       carryForwardCount: 0,
//       autoEarnCount: 0,
//     });
//     setMultipleDepartments([]);
//     setOpen(false);
//   };

//   const styles = {
//     button: {
//       backgroundColor: "orange",
//     },
//     textfield: {
//       marginTop: "10px",
//       width: "200px",
//     },
//     addbutton: {
//       paddingTop: "6.2px",
//       paddingBottom: "6.2px",
//     },
//     tab: {
//       width: "100%",
//       borderCollapse: "collapse",
//     },
//   };

//   useEffect(() => {
//     let fetchData = async () => {
//       try {
//         let { data } = await get(`${urls.leaveType.getList}/all`);
//         setLeaveTypeData(data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchData();
//   }, [selectedSetting._id]);

//   const handleEdit = (id, value) => {
//     setOpen(true);
//     setEditLeaveType(id);
//     setState({
//       name: value.name,
//       leaveType: value.leaveType,
//       departments: value.departments,
//       autoEarned: value.autoEarned,
//       total: value.total,
//       isSpecial: value.isSpecial ? true : false,
//       canResetCarryForward: value.canResetCarryForward,
//       carryForwardCount: value.carryForwardCount,
//       autoEarnCount: value.autoEarnCount,
//     });
//   };

//   const handleDelete = async (id) => {
//     try {
//       const res = await del(`${urls.leaveType.delete}/remove/`, id);
//       if (res.data.success) {
//         const filteredEvent = leaveTypeData.filter((item) => item._id !== id);
//         setLeaveTypeData(filteredEvent);
//       } else {
//         console.log("Couldn't complete your request, Try again later");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleSearch = (e) => {
//     const { value } = e.target;
//     setSearch(value.trim());
//     if (value.trim() !== "") {
//       leaveTypeData.length > 0 &&
//         setFilter(
//           leaveTypeData.filter((ele) =>
//             ele.name.toLowerCase().includes(value.toLowerCase().trim())
//           )
//         );
//     } else {
//       setFilter([]);
//     }
//   };

//   const handleRemoveDepartments = (data) => {
//     let newList = multipleDepartments.filter((t) => t != data);
//     setMultipleDepartments(newList);
//   };

//   return (
//     <Wrapper>
//       <SectionSubHeader title="Leave Type" />
//       <Box sx={{ margin: "15px 0" }}>
//         {/* <Paper style={{ width: "100%", height: "60px", overflow: "hidden" }}> */}
//         <Grid
//           container
//           // sx={{ justifyContent: { md: "flex-end", xs: "center" } }}
//         >
//           <Grid
//             item
//             xs={12}
//             md={6}
//             lg={12}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               p: "0.5%",
//               justifyContent: { md: "right", sm: "right", xs: "center" },
//             }}
//           >
//             <TextField
//               full
//               size="small"
//               value={search}
//               onChange={handleSearch}
//               // style={styles.textfield}
//               label="Search"
//               variant="outlined"
//               placeholder="Search By Name"
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton edge="end" type="submit">
//                       <Search />
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Grid>
//         </Grid>
//         {/* </Paper> */}
//       </Box>
//       <Box style={{ margin: "15px 0" }}>
//         <Paper sx={{ width: "100%", overflow: "hidden" }}>
//           <TableContainer component={Paper}>
//             <Table aria-label="simple table">
//               <TableHead style={{ background: "rgb(27 55 121)" }}>
//                 <TableRow>
//                   <TableCell>
//                     <span style={{ color: "white" }}>#SL</span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span style={{ color: "white" }}>Name</span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span style={{ color: "white" }}>Leave category</span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span style={{ color: "white" }}>Department</span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span style={{ color: "white" }}>Total</span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span style={{ color: "white" }}>Action</span>
//                   </TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {search
//                   ? filter.map((value, index) => (
//                       <TableRow key={index} style={{ border: "0px" }}>
//                         <TableCell
//                           key={value.name}
//                           align="center"
//                           style={{ textTransform: "capitalize" }}
//                         >
//                           {index + 1}
//                         </TableCell>
//                         <TableCell align="center">{value.name}</TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}
//                         >
//                           {value.leaveType}
//                         </TableCell>

//                         <TableCell>
//                           {value.departments.map((d) => d.department).join(",")}
//                         </TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}
//                         >
//                           {value.total}
//                         </TableCell>
//                         <TableCell
//                           //  className="edit-delete"
//                           align="center"
//                         >
//                           <Tooltip title="Edit">
//                             <Button>
//                               <Edit
//                                 sx={{ fontSize: "medium", color: "#1b3779" }}
//                                 onClick={() => handleEdit(value._id, value)}
//                               />
//                             </Button>
//                           </Tooltip>
//                           <Tooltip title="Delete">
//                             <Button>
//                               <Delete
//                                 sx={{ fontSize: "medium", color: "#1b3779" }}
//                                 onClick={() => setDeleteModal(value._id, value)}
//                               />
//                             </Button>
//                             <DeleteModal
//                               deleteModal={deleteModal}
//                               handleDelete={handleDelete}
//                               id={value._id}
//                               setDeleteModal={setDeleteModal}
//                             />
//                           </Tooltip>
//                         </TableCell>
//                       </TableRow>
//                     ))
//                   : leaveTypeData.map((value, index) => (
//                       <TableRow key={index} style={{ border: "0px" }}>
//                         <TableCell
//                           key={value.name}
//                           align="center"
//                           style={{ textTransform: "capitalize" }}
//                         >
//                           {index + 1}
//                         </TableCell>
//                         <TableCell align="center">{value.name}</TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}
//                         >
//                           {value.leaveType}
//                         </TableCell>
//                         {/* <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}
//                         >
//                           {value.departments}
//                         </TableCell> */}

//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}
//                         >
//                           {/* {value.departments} */}
//                           {value.departments.map((d) => d.department).join(",")}
//                         </TableCell>

//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}
//                         >
//                           {value.total}
//                         </TableCell>
//                         <TableCell
//                           //  className="edit-delete"
//                           align="center"
//                         >
//                           <Tooltip title="Edit">
//                             <Button>
//                               <Edit
//                                 sx={{ fontSize: "medium", color: "#1b3779" }}
//                                 onClick={() => handleEdit(value._id, value)}
//                               />
//                             </Button>
//                           </Tooltip>
//                           <Tooltip title="Delete">
//                             <Button>
//                               <Delete
//                                 sx={{ fontSize: "medium", color: "#1b3779" }}
//                                 onClick={() => setDeleteModal(value._id, value)}
//                               />
//                             </Button>
//                           </Tooltip>
//                           <DeleteModal
//                             deleteModal={deleteModal}
//                             handleDelete={handleDelete}
//                             id={value._id}
//                             setDeleteModal={setDeleteModal}
//                           />
//                         </TableCell>
//                       </TableRow>
//                     ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           {!leaveTypeData.length && !search.trim() && (
//             <Typography
//               variant="h6"
//               style={{ textAlign: "center", margin: "5px", padding: "5px" }}
//             >
//               No data found
//             </Typography>
//           )}
//           {search.trim() && !filter.length && (
//             <Typography
//               variant="h6"
//               sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
//             >
//               No data found
//             </Typography>
//           )}
//         </Paper>
//         <Box className="add-icon">
//           <Tooltip title="Add">
//             <Fab
//               size="medium"
//               style={{ background: "#1b3779", color: "#fff" }}
//               aria-label="add"
//               onClick={handleOpen}
//             >
//               <Add />
//             </Fab>
//           </Tooltip>
//         </Box>
//       </Box>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <form action="" onSubmit={handleSubmit}>
//           {/* <Card > */}
//           <ContainerBox>
//             <Box>
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 align="center"
//                 fontSize="20px"
//                 fontWeight="bold"
//                 marginBottom="20px"
//               >
//                 {editLeaveType ? "Update Leave" : "Add Leave"}
//               </Typography>
//             </Box>
//             <Box>
//               <Grid
//                 container
//                 spacing={2}
//                 sx={{
//                   display: "flex",
//                   justifyContent: "flex-start",
//                   alignItems: "flex-end",
//                 }}
//               >
//                 <Grid item xs={12} md={6} lg={6}>
//                   <FormControl fullWidth>
//                     <TextField
//                       required
//                       id="basic-standard"
//                       size="small"
//                       name="name"
//                       value={name}
//                       onChange={handleChange}
//                       label="Name"
//                       sx={{ label: { fontSize: 12 } }}
//                     />
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={6}>
//                   <FormControl fullWidth size="small">
//                     <InputLabel required>LeaveType</InputLabel>
//                     <Select
//                       label="LeaveType"
//                       labelId="demo-simpless-select-filled-label"
//                       id="demo-simple-select-filled-l"
//                       name="leaveType"
//                       size="small"
//                       value={leaveType}
//                       onChange={handleChange}
//                     >
//                       <MenuItem value={"Student"}>Student</MenuItem>
//                       <MenuItem value={"Employee"}>Employee</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 {leaveType === "Employee" ? (
//                   <>
//                     <Grid item xs={12} md={6} lg={6}>
//                       <FormControl fullWidth size="small">
//                         <InputLabel size="small" required>
//                           Department
//                         </InputLabel>
//                         <Select
//                           label="Department"
//                           labelId="demo-simpless-select-filled-label"
//                           id="demo-simple-select-filled-l"
//                           name="departments"
//                           value={multipleDepartments}
//                           onChange={handleChange}
//                           multiple
//                         >
//                           {schoolDepartment.map((dept) => (
//                             <MenuItem key={dept._id} value={dept._id}>
//                               {dept.department}
//                             </MenuItem>
//                           ))}
//                         </Select>
//                       </FormControl>

//                       {multipleDepartments.length ? (
//                         <ListContainer>
//                           {multipleDepartments.map((data) => (
//                             <ListItemContainer key={data}>
//                               <ListItem component="span">
//                                 {
//                                   schoolDepartment.filter(
//                                     (d) => d._id == data
//                                   )[0].department
//                                 }
//                               </ListItem>
//                               <IconButton
//                                 onClick={() => handleRemoveDepartments(data)}
//                               >
//                                 <CloseRoundedIcon fontSize="small" />
//                               </IconButton>
//                             </ListItemContainer>
//                           ))}
//                         </ListContainer>
//                       ) : null}
//                     </Grid>
//                     <Grid item xs={12} md={6} lg={6}>
//                       <FormControl required fullWidth size="small">
//                         <RadioGroup
//                           row
//                           aria-labelledby="demo-row-radio-buttons-group-label"
//                           name="isSpecial"
//                           style={{
//                             display: "flex",
//                             alignItems: "center",
//                             flexDirection: "row",
//                           }}
//                           value={isSpecial}
//                           onChange={handleChange}
//                         >
//                           <FormLabel
//                             id="demo-row-radio-buttons-group-label"
//                             sx={{ fontSize: "15px", fontWeight: 600 }}
//                           >
//                             is Special Type:
//                           </FormLabel>
//                           <FormControlLabel
//                             value={"true"}
//                             control={<Radio />}
//                             label="Yes"
//                           />
//                           <FormControlLabel
//                             value={"false"}
//                             control={<Radio />}
//                             label="No"
//                           />
//                         </RadioGroup>
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={6} lg={6}>
//                       <FormControl fullWidth size="small">
//                         <TextField
//                           id="basic-standard"
//                           size="small"
//                           name="total"
//                           value={total}
//                           onChange={handleChange}
//                           label="Total"
//                           style={{ label: { fontSize: 12 } }}
//                           // sx={styles.textfield}
//                           type="number"
//                         />
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={6} lg={6}>
//                       <FormControl fullWidth size="small">
//                         <RadioGroup
//                           row
//                           aria-labelledby="demo-row-radio-buttons-group-label"
//                           name="autoEarned"
//                           style={{ display: "flex", alignItems: "center" }}
//                           value={autoEarned}
//                           onChange={handleChange}
//                         >
//                           <FormLabel
//                             id="demo-row-radio-buttons-group-label"
//                             sx={{ fontSize: "15px", fontWeight: 600 }}
//                           >
//                             Is Leave Auto Earned:
//                           </FormLabel>
//                           <FormControlLabel
//                             value={"true"}
//                             control={<Radio sx={{ fontSize: 15 }} />}
//                             label="Yes"
//                           />
//                           <FormControlLabel
//                             value={"false"}
//                             control={<Radio sx={{ fontSize: 15 }} />}
//                             label="No"
//                           />
//                         </RadioGroup>
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={6} lg={6}>
//                       <FormControl fullWidth size="small">
//                         <TextField
//                           id="standard-basic"
//                           size="small"
//                           name="autoEarnCount"
//                           value={autoEarnCount}
//                           onChange={handleChange}
//                           label="Auto Earned Count :"
//                           style={{ minWidth: 227, label: { fontSize: 12 } }}
//                           type="number"
//                         />
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={6} lg={6}>
//                       <FormControl fullWidth>
//                         <RadioGroup
//                           row
//                           aria-labelledby="demo-row-radio-buttons-group-label"
//                           name="canResetCarryForward"
//                           style={{ display: "flex", alignItems: "center" }}
//                           value={canResetCarryForward}
//                           onChange={handleChange}
//                         >
//                           <FormLabel
//                             id="demo-row-radio-buttons-group-label"
//                             sx={{ fontSize: "15px", fontWeight: 600 }}
//                           >
//                             Is Carryforward Reset:{" "}
//                           </FormLabel>
//                           <FormControlLabel
//                             value={"true"}
//                             sx={{ fontSize: 15 }}
//                             control={<Radio />}
//                             label="Yes"
//                           />
//                           <FormControlLabel
//                             value={"false"}
//                             sx={{ fontSize: 15 }}
//                             control={<Radio size="small" />}
//                             label="No"
//                           />
//                         </RadioGroup>
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={6} lg={6}>
//                       <FormControl fullWidth>
//                         <TextField
//                           id="standard-basic"
//                           size="small"
//                           name="carryForwardCount"
//                           value={carryForwardCount}
//                           onChange={handleChange}
//                           label="Is Carryorword Count :"
//                           style={{ minWidth: 227, label: { fontSize: 12 } }}
//                           type="number"
//                         />
//                       </FormControl>
//                     </Grid>
//                   </>
//                 ) : (
//                   <></>
//                 )}

//                 <Grid item xs={12} md={6} lg={12}>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: {
//                         md: "right",
//                         sm: "right",
//                         xs: "center",
//                         gap: "1%",
//                       },
//                     }}
//                   >
//                     <Tooltip title="Cancel">
//                       <Button
//                         onClick={handleCancel}
//                         variant="contained"
//                         color="error"
//                         size="medium"
//                         type="button"
//                         sx={{ m: 1, color: "#fff" }}
//                       >
//                         Cancel
//                       </Button>
//                     </Tooltip>
//                     <Tooltip title="Submit">
//                       <Button
//                         variant="contained"
//                         size="medium"
//                         type="submit"
//                         sx={{
//                           marginLeft: "10px",
//                           color: "white",
//                           background: "rgb(27, 55, 121)",
//                           // m: 1,
//                           ":hover": { background: "rgb(27, 55, 121)" },
//                         }}
//                       >
//                         {editLeaveType ? "Update" : "Add"}
//                       </Button>
//                     </Tooltip>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </Box>
//           </ContainerBox>
//           {/* </Card> */}
//         </form>
//       </Modal>
//     </Wrapper>
//   );
// }
// export default LeaveType;

import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import { urls as url } from "../../../services/urlConstant";
import { del, get, post, put } from "../../../services/apis";
import { FormLabel } from "@mui/material";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import DeleteModal from "../Academic/DeleteModal";

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ListContainer = styled(Box)(() => ({
  flexWrap: "nowrap",
  overflowY: "hidden",
  overflowX: "auto",
  flexDirection: "column",
  display: "flex",
  paddingLeft: "10px",
}));
const ListItem = styled(Typography)(() => ({
  fontSize: "14px",
  paddingRight: "5px",
  paddingLeft: "5px",
}));

const ListItemContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#f9f9f9",
  marginRight: "10px",
  alignItems: "center",
  borderRadius: "10@s",
  minWidth: "150px",
}));

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function LeaveType() {
  const { selectedSetting } = useContext(SettingContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [schoolDepartment, setSchoolDepartment] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [editLeaveType, setEditLeaveType] = useState();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [multipleDepartments, setMultipleDepartments] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [state, setState] = useState({
    name: "",
    leaveType: "",
    departments: "",
    autoEarned: false,
    total: 0,
    isSpecial: false,
    canResetCarryForward: false,
    carryForwardCount: 0,
    autoEarnCount: 0,
  });
  let {
    name,
    leaveType,
    departments,
    autoEarned,
    total,
    isSpecial,
    canResetCarryForward,
    carryForwardCount,
    autoEarnCount,
  } = state;
  // let handleChange = (e) => {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // };

  let handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "departments") {
      setMultipleDepartments(value);
    } else {
      setState({ ...state, [name]: value });
    }
  };

  useEffect(() => {
    const getSchoolDepartment = async () => {
      try {
        const res = await get(urls.department.getDepartment);
        // console.log(res.data)
        setSchoolDepartment([...res.data.data]);
        setSearchFilter([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolDepartment();
  }, [selectedSetting._id]);

  let handleCancel = () => {
    setOpen(false);
    setState({});
    setMultipleDepartments([]);
    setEditLeaveType(null);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      name,
      leaveType,
      departments: multipleDepartments,
      autoEarned,
      total,
      isSpecial,
      canResetCarryForward,
      carryForwardCount,
      autoEarnCount,
    };
    console.log(payload);
    if (!editLeaveType) {
      try {
        // console.log(payload)
        let { data } = await post(`${urls.leaveType.create}/new`, payload);
        let newList = [...leaveTypeData, data.data];
        setLeaveTypeData(newList);
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      // console.log(payload,editLeaveType, "iiiiiiiiiiiiii")
      const response = await put(
        `${urls.leaveType.update}/${editLeaveType}`,
        "",
        payload
      );
      setLeaveTypeData(
        leaveTypeData.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    }
    setState({
      name: "",
      leaveType: "",
      departments: "",
      autoEarned: false,
      total: 0,
      isSpecial: "",
      canResetCarryForward: false,
      carryForwardCount: 0,
      autoEarnCount: 0,
    });
    setMultipleDepartments([]);
    setOpen(false);
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "10px",
      width: "200px",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };

  useEffect(() => {
    let fetchData = async () => {
      try {
        let { data } = await get(`${urls.leaveType.getList}/all`);
        setLeaveTypeData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting._id]);

  const handleEdit = (id, value) => {
    setOpen(true);
    setEditLeaveType(id);
    setState({
      name: value.name,
      leaveType: value.leaveType,
      departments: value.departments,
      autoEarned: value.autoEarned,
      total: value.total,
      isSpecial: value.isSpecial ? true : false,
      canResetCarryForward: value.canResetCarryForward,
      carryForwardCount: value.carryForwardCount,
      autoEarnCount: value.autoEarnCount,
    });
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(`${urls.leaveType.delete}/remove/`, id);
      if (res.data.success) {
        const filteredEvent = leaveTypeData.filter((item) => item._id !== id);
        setLeaveTypeData(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      leaveTypeData.length > 0 &&
        setFilter(
          leaveTypeData.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setFilter([]);
    }
  };

  const handleRemoveDepartments = (data) => {
    let newList = multipleDepartments.filter((t) => t != data);
    setMultipleDepartments(newList);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Leave Type" />
      <Box sx={{ margin: "15px 0" }}>
        {/* <Paper style={{ width: "100%", height: "60px", overflow: "hidden" }}> */}
        <Grid
          container
          // sx={{ justifyContent: { md: "flex-end", xs: "center" } }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={12}
            sx={{
              display: "flex",
              alignItems: "center",
              p: "0.5%",
              justifyContent: { md: "right", sm: "right", xs: "center" },
            }}
          >
            <TextField
              full
              size="small"
              value={search}
              onChange={handleSearch}
              // style={styles.textfield}
              label="Search"
              variant="outlined"
              placeholder="Search By Name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* </Paper> */}
      </Box>
      <Box style={{ margin: "15px 0" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell>
                    <span style={{ color: "white" }}>#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Leave category</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Department</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Total</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? filter.map((value, index) => (
                      <TableRow key={index} style={{ border: "0px" }}>
                        <TableCell
                          key={value.name}
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{value.name}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.leaveType}
                        </TableCell>

                        <TableCell>
                          {value.departments.map((d) => d.department).join(",")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.total}
                        </TableCell>
                        <TableCell
                          //  className="edit-delete"
                          align="center"
                        >
                          <Tooltip title="Edit">
                            <Button>
                              <Edit
                                sx={{ fontSize: "medium", color: "#1b3779" }}
                                onClick={() => handleEdit(value._id, value)}
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button>
                              <Delete
                                sx={{ fontSize: "medium", color: "#1b3779" }}
                                onClick={() => setDeleteModal(value._id, value)}
                              />
                            </Button>
                            <DeleteModal
                              deleteModal={deleteModal}
                              handleDelete={handleDelete}
                              id={value._id}
                              setDeleteModal={setDeleteModal}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  : leaveTypeData.map((value, index) => (
                      <TableRow key={index} style={{ border: "0px" }}>
                        <TableCell
                          key={value.name}
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{value.name}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.leaveType}
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.departments}
                        </TableCell> */}

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* {value.departments} */}
                          {value.departments.map((d) => d.department).join(",")}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {value.total}
                        </TableCell>
                        <TableCell
                          //  className="edit-delete"
                          align="center"
                        >
                          <Tooltip title="Edit">
                            <Button>
                              <Edit
                                sx={{ fontSize: "medium", color: "#1b3779" }}
                                onClick={() => handleEdit(value._id, value)}
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button>
                              <Delete
                                sx={{ fontSize: "medium", color: "#1b3779" }}
                                onClick={() => setDeleteModal(value._id, value)}
                              />
                            </Button>
                          </Tooltip>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={value._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!leaveTypeData.length && !search.trim() && (
            <Typography
              variant="h6"
              style={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !filter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
        <Box className="add-icon">
          <Tooltip title="Add">
            <Fab
              size="medium"
              style={{ background: "#1b3779", color: "#fff" }}
              aria-label="add"
              onClick={handleOpen}
            >
              <Add />
            </Fab>
          </Tooltip>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" onSubmit={handleSubmit}>
          {/* <Card > */}
          <ContainerBox>
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                fontSize="20px"
                fontWeight="bold"
                marginBottom="20px"
              >
                {editLeaveType ? "Update Leave" : "Add Leave"}
              </Typography>
            </Box>
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="basic-standard"
                      size="small"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      label="Name"
                      sx={{ label: { fontSize: 12 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel required>Leave Type</InputLabel>
                    <Select
                      label="Leave Type"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="leaveType"
                      size="small"
                      value={leaveType}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Student"}>Student</MenuItem>
                      <MenuItem value={"Employee"}>Employee</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {leaveType === "Employee" ? (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel size="small" required>
                          Department
                        </InputLabel>
                        <Select
                          label="Department"
                          labelId="demo-simpless-select-filled-label"
                          id="demo-simple-select-filled-l"
                          name="departments"
                          value={multipleDepartments}
                          onChange={handleChange}
                          multiple
                        >
                          {schoolDepartment.map((dept) => (
                            <MenuItem key={dept._id} value={dept._id}>
                              {dept.department}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {multipleDepartments.length ? (
                        <ListContainer>
                          {multipleDepartments.map((data) => (
                            <ListItemContainer key={data}>
                              <ListItem component="span">
                                {
                                  schoolDepartment.filter(
                                    (d) => d._id == data
                                  )[0].department
                                }
                              </ListItem>
                              <IconButton
                                onClick={() => handleRemoveDepartments(data)}
                              >
                                <CloseRoundedIcon fontSize="small" />
                              </IconButton>
                            </ListItemContainer>
                          ))}
                        </ListContainer>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl required fullWidth size="small">
                        <RadioGroup
                          size="small"
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="isSpecial"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                          value={isSpecial}
                          onChange={handleChange}
                        >
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            sx={{ fontSize: "15px", fontWeight: 600 }}
                          >
                            Is Special Type:
                          </FormLabel>
                          <FormControlLabel
                            value={"true"}
                            control={<Radio size="small" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={"false"}
                            control={<Radio size="small" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="basic-standard"
                          size="small"
                          name="total"
                          value={total}
                          onChange={handleChange}
                          label="Total"
                          style={{ label: { fontSize: 12 } }}
                          // sx={styles.textfield}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="autoEarned"
                          style={{ display: "flex", alignItems: "center" }}
                          value={autoEarned}
                          onChange={handleChange}
                        >
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            sx={{ fontSize: "15px", fontWeight: 600 }}
                          >
                            Is Leave Auto Earned:
                          </FormLabel>
                          <FormControlLabel
                            value={"true"}
                            control={<Radio size="small" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            size="small"
                            value={"false"}
                            control={<Radio size="small" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="standard-basic"
                          size="small"
                          name="autoEarnCount"
                          value={autoEarnCount}
                          onChange={handleChange}
                          label="Auto Earned Count :"
                          style={{ minWidth: 227, label: { fontSize: 12 } }}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="canResetCarryForward"
                          style={{ display: "flex", alignItems: "center" }}
                          value={canResetCarryForward}
                          onChange={handleChange}
                        >
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            sx={{ fontSize: "15px", fontWeight: 600 }}
                          >
                            Is Carryforward Reset:{" "}
                          </FormLabel>
                          <FormControlLabel
                            size="small"
                            value={"true"}
                            sx={{ fontSize: 15 }}
                            control={<Radio size="small" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            size="small"
                            value={"false"}
                            sx={{ fontSize: 15 }}
                            control={<Radio size="small" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="standard-basic"
                          size="small"
                          fullWidth
                          name="carryForwardCount"
                          value={carryForwardCount}
                          onChange={handleChange}
                          label="Is Carryorword Count :"
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid item xs={12} md={6} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                        gap: "1%",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleCancel}
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          marginLeft: "10px",
                          color: "white",
                          background: "rgb(27, 55, 121)",
                          // m: 1,
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        {editLeaveType ? "Update" : "Add"}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ContainerBox>
          {/* </Card> */}
        </form>
      </Modal>
    </Wrapper>
  );
}
export default LeaveType;
