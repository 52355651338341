import React from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "./App.scss";
// Import custom components
// import Redirect from "./utils/Redirect";
import { routes } from "./utils/Routes";
import Home from "./pages/HomePage";
import DashBoard from "./pages/DashBoard";
import JoinSession from "./components/JoinSession/JoinSession";
import "react-modal-video/css/modal-video.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./components/About/About";
import NavTopnav from "./components/NavTopnav/NavTopnav";
import AcademicYear from "./components/Layout/Administrator/AcademicYear";
import Academic from "./components/Layout/Academic/Academic";
import SchoolSection from "./components/Layout/Academic/SchoolSection";
import SchoolSubject from "./components/Layout/Academic/SchoolSubject";
import Designation from "./components/Layout/HumanResource/Designation";
import Department from "./components/Layout/HumanResource/Department";
import Employee from "./components/Layout/HumanResource/Employee";
import AddEmployee from "./components/Layout/HumanResource/AddEmployee";
import ExamGrade from "./components/Layout/Exam/ExamGrade";
import UserRole from "./components/Layout/Administrator/UserRole";
import ExamTerm from "./components/Layout/Exam/ExamTerm";
import ExamSchedule from "./components/Layout/Exam/ExamSchedule";
import HallTicket from "./components/Layout/Exam/HallTicket";
import AdmitStudent from "./components/Layout/Student/AdmitStudent";
import AddAdmitStudent from "./components/Layout/Student/AddAdmitStudent";
import ExamAttendance from "./components/Layout/ExamMark/ExamAttendance";

import Reshuffle from "./components/Layout/Student/Reshuffle";
import Promotion from "./components/Layout/Student/Promotion";
import Settings from "./components/Layout/Settings/Settings";
import AnnounceNews from "./components/Layout/Announcement/AnnounceNews";
import AnnounceHoliday from "./components/Layout/Announcement/AnnounceHoliday";
import Notice from "./components/Layout/Announcement/Notice";
import ClassRoutine from "./components/Layout/ClassRoutine/ClassRoutine";
import ManageMark from "./components/Layout/ExamMark/ManageMark";
import ExamMark from "./components/Layout/ExamMark/ExamResult";
import StudyCertificate from "./components/Layout/Certificate/StudyCertificate";
import EditStudent from "./components/Layout/Student/EditStudent";
import EditEmployee from "./components/Layout/HumanResource/EditEmployee";
import TransferCertificate from "./components/Layout/Certificate/TransferCertificate";
import MarksCard from "./components/Layout/ExamMark/MarksCard";
import Compose from "./components/Layout/Communication/Compose";
import Report from "./components/Layout/Communication/Report";
import TextMessage from "./components/Layout/Communication/TextMessage";
import RolePermission from "./components/Layout/Administrator/RolePermission";
import AdminRolePermission from "./components/Layout/Administrator/AdminRolePermission";
import AssignRolePermission from "./components/Layout/Administrator/AssignRolePermission";
import StudentAttendance from "./components/Layout/Attendance/StudentAttendance";
import EmployeeAttendance from "./components/Layout/Attendance/EmployeeAttendance";
import Sidebar1 from "./components/Navigation/components/Sidebar1";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import dataSidebar from "./components/Navigation/components/DataSidebar";
import { NavLink } from "react-router-dom";
import Consolidated from "./components/Layout/ExamMark/Consolidated";
import NewRolePermission from "./components/Layout/Administrator/NewRolePermission";
import { urls } from "./services/urlConstant";
import { get } from "./services/apis";
import StudentTable from "./components/Layout/Student/StudentTable";
import Login from "./components/Layout/Authentication/Login";
import ResetPassword from "./components/Layout/ResetPassword/ResetPassword";
import { objHas } from "./utils/utils";
import Event from "./components/Layout/Events/Event";
import ForgotPassword from "./components/Layout/ForgotPassword";
import BulkAdmission from "./components/Layout/Student/BulkAdmission";
import NavBar from "./NavBar";
import PrivateRoute from "./PrivateRoute";
import ResetPasswordUser from "./components/Layout/ResetPassword/ResetPasswordUser";
import Addmission from "./components/Addmission";
import Academics from "./components/Academics";
import Primary from "./components/Primary";
import HighSchool from "./components/HighSchool";
import Library from "./components/Library";
import Sports from "./components/Sports";
import Canteen from "./components/Canteen";
import Transport from "./components/Transport";
import EduSmart from "./components/EduSmart";
import Assignment from "./components/Layout/Assignment/Assignment";
import ContactUs from "./components/ContactUs";
import PublicRoute from "./PublicRoute";
import Results from "./components/Results";
import Gallery from "./components/Layout/Gallery/gallery";
import GalleryHome from "./components/GalleryHome";
import OurSchool from "./components/Our school/OurSchool";
import Principal from "./components/PrincipalDesk/Principal";
import Faculty from "./components/Faculty/Faculty";
import SettingContext from "./context/SettingsContext";
import EventHome from "./components/EventHome";
import UniqueFeatures from "./components/UniqueFeatures";
import KnowledgeOfParents from "./components/KnowledgeOfParents";
import Careers from "./components/Careers";
import HomeNews from "./components/HomeNews";
import HomeNewsView from "./components/HomeNewsView";
import HomeNotice from "./components/HomeNotice";
import HomeNoticeView from "./components/HomeNoticeView";
import EmployeeView from "./components/Layout/HumanResource/EmployeeView";
import AssignmentHome from "./components/AssignmentHome";
import AssignmentView from "./components/AssignmentView";
import Credential from "./components/Layout/Student/Credential";
import Birthday from "./components/Layout/Birthday/Birthday";
import Awards from "./components/Layout/Awards/Awards";
import AwardsAndAchivments from "./components/AwardsAndAchivments";
import Overview from "./components/Layout/Student/Overview";
import LatestEventsById from "./components/LatestEventsById";
import StudentBulkPhoto from "./components/Layout/Student/StudentBulkPhoto";
import Toppers from "./components/toppers";
import ReceiptBook from "./components/Layout/Fees/ReceiptBook";
import QuickFee from "./components/Layout/Fees/QuickFee";
import QuickFeeCollection from "./components/Layout/Fees/QuickFeeCollection";
import FeeCategoryList from "./components/Layout/Fees/FeeCategoryList";
import FeeRemittance from "./components/Layout/Fees/FeeRemittance";
import DetailedFeeCollection from "./components/Layout/Fees/DetailedFeeCollection";
import BalanceFeeReport from "./components/Layout/Fees/balanceFeeReport";
import FeeOverview from "./components/Layout/Fees/feeOverview";
import Credentails from "./components/Layout/Communication/Credentials";
import StudentDashbord from "./pages/StudentDashbord";
import Hostel from "./components/Hostel/Hostel";
import ManageRoom from "./components/Hostel/ManageRoom";
import ManageRoomType from "./components/Hostel/ManageRoomType";
import ManageRoomFee from "./components/Hostel/ManageRoomFee";
import HostelMember from "./components/Hostel/HostelMember";
import StudentLibrary from "./components/StudentLibrary/StudentLibrary";
import Periodical from "./components/StudentLibrary/Periodical";
import StudentMember from "./components/StudentLibrary/StudentMember";
import StudentIssue from "./components/StudentLibrary/StudentIssue";
import EmployeeMember from "./components/StudentLibrary/EmployeeMember";
import EmployeeIssue from "./components/StudentLibrary/EmployeeIssue";
import Vehicle from "./components/Transport/Vehicle";
import ManageRoute from "./components/Transport/ManageRoute";
import Trips from "./components/Transport/Trips";
import TransportMember from "./components/Transport/TransportMember";
import VehicleLog from "./components/Transport/VehicleLog";
import VehicleMaintenance from "./components/Transport/VehicleMaintenance";
import ManageStopFee from "./components/Transport/ManageStopFee";

import { TableDrawer } from "./context/DrawerComponent";
import SplashNews from "./components/Layout/Announcement/SplashNews";
import SalaryGrade from "./components/Layout/Payroll/SalaryGrade";
import SalaryPayment from "./components/Layout/Payroll/SalaryPayment";
import PaymentHistroy from "./components/Layout/Payroll/PaymentHistory";
// import StudentCheckOut from "./components/VisitorInfo/StudentCheckout";
import VisitoryInfo from "./components/VisitorInfo/VisitoInfo";
import LibraryReport from "./components/Layout/Report/LibraryReport";
import StudentYearlyAttendence from "./components/Layout/Report/StudentYearlyAttendance";
import TeacherAttendence from "./components/Layout/Report/TeacherAttendance";
import StudentAttendenceReport from "./components/Layout/Report/StudentAttendanceReport";
import TeacherYearlyAttendence from "./components/Layout/Report/TeacherYearlyAttendance";
import EmployeeAttendence from "./components/Layout/Report/EmployeeAttendance";
import EmployeeYearlyAttendence from "./components/Layout/Report/EmployeeYearlyAttendance";
import StudentReport from "./components/Layout/Report/StudentReport";
import StudentActivityReport from "./components/Layout/Report/StudentActivityReport";
import LeaveType from "./components/Layout/LeaveManagement/LeaveType";
import EmployeeLeave from "./components/Layout/LeaveManagement/EmployeeLeave";
import StudentsLeave from "./components/Layout/LeaveManagement/StudentsLeave";
import TeacherLeave from "./components/Layout/LeaveManagement/TeacherLeave";
import LeaveReport from "./components/Layout/LeaveManagement/LeaveReport";
import AboutFounder from "./components/About/AboutFounder";
import DanceAndSinging from "./components/DanceAndSinging";
import OverView from "./components/About/OverView";
import VisionMission from "./components/About/VisionMission";
import EventDetails from "./components/SchoolCard/EventDetails";
import CourseDetails from "./components/HomeComponents/CourseDetails";
import DrawerWidthContext from "./context/DrawerWidthContext";
import ScrollToTop from "./components/ScrollToTop";
import StudentCheckout from "./components/VisitorInfo/StudentCheckout";

// theme 2 components
import Addmission2 from "./Components2/Admission";
import OverView2 from "./Components2/about/Overview";
import AboutFounder2 from "./NavbarComponents/AboutFounder";
import VisionMission2 from "./NavbarComponents/VisionandMission";
import ContactUs2 from "./Components2/ConatctUs";
import Canteen2 from "./Components2/Category/Facilities/Food";
import Library2 from "./Components2/Category/Facilities/Library";
import Transport2 from "./Components2/Category/Facilities/Transport";
import DanceAndSinging2 from "./Components2/Category/Facilities/Dance";
import LabFacilities from "./Components2/Category/Facilities/Lab";
import Results2 from "./Components2/Results";
import NewsDetails from "./Components2/NewsAndNotice/NewsDetails";
import EventInfo from "./Components2/events/EventInfo";
import Detail from "./ourblog/Detail";
import ManageInstitute from "./components/Layout/Settings/ManageInstitute";
import IssueDetails from "./components/Layout/Inventory/IssueDetails";
import ItemInventory from "./components/Layout/Inventory/ItemInventory";
import Item from "./components/Layout/Inventory/Item";

import Vendor from "./components/Layout/Inventory/Vendor";

import Transaction from "./components/Layout/Inventory/Transaction";
import Reconciliation from "./components/Layout/Fees/Reconciliation";
// import Issue from "./components/Layout/Inventory/Issue";
import SplashNewsDisplay from "./components/Layout/Announcement/SplashNewsDisplay";
import SplashNewsHorizontal from "./components/Layout/Announcement/SplashNewsHorizontal";
import AddSetting from "./components/Layout/Settings/AddSetting";
import PasswordReset from "./components/Layout/NavbarProfile/PasswordReset";
import NavbarProfile from "./components/Layout/NavbarProfile/NavbarProfile";
import PageNotFound from "./pages/PageNotFound";

import TeacherActivity from "./components/Layout/Teacher/TeacherActivity";
import Account from "./components/Layout/Account";
import FeedBack from "./components/Layout/Teacher/FeedBack";
import TransportFee from "./components/Transport/TransportFee";
import AccountSuspended from "./components/AccountSuspended/AccountSuspended";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const excludePath = [
  "/home-assignment",
  "/home-notice",
  `/home-news`,
  "/home-news",
  "/home-careers",
  "/to-the-knowledge-of-parents",
  "/unique-features",
  "/home-gallery",
  "/results",
  "/contact-us",
  "/facilities/edusmart",
  "/facilities/transport",
  "/",
  "/login",
  "/forgot-password",
  "/principal",
  "/desk",
  "/facality",
  "/addmission",
  "/our-school",
  "/academics",
  "/academics/primary",
  "/academics/high-school",
  "/facilities/library",
  "/facilities/sports",
  "/facilities/canteen",
  "/view-assignment",
  "/about/founder",
  "/about/visionandmission",
  "/about/overview",
  "/facilities/dance-and-singing",
  "/eventdetails",
  "/courseDetails",
  "/unique-features",
  "/to-the-knowledge-of-parents",
  "/facilities/labs",
];

function App() {
  const [isReady, setIsReady] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  let updateNav = false;
  const token = window.localStorage.getItem("token");
  if (token) updateNav = true;
  const [reRender, setRender] = useState(1);
  const [showDrawerHeader, setShowDrawerHeader] = useState(true);
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState({
    schoolName: "ABC School",
    email: "abc@gmail.com",
    phone: 9999999999,
    themeOrder: 1,
  });
  const [drawerWidth, setDraweWidth] = useState(0);
  const [canShowDrawer, setCanShowDrawer] = useState(false);
  const [popupData, setPopupData] = useState({
    open: false,
    data: null,
  });
  const [horizontalData, setHorizontalData] = useState({
    //  open: false,
    //  data: null,
  });

  const handleClosePopup = () => setPopupData({ open: false, data: null });
  let userType = window.localStorage.getItem("userType");
  console.log(userType, "=====");

  const getSettings = async () => {
    try {
      const { data } = await get("/123/settings");
      setSettings(data.data);
      setSelectedSetting(data.data[0]);
    } catch (error) {
      console.log(error);
    }
    setIsReady(true);
  };

  useEffect(() => {
    getSettings();
  }, []);

  const [settingsContext, setSettingsContext] = useState({
    schoolName: "",
    schoolLogo: "",
    activeAcademicYear: "",
  });

  useEffect(() => {
    // call the popup api to show popup data
  }, []);

  useEffect(() => {
    //call the hozizontal api to show horizontal data
  }, []);

  useEffect(() => {
    const exclude =
      excludePath.includes(pathname) ||
      pathname.startsWith("/home-news") ||
      pathname.startsWith("/home-notice") ||
      pathname.startsWith("/view-assignment") ||
      pathname.startsWith("/awards-home") ||
      pathname.startsWith("/events-home") ||
      pathname.startsWith("/eventdetails") ||
      pathname.startsWith("/courseDetails") ||
      pathname.startsWith("/details") ||
      pathname.startsWith("/ourachievement/") ||
      pathname.startsWith("/toppers-list");
    setShowDrawerHeader(!exclude);
  }, [location.pathname]);

  useEffect(() => {
    for (let path of excludePath) {
      if (pathname.startsWith(path)) {
        return setCanShowDrawer(false);
      }
    }
  }, [location.path]);

  const triggerRender = () => {
    console.log("cbsdjbc");
    setRender((prev) => prev + 1);
  };

  let [drawer, setDrawer] = useState(true);
  const [padding, setPadding] = useState("270px");

  useEffect(() => {
    if (drawer) {
      setPadding("270px");
    } else {
      setPadding("60px");
    }
  }, [drawer]);

  if (!isReady) return null;

  return <AccountSuspended />;
  return (
    <SettingContext.Provider
      value={{
        settingsContext,
        setSettingsContext,
        settings,
        setSettings,
        selectedSetting,
        setSelectedSetting,
      }}
    >
      {popupData.data ? (
        <SplashNewsDisplay
          open={popupData.open}
          sharedData={popupData.data}
          handleClose={handleClosePopup}
        />
      ) : null}
      {horizontalData.data ? (
        <SplashNewsHorizontal horizontalData={horizontalData.data} />
      ) : null}
      <ScrollToTop />
      <TableDrawer.Provider value={{ drawer, setDrawer }}>
        <DrawerWidthContext.Provider value={{ drawerWidth, setDraweWidth }}>
          <Box
            sx={{
              paddingLeft:
                updateNav &&
                userType === "employee" &&
                !excludePath.includes(pathname) &&
                showDrawerHeader
                  ? padding
                  : 0,
            }}
          >
            <CssBaseline />
            {updateNav && userType === "employee" && (
              <NavBar reRender={reRender} />
            )}
            <div className="App">
              <Box component="main">
                {showDrawerHeader && userType === "employee" ? (
                  <DrawerHeader />
                ) : null}
                <StylesProvider injectFirst>
                  <Routes>
                    <Route
                      path="/account-suspended"
                      element={<AccountSuspended />}
                    />

                    <Route path="/" element={<NavTopnav />} exact>
                      <Route path="/" element={<Home />} />
                      <Route path="/about" element={<About />} />
                    </Route>

                    {/* Common */}
                    <Route
                      path="/login"
                      element={
                        <PublicRoute>
                          <Login />
                        </PublicRoute>
                      }
                    />

                    <Route
                      path="/dashboard/sd"
                      element={
                        <PrivateRoute>
                          <StudentDashbord />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/moreproject"
                      element={<JoinSession />}
                      exact
                    />
                    <Route
                      path={routes.dashBoard}
                      element={
                        <PrivateRoute>
                          <DashBoard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/academicyear"
                      element={
                        <PrivateRoute>
                          <AcademicYear />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/class"
                      element={
                        <PrivateRoute>
                          <Academic />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/section"
                      element={
                        <PrivateRoute>
                          <SchoolSection />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/subject"
                      element={
                        <PrivateRoute>
                          <SchoolSubject />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/designation"
                      element={
                        <PrivateRoute>
                          <Designation />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/department"
                      element={
                        <PrivateRoute>
                          <Department />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/employee"
                      element={
                        <PrivateRoute>
                          <Employee />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/addemployee"
                      element={
                        <PrivateRoute>
                          <AddEmployee />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/examgrade"
                      element={
                        <PrivateRoute>
                          <ExamGrade />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/role"
                      element={
                        <PrivateRoute>
                          <UserRole />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/examterm"
                      element={
                        <PrivateRoute>
                          <ExamTerm />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/examschedule"
                      element={
                        <PrivateRoute>
                          <ExamSchedule />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/addSetting"
                      element={
                        <PrivateRoute>
                          <AddSetting />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/exam/hallticket"
                      element={
                        <PrivateRoute>
                          <HallTicket />
                          {/* <SalaryPayment /> */}

                          {/* <SalaryGrade/> */}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/admitstudent"
                      element={
                        <PrivateRoute>
                          <StudentTable />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/addstudent"
                      element={
                        <PrivateRoute>
                          <AddAdmitStudent />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/examattendance"
                      element={
                        <PrivateRoute>
                          <ExamAttendance />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/teacher"
                      element={<TeacherActivity />}
                    />

                    <Route path="/desk" element={<Faculty />} />
                    <Route
                      path="/managemark"
                      element={
                        <PrivateRoute>
                          <ManageMark />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route path="/event" element={<Event />} />
                    <Route path="/principal" element={<Principal />} />
                    <Route
                      path="/examresult"
                      element={
                        <PrivateRoute>
                          <ExamMark />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/reshuffle"
                      element={
                        <PrivateRoute>
                          <Reshuffle />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/promotion"
                      element={
                        <PrivateRoute>
                          <Promotion />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/bulkadmission"
                      element={
                        <PrivateRoute>
                          <BulkAdmission />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/settings/edit/:id"
                      element={
                        <PrivateRoute>
                          <Settings />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/resetPassword"
                      element={
                        <PrivateRoute>
                          <PasswordReset />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <PrivateRoute>
                          <NavbarProfile />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/manageSetting"
                      element={
                        <PrivateRoute>
                          <ManageInstitute />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      path="/issue"
                      element={
                        <PrivateRoute>
                          <Issue />
                        </PrivateRoute>
                      }
                    /> */}
                    <Route
                      path="/announcenews"
                      element={
                        <PrivateRoute>
                          <AnnounceNews />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/announceholiday"
                      element={
                        <PrivateRoute>
                          <AnnounceHoliday />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/notice"
                      element={
                        <PrivateRoute>
                          <Notice />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/splashNews"
                      element={
                        <PrivateRoute>
                          <SplashNews />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="routine"
                      element={
                        <PrivateRoute>
                          <ClassRoutine />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/studycertificate"
                      element={
                        <PrivateRoute>
                          <StudyCertificate />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/edit-student/:studentId"
                      element={
                        <PrivateRoute>
                          <EditStudent />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/edit-employee/:employeeId"
                      element={
                        <PrivateRoute>
                          <EditEmployee />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/transfercertificate"
                      element={
                        <PrivateRoute>
                          <TransferCertificate />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/marks-card"
                      element={
                        <PrivateRoute>
                          <MarksCard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/compose"
                      element={
                        <PrivateRoute>
                          <Compose />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/sms-report"
                      element={
                        <PrivateRoute>
                          <Report />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/text-message"
                      element={
                        <PrivateRoute>
                          <TextMessage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/role-permission"
                      element={
                        <PrivateRoute>
                          <RolePermission />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/role-permission/:id"
                      element={
                        <PrivateRoute>
                          <AssignRolePermission />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/admin-role-permission"
                      element={
                        <PrivateRoute>
                          <AdminRolePermission />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/student-attendance"
                      element={
                        <PrivateRoute>
                          <StudentAttendance />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/employee-attendance"
                      element={
                        <PrivateRoute>
                          <EmployeeAttendance />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/sidebar-1"
                      element={
                        <PrivateRoute>
                          <Sidebar1 />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/consolidated"
                      element={
                        <PrivateRoute>
                          <Consolidated />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/new-role-permission"
                      element={
                        <PrivateRoute>
                          <NewRolePermission />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/receipt"
                      element={
                        <PrivateRoute>
                          <ReceiptBook />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/quick-fee"
                      element={
                        <PrivateRoute>
                          <QuickFee />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/quick-fee-collection"
                      element={
                        <PrivateRoute>
                          <QuickFeeCollection />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/fee-remittance"
                      element={
                        <PrivateRoute>
                          <FeeRemittance />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/detailed-fee"
                      element={
                        <PrivateRoute>
                          <DetailedFeeCollection />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/feecategorylist"
                      element={
                        <PrivateRoute>
                          <FeeCategoryList />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/balancefeereport"
                      element={
                        <PrivateRoute>
                          <BalanceFeeReport />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/feeoverview"
                      element={
                        <PrivateRoute>
                          <FeeOverview />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="dashboard/fee/reconciliation"
                      element={
                        <PrivateRoute>
                          <Reconciliation />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/reset-password"
                      element={
                        <PrivateRoute>
                          <ResetPassword />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/event"
                      element={
                        <PrivateRoute>
                          <Event />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/ac"
                      element={
                        <PrivateRoute>
                          <Account />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/teacherFeedback"
                      element={
                        <PrivateRoute>
                          <FeedBack />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/hostel"
                      element={
                        <PrivateRoute>
                          <Hostel />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/hostelroom"
                      element={
                        <PrivateRoute>
                          <ManageRoom />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/hostelroomtype"
                      element={
                        <PrivateRoute>
                          <ManageRoomType />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/hostelroomfee"
                      element={
                        <PrivateRoute>
                          <ManageRoomFee />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/hostelmember"
                      element={
                        <PrivateRoute>
                          <HostelMember />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/grade"
                      element={
                        <PrivateRoute>
                          <SalaryGrade />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/payment"
                      element={
                        <PrivateRoute>
                          <SalaryPayment />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/librarybooks"
                      element={
                        <PrivateRoute>
                          <StudentLibrary />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/libraryperiodical"
                      element={
                        <PrivateRoute>
                          <Periodical />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/studentlibrarymember"
                      element={
                        <PrivateRoute>
                          <StudentMember />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/studentissue"
                      element={
                        <PrivateRoute>
                          <StudentIssue />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/employeelibrary"
                      element={
                        <PrivateRoute>
                          <EmployeeMember />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/employeeissue"
                      element={
                        <PrivateRoute>
                          <EmployeeIssue />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/vehicle"
                      element={
                        <PrivateRoute>
                          <Vehicle />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/transportfee"
                      element={
                        <PrivateRoute>
                          <TransportFee />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/route"
                      element={
                        <PrivateRoute>
                          <ManageRoute />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/trips"
                      element={
                        <PrivateRoute>
                          <Trips />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/transportmember"
                      element={
                        <PrivateRoute>
                          <TransportMember />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/visitoryinfo"
                      element={
                        <PrivateRoute>
                          <VisitoryInfo />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/studentcheckout"
                      element={
                        <PrivateRoute>
                          <StudentCheckout />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/maintenance"
                      element={
                        <PrivateRoute>
                          <VehicleMaintenance />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/stop"
                      element={
                        <PrivateRoute>
                          <ManageStopFee />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/log"
                      element={
                        <PrivateRoute>
                          <VehicleLog />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/maintenance"
                      element={
                        <PrivateRoute>
                          <VehicleMaintenance />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/stop"
                      element={
                        <PrivateRoute>
                          <ManageStopFee />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/libraryReport"
                      element={
                        <PrivateRoute>
                          <LibraryReport />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/report/yearlyAttendance"
                      element={
                        <PrivateRoute>
                          <StudentYearlyAttendence />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/teacherAttendance"
                      element={
                        <PrivateRoute>
                          <TeacherAttendence />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/studentAttendance"
                      element={
                        <PrivateRoute>
                          <StudentAttendenceReport />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/teacherYearlyAttendance"
                      element={
                        <PrivateRoute>
                          <TeacherYearlyAttendence />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/teacherYearlyAttendance"
                      element={
                        <PrivateRoute>
                          <TeacherYearlyAttendence />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/employeeAttendance"
                      element={
                        <PrivateRoute>
                          <EmployeeAttendence />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/employeeYearlyAttendance"
                      element={
                        <PrivateRoute>
                          <EmployeeYearlyAttendence />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/studentReport"
                      element={
                        <PrivateRoute>
                          <StudentReport />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/report/studentActivityReport"
                      element={
                        <PrivateRoute>
                          <StudentActivityReport />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/leaveType"
                      element={
                        <PrivateRoute>
                          <LeaveType />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/employeeLeave"
                      element={
                        <PrivateRoute>
                          <EmployeeLeave />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/studentsLeave"
                      element={
                        <PrivateRoute>
                          <StudentsLeave />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/teacherLeave"
                      element={
                        <PrivateRoute>
                          <TeacherLeave />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard/leaveReport"
                      element={
                        <PrivateRoute>
                          <LeaveReport />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/bulkadmission"
                      element={
                        <PrivateRoute>
                          <BulkAdmission />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/reset-password-user"
                      element={
                        <PrivateRoute>
                          <ResetPasswordUser />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/assignment"
                      element={
                        <PrivateRoute>
                          <Assignment />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/profile-view/:employeeId"
                      element={
                        <PrivateRoute>
                          <EmployeeView />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/student-credential"
                      element={
                        <PrivateRoute>
                          <Credential />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/awards"
                      element={
                        <PrivateRoute>
                          <Awards />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/birthday"
                      element={
                        <PrivateRoute>
                          <Birthday />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/student-bulk-photo"
                      element={
                        <PrivateRoute>
                          <StudentBulkPhoto />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/send-credentials"
                      element={
                        <PrivateRoute>
                          <Credentails />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/addmission"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <Addmission />
                        ) : (
                          <Addmission2 />
                        )
                      }
                    />
                    <Route
                      path="/about/visionandmission"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <VisionMission />
                        ) : (
                          <VisionMission2 />
                        )
                      }
                    />
                    <Route path="/academics" element={<Academics />} />
                    <Route path="/academics/primary" element={<Primary />} />
                    <Route
                      path="/academics/high-school"
                      element={<HighSchool />}
                    />
                    <Route
                      path="/courseDetails/:id"
                      element={<CourseDetails />}
                    />

                    <Route
                      path="/about/founder"
                      element={
                        selectedSetting.themeOrder == 1 ? (
                          <AboutFounder />
                        ) : (
                          <AboutFounder2 />
                        )
                      }
                    />
                    <Route path="/student-overview" element={<Overview />} />
                    <Route
                      path="/about/overview"
                      element={
                        selectedSetting.themeOrder == 1 ? (
                          <OverView />
                        ) : (
                          <OverView2 />
                        )
                      }
                    />

                    <Route
                      path="/facilities/library"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <Library />
                        ) : (
                          <Library2 />
                        )
                      }
                    />
                    <Route
                      path="/facilities/labs"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <PageNotFound />
                        ) : (
                          <LabFacilities />
                        )
                      }
                    />
                    <Route path="/facilities/sports" element={<Sports />} />
                    <Route
                      path="/facilities/dance-and-singing"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <DanceAndSinging />
                        ) : (
                          <DanceAndSinging2 />
                        )
                      }
                    />
                    <Route
                      path="/facilities/labs"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <PageNotFound />
                        ) : (
                          <LabFacilities />
                        )
                      }
                    />
                    <Route
                      path="/facilities/canteen"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <Canteen />
                        ) : (
                          <Canteen2 />
                        )
                      }
                    />
                    <Route
                      path="/facilities/transport"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <Transport />
                        ) : (
                          <Transport2 />
                        )
                      }
                    />
                    <Route path="/facilities/edusmart" element={<EduSmart />} />
                    <Route
                      path="/contact-us"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <ContactUs />
                        ) : (
                          <ContactUs2 />
                        )
                      }
                    />
                    <Route
                      path="/results"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <Results />
                        ) : (
                          <Results2 />
                        )
                      }
                    />

                    <Route path="/details/:id" element={<NewsDetails />} />
                    <Route path="/events/:id" element={<EventInfo />} />
                    <Route path="/ourachievement/:id" element={<Detail />} />

                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/home-gallery" element={<GalleryHome />} />
                    <Route path="/our-school" element={<OurSchool />} />
                    <Route path="/principal" element={<Principal />} />
                    <Route path="/facality" element={<Faculty />} />
                    <Route path="/text-message" element={<TextMessage />} />
                    <Route path="/event-home" element={<EventHome />} />
                    <Route
                      path="/unique-features"
                      element={<UniqueFeatures />}
                    />
                    <Route
                      path="/to-the-knowledge-of-parents"
                      element={<KnowledgeOfParents />}
                    />
                    <Route path="/home-careers" element={<Careers />} />

                    <Route
                      path="/bulkadmission"
                      element={
                        <PrivateRoute>
                          <BulkAdmission />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/reset-password-user"
                      element={
                        <PrivateRoute>
                          <ResetPasswordUser />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/assignment"
                      element={
                        <PrivateRoute>
                          <Assignment />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/profile-view/:employeeId"
                      element={
                        <PrivateRoute>
                          <EmployeeView />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/student-credential"
                      element={
                        <PrivateRoute>
                          <Credential />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/awards"
                      element={
                        <PrivateRoute>
                          <Awards />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/birthday"
                      element={
                        <PrivateRoute>
                          <Birthday />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/student-bulk-photo"
                      element={
                        <PrivateRoute>
                          <StudentBulkPhoto />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/send-credentials"
                      element={
                        <PrivateRoute>
                          <Credentails />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/vendor"
                      element={
                        <PrivateRoute>
                          <Vendor />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/item"
                      element={
                        <PrivateRoute>
                          <Item />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/transaction"
                      element={
                        <PrivateRoute>
                          <Transaction />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/itemInventory"
                      element={
                        <PrivateRoute>
                          <ItemInventory />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dashboard/issueDetails"
                      element={
                        <PrivateRoute>
                          <IssueDetails />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/eventdetails/:id"
                      element={<EventDetails />}
                    />
                    {/* <Route path="/addmission" element={<Addmission />} /> */}
                    <Route path="/academics" element={<Academics />} />
                    <Route path="/academics/primary" element={<Primary />} />
                    <Route
                      path="/academics/high-school"
                      element={<HighSchool />}
                    />
                    <Route path="/student-overview" element={<Overview />} />
                    <Route
                      path="/facilities/library"
                      element={
                        selectedSetting.themeOrder === 1 ? (
                          <Library />
                        ) : (
                          <Library2 />
                        )
                      }
                    />
                    <Route path="/facilities/sports" element={<Sports />} />
                    <Route path="/facilities/canteen" element={<Canteen />} />
                    <Route
                      path="/facilities/transport"
                      element={<Transport />}
                    />
                    <Route path="/facilities/edusmart" element={<EduSmart />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/results" element={<Results />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/home-gallery" element={<GalleryHome />} />
                    <Route path="/our-school" element={<OurSchool />} />
                    <Route path="/principal" element={<Principal />} />
                    <Route path="/facality" element={<Faculty />} />
                    <Route path="/text-message" element={<TextMessage />} />
                    <Route path="/event-home" element={<EventHome />} />
                    <Route
                      path="/unique-features"
                      element={<UniqueFeatures />}
                    />
                    <Route
                      path="/to-the-knowledge-of-parents"
                      element={<KnowledgeOfParents />}
                    />
                    <Route path="/home-careers" element={<Careers />} />

                    <Route path="/home-news" element={<HomeNews />} />
                    <Route
                      path="/home-news/:newsId"
                      element={<HomeNewsView />}
                    />
                    <Route path="/home-notice" element={<HomeNotice />} />
                    <Route
                      path="/home-notice/:noticeId"
                      element={<HomeNoticeView />}
                    />
                    <Route
                      path="/home-assignment"
                      element={<AssignmentHome />}
                    />
                    <Route
                      path="/view-assignment"
                      element={<AssignmentView />}
                    />
                    <Route
                      path="/awards-home/:awardsId"
                      element={<AwardsAndAchivments />}
                    />
                    <Route
                      path="/events-home/:eventsId"
                      element={<LatestEventsById />}
                    />
                    <Route path="/toppers-list" element={<Toppers />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </StylesProvider>
              </Box>
              <ToastContainer />
            </div>
          </Box>
        </DrawerWidthContext.Provider>
      </TableDrawer.Provider>
    </SettingContext.Provider>
  );
}

export default App;
