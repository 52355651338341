import React, { useEffect, useContext } from "react";
import "../../../styles/components/Certificate/TransferCertificate.scss";
import {
  Card,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Autocomplete,
  styled,
  Container,
  Typography,
} from "@mui/material";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useState } from "react";
import axios from "axios";
import { instance } from "../../../services/axios.Config";
import { FaBox } from "react-icons/fa";
import data from "./certificate";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
const styles = {
  card: {
    // margin: 15,
    padding: 20,
    marginTop: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 50 },
    // mt: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "30px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "left",
  },
}));

function TransferCertificate() {
  const { selectedSetting } = useContext(SettingContext);

  const [settings, setSettings] = useState({});
  const [formData, setFormData] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const [selectAcademicYear, setSelectAcademicYear] = useState([]);
  const [studentsSC, setStudentsSC] = useState([]);
  const [studentSelect, setStudentSelect] = useState({});
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const getSettings = async () => {
      try {
        const settings = await get(urls.settings.getAllSettings);
        setSettings(settings.data.data[0]);
        const data = settings.data.data[0];
        setFormData({
          ...formData,
          schoolName: data.schoolName,
        });
        setSelectAcademicYear([]);
        setFormData({});
      } catch (error) {
        console.log(error);
      }
    };
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
    getSettings();
    setSelectAcademicYear([]);

    setStudentSelect(null);
    setFormData({});
  }, [selectedSetting._id]);
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleAcademicYears = async (e) => {
    try {
      setStudentSelect(null);
      setSelectAcademicYear(e.target.value);

      const res = await get(urls.students.getAllStudent, {
        params: {
          search: {
            academicYear: e.target.value,
          },
        },
      });
      setStudentsSC(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStudentSelect = (val, newVal) => {
    console.log(newVal);
    setStudentSelect(newVal);
    setFormData({});
  };

  const handleIssue = async (e) => {
    e.preventDefault();

    try {
      setFormData({
        ...formData,
        studentName: studentSelect.basicInfo && studentSelect.basicInfo.name,
        birthPlace:
          studentSelect.basicInfo && studentSelect.basicInfo.birthPlace,
        sex: studentSelect.basicInfo && studentSelect.basicInfo.gender,
        religion: studentSelect.basicInfo && studentSelect.basicInfo.religion,
        caste: studentSelect.basicInfo && studentSelect.basicInfo.caste,
        aadhar: studentSelect.basicInfo && studentSelect.basicInfo.aadharNo,
        fatherName:
          studentSelect.fatherInfo && studentSelect.fatherInfo.fatherName,
        motherName:
          studentSelect.motherInfo && studentSelect.motherInfo.motherName,
        admissionDate:
          studentSelect.basicInfo && studentSelect.basicInfo.addmissionDate,
        prevSchool:
          studentSelect.prevSchInfo && studentSelect.prevSchInfo.prevSchool,
        currentClass:
          studentSelect.academicInfo &&
          studentSelect.academicInfo.class.className,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const transferCertificate = await instance({
        method: "post",
        url: `/${selectedSetting._id}/transfer-certificate`,
        data: {
          data: formData,
        },
        responseType: "blob",
      });
      const uri = URL.createObjectURL(transferCertificate.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };

  console.log(studentSelect, "selected Student");
  return (
    <Container>
      <SectionSubHeader title="Transfer Certificate" />
      <Card style={styles.card}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            // sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <FormControl
              size="small"
              // sx={{ width: { md: 227, xs: "100%" } }}
              fullWidth
            >
              <InputLabel>Academic Year</InputLabel>
              <Select
                label="Academic Year"
                fullWidth
                name="Academic Year"
                value={selectAcademicYear}
                onChange={(event) => handleAcademicYears(event)}
              >
                {academicYears &&
                  academicYears.map((row, index) => (
                    <MenuItem
                      key={row._id}
                      value={row._id}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      {row.academicYearFrom} - {row.academicYearTo}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            // sx={{ display: "flex", justifyContent: "center" }}
          >
            <Autocomplete
              fullWidth
              size="small"
              key={selectedSetting._id}
              //itemID={selectedSetting._id}
              // sx={{ width: { md: 227, xs: "100%" } }}
              // inputValue={studentSelect ? studentSelect.basicInfo.name : ""}
              disablePortal
              getOptionLabel={(option) => option.basicInfo.name}
              id="combo-box-demo-id"
              options={studentsSC}
              onChange={handleStudentSelect}
              renderInput={(params) => (
                <TextField
                  //   value={studentSelect? studentSelect.basicInfo.name : ""}
                  size="small"
                  fullWidth
                  {...params}
                  label="Students"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
              display: "flex",
              gap: "5px",
              // mt: 2,
              justifyContent: { md: "flex-end", xs: "center", sm: "center" },
            }}
          >
            <Button
              // onClick={handleSubmit}
              type="submit"
              onClick={handleIssue}
              size="small"
              variant="contained"
              sx={styles.Button}
            >
              Issue
            </Button>
            <Button size="small" variant="contained" sx={styles.Button}>
              Bulk Issue
            </Button>
            <Button
              size="small"
              onClick={handleSubmit}
              variant="contained"
              sx={styles.Button}
            >
              Print
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <span className="excellent">{selectedSetting.schoolName}</span> <br />
          <Box className="schl-name">
            ENGLISH MEDIUM PRIMARY AND HIGH SCHOOL
          </Box>
          <div className="address-in-certificate">
            {selectedSetting.address}
          </div>
          <span className="tc-title">TRANSFER CERTIFICATE</span>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <MainBox className="main-container">
            <div className="tc-container">
              <div className="tc-details">
                <div className="tc-label" style={{ backgroundColor: "red" }}>
                  1] Name of the School:
                </div>
                <Box
                  sx={{}}
                  className="tc-inputs"
                  fontStyle={{ backgroundColor: "yellow" }}
                >
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="schoolName"
                    value={selectedSetting.schoolName}
                    onChange={handleFormChange}
                  />
                </Box>
              </div>
              <div className="tc-details">
                <div className="tc-label">2] Name of the Pupil in full:</div>
                <Box className="tc-inputs" sx={{}}>
                  <TextField
                    sx={{
                      width: "80%",
                      textAlign: "end",
                    }}
                    id="standard-basic"
                    variant="standard"
                    name="studentName"
                    value={formData.studentName || ""}
                    onChange={handleFormChange}
                  />
                </Box>
              </div>
              <div className="tc-details">
                <div className="tc-label">3] G. R. No:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="grNo"
                    value={formData.grNo || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">4] Place of Birth:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="birthPlace"
                    value={formData.birthPlace || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  5] Date of Birth(in figures and words):
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="dobWords"
                    value={formData.dobWords || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">6] Sex:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="sex"
                    value={formData.sex || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">7] Nationality:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="nationality"
                    value={formData.nationality || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">8] Religion:</div>

                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="religion"
                    value={formData.religion || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">9] Caste:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="caste"
                    value={formData.caste || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  10] Whether the candidate belongs to SC and ST":
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="belongs"
                    value={formData.belongs || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">11] AAdhar No.:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="aadhar"
                    value={formData.aadhar || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">12] Name of the father:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="fatherName"
                    value={formData.fatherName || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">13] Name of the mother:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="motherName"
                    value={formData.motherName || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  14] Date of Admission in the Scchool:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="admissionDate"
                    value={formData.admissionDate || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">15] School Previously Attended:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="prevSchool"
                    value={formData.prevSchool || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  16] Standard in which admission is granted:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="admissionGrant"
                    value={formData.admissionGrant}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  17] Standard in which the pupil was studying at the time of
                  leaving the school(in words):
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="currentClass"
                    value={formData.currentClass || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">18] Medium of instruction:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="instructions"
                    value={formData.instructions || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  19] Whether the pupil has paid all the free due of the school:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="fees"
                    value={formData.fees || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">20] Promoted to/ Detained in:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="proDet"
                    value={formData.proDet || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  21] Whether medically examinated or not ? :
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="medical"
                    value={formData.medical || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  22] Date of pupil's last attendance at the school:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="lastAttend"
                    value={formData.lastAttend || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  23] Date of which the application for the Transfer Certificate
                  was received:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="transferRecivied"
                    value={formData.transferRecivied || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  24] Date of issue of the Transfer Certificate:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="dateIssue"
                    value={formData.dateIssue || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">25] Attendance:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="attendance"
                    value={formData.attendance || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">26] Character & Conduct:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="character"
                    value={formData.character || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>
          </MainBox>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Container sx={{ marginTop: "5%" }}>
            {data.map((ele, i) => (
              <Box
                sx={{
                  display: { md: "flex", sm: "flex", xs: "block" },
                  margin: "5px",
                  justifyContent: { md: "space-between", xs: "center" },
                }}>
                <Box sx={{ width: { md: "45%", sm: "60%" }, display: "flex" }}>
                  <Typography variant="subtitle1">{ele.id}]</Typography>
                  <Typography variant="subtitle1">{ele.title}</Typography>
                </Box>
                <TextField
                  sx={{ width: { md: "35%", sm: "60%" }, marginRight: "15%" }}
                  id="standard-basic"
                  variant="standard"
                  name="character"
                  value={formData.character || ""}
                  onChange={handleFormChange}
                />
              </Box>
            ))}
          </Container>
        </Grid> */}

        <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <span className="certificate">CERTIFICATE</span> <br />
          <div className="certif-info">
            <div className="note-in-certificate">
              Certified that the entries made in this Transfer Certificate are
              taken from the entries made as per the general egister maintained
              in the institution. The entries with the original records
              persented by the parents
            </div>
          </div>
          <div className="tc-sign">
            <div>Prepared By</div>
            <div>Checked By</div>
            <div>Head Master</div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TransferCertificate;
