import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Grid,
  Tooltip,
  styled,
  useMediaQuery,
  Dialog,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddHoliday = (props) => {
  const [title, setTitle] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [note, setNote] = useState("");
  const [webView, setWebView] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if (props.updateHoliday) {
      setTitle(props.updateHoliday.title);
      setFromDate(dayjs(props.updateHoliday.fromDate).format("YYYY-MM-DD"));
      setToDate(dayjs(props.updateHoliday.toDate).format("YYYY-MM-DD"));
      setNote(props.updateHoliday.note);
      setWebView(props.updateHoliday.webView);
    }
  }, [props.updateHoliday]);

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const handleToDate = (e) => {
    setToDate(e.target.value);
  };
  const handleNote = (e) => {
    setNote(e.target.value);
  };
  const handleWebView = (e) => {
    setWebView(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.updateHoliday) {
      props.handleUpdate(
        {
          title,
          fromDate: dayjs(fromDate).toDate(),
          toDate: dayjs(toDate).toDate(),
          note,
          webView,
        },
        props.updateHoliday._id
      );
    } else {
      props.handleSubmit(e, {
        title,
        fromDate: dayjs(fromDate).toDate(),
        toDate: dayjs(toDate).toDate(),
        note,
        webView,
      });
    }
    setTitle("");
    setFromDate("");
    setToDate("");
    setNote("");
    setWebView("");

    props.handleClose();
  };

  const handleCloseModal = () => {
    setTitle("");
    setFromDate("");
    setToDate("");
    setNote("");
    setWebView("");
    props.handleClose();
  };
  const handleClose = () => {
    setTitle("");
    setFromDate("");
    setToDate("");
    setNote("");
    setWebView("");
    props.handleClose();
  };

  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Tooltip title="Add">
          <Add />
        </Tooltip>
      </Fab>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Holiday
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Holiday Title"
                  value={title}
                  onChange={handleTitle}
                  sx={{
                    // m: 1,
                    // width: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="From Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fromDate}
                  onChange={handleFromDate}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="To Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={toDate}
                  onChange={handleToDate}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 1, width: { md: 227, xs: "100%" } }}
                  required
                >
                  <InputLabel sx={{ fontSize: 12 }}>Is view on web?</InputLabel>
                  <Select
                    label="Is View On Web"
                    value={webView}
                    onChange={handleWebView}
                  >
                    <MenuItem
                      value={"yes"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      value={"no"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={note}
                  onChange={handleNote}
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    borderRadius: "5px",
                    padding: 10,
                    height: 70,
                    overflow: "auto",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", xs: "center" },
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      variant="contained"
                      size="medium"
                      type="button"
                      color="error"
                      sx={{ m: 1, color: "#fff" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={props.loader}
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default AddHoliday;
