import React from "react";
import Confetti from "react-confetti";

class CelebAnime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      showConfetti: true,
    };
  }

  // componentDidMount() {
  //   window.addEventListener('resize', this.handleResize);
  //   this.startTimer();
  // }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.clearTimer();
  }

  handleResize = () => {
    this.setState({
      // width: window.innerWidth,
      // height: window.innerHeight,
    });
  };

  // startTimer = () => {
  //   this.timer = setTimeout(() => {
  //     this.setState({ showConfetti: false });
  //   }, 7000);
  // };

  clearTimer = () => {
    clearTimeout(this.timer);
  };

  render() {
    const { width, height, showConfetti } = this.state;

    return showConfetti ? <Confetti width="500%" height="450%" /> : null;
  }
}

export default CelebAnime;
