import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
  Grid,
  Card,
  TextField,
  styled,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect, useContext } from "react";
import DeleteModal from "../Academic/DeleteModal";
import { urls as url } from "../../../services/urlConstant";
import { del, post, put, get } from "../../../services/apis";
import AddExamTerm from "./AddExamTerm";
import TablePagination from "@mui/material/TablePagination";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../../components/Wrapper";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";

const SearchBox = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down(900)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(685)]: {
    justifyContent: "flex-start",
  },
}));

const ExamTerm = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [schoolExamTerm, setSchoolExamTerm] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateExamTerm, setUpdateExamTerm] = useState(undefined);
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  let [loader, setLoader] = useState(false);

  const [sort, setSort] = useState({
    examTitle: "asc",
    examType: "asc",
  });

  const sortByExamTitle = () => {
    console.log("ExamTitle");
    if (search) {
      if (sort.examTitle === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.examTitle.localeCompare(a.examTitle)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examTitle: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.examTitle.localeCompare(b.examTitle)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examTitle: "asc" });
      }
    } else {
      if (sort.examTitle === "asc") {
        let sortedList = [
          ...schoolExamTerm.sort((a, b) =>
            b.examTitle.localeCompare(a.examTitle)
          ),
        ];
        setSchoolExamTerm(sortedList);
        setSort({ ...sort, examTitle: "des" });
      } else {
        let sortedList = [
          ...schoolExamTerm.sort((a, b) =>
            a.examTitle.localeCompare(b.examTitle)
          ),
        ];
        setSchoolExamTerm(sortedList);
        setSort({ ...sort, examTitle: "asc" });
      }
    }
  };

  const sortByExamType = () => {
    console.log("ExamType");
    if (search) {
      if (sort.examType === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.examType.localeCompare(a.examType)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examType: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.examType.localeCompare(b.examType)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examType: "asc" });
      }
    } else {
      if (sort.examType === "asc") {
        let sortedList = [
          ...schoolExamTerm.sort((a, b) =>
            b.examType.localeCompare(a.examType)
          ),
        ];
        setSchoolExamTerm(sortedList);
        setSort({ ...sort, examType: "des" });
      } else {
        let sortedList = [
          ...schoolExamTerm.sort((a, b) =>
            a.examType.localeCompare(b.examType)
          ),
        ];
        setSchoolExamTerm(sortedList);
        setSort({ ...sort, examType: "asc" });
      }
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateExamTerm(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(urls.examTerm.delExamTerm, id);
      if (res.data.success) {
        setSchoolExamTerm(
          schoolExamTerm.filter((item) => item._id !== res.data.data._id)
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    const getSchoolLength = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setTotalCount(res.data.data.length);
      } catch (error) {}
    };
    getSchoolLength();
  }, [selectedSetting]);

  const handleSubmit = async (e, data) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await post(urls.examTerm.postExamTerm, data);
      setSchoolExamTerm([...schoolExamTerm, res.data.data]);
    } catch (error) {}
    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const res = await put(urls.examTerm.putExamTerm, id, data);
      setSchoolExamTerm(
        schoolExamTerm.map((e) => {
          if (e._id === res.data.data._id) {
            return res.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {}
    setLoader(false);
  };

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getExamTermById = schoolExamTerm.find((item) => item._id === id);
      setUpdateExamTerm(getExamTermById);
    } catch (error) {}
  };

  useEffect(() => {
    const getSchoolExam = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        if (res && res.data && res.data.data) {
          setSchoolExamTerm([...res.data.data]);
        }
      } catch (error) {}
    };
    getSchoolExam();
  }, [selectedSetting]);

  const handleChangePage = async (event, newPage) => {
    try {
      const res = await get(urls.examTerm.getAllExamTerm, {
        params: {
          limit: 10,
          page: newPage + 1,
        },
      });

      setPage(newPage);
      setSchoolExamTerm([...res.data.data]);
    } catch (error) {}
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolExamTerm.filter((ele) =>
          ele.examTitle.toLowerCase().includes(value)
        )
      );
    } else {
      setSearchFilter([]);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Exam Term" />

      <div style={{ margin: "15px 0" }}>
        <div className={styles.searchInputContainer}>
          <SearchBox container spacing={2}>
            <Grid item xs="auto">
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search"
                variant="outlined"
                placeholder="Search By Exam Title"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </SearchBox>
        </div>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Exam Title</span>
                    <span
                      onClick={sortByExamTitle}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.examTitle === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Type</span>
                    <span
                      onClick={sortByExamType}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.examType === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>

                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examTitle}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : schoolExamTerm.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examTitle}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div className="add-icon">
          <AddExamTerm
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateExamTerm={updateExamTerm}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ExamTerm;
