import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "../../styles/HostelTable/HostelTable.scss";
import {
  Box,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Search } from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";

import Wrapper from "../Wrapper";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   height: 460,
//   bgcolor: "background.paper",
//   p: 4,
// };

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "650px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Hostel = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editHostel, setEditHostel] = useState();
  const [addForm, setAddForm] = useState({});
  const [hostel, setHostel] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [type, setType] = React.useState("");
  const [list, setList] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  useEffect(() => {
    getHostel();
  }, [selectedSetting]);
  useEffect(() => {
    const getList = async () => {
      try {
        const getEmps = await get(urls.hostel.getList);
        setList(getEmps.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getList();
  }, [selectedSetting]);

  useEffect(() => {
    const getWarden = async () => {
      try {
        const { data: role, status } = await get(`${urls.role.getAllRole}`, {
          params: {
            search: {
              roleName: "Warden",
            },
          },
        });
        if (status > 199 && status < 299) {
          const { data: employees, status } = await get(
            `${urls.employee.getAllEmployee}`,
            {
              params: {
                search: {
                  role: role.data[0]?._id,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            setEmployees(employees.data);
            console.log(employees.data, "warden");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getWarden();
  }, [selectedSetting]);

  const getHostel = async () => {
    try {
      const { data: hostels, status } = await get(`${urls.hostel.getList}`);

      setList(hostels.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.hostel.delHostel}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditHostel(null);
    setAddForm({});
    setOpen(false);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickOpenDialog = (hostel) => {
    setShowDetails(hostel);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
    setAddForm({});
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      if (!editHostel) {
        const { status } = await post(`${urls.hostel.create}`, {
          name: addForm.name,
          type: addForm.type,
          warden: addForm.warden,
          note: addForm.note,
          address: addForm.address,
          contactNumber: addForm.contactNumber,
        });
        getHostel();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.hostel.editHostel}${editHostel}`,
          "",
          {
            name: addForm.name,
            type: addForm.type,
            warden: addForm.warden,
            note: addForm.note,
            address: addForm.address,
            contactNumber: addForm.contactNumber,
          }
        );
        getHostel();
        handleClose();
        // if (status > 199 && status < 299) {
        //    getHostel();
        //   handleClose();
        // }
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      // marginTop: "25px",
      // width: "70%",
    },
    addbutton: {
      // paddingTop: "6.2px",
      // paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleClassMapEdit = (id, data) => {
    setEditHostel(id);
    setAddForm({
      name: data.name,
      type: data.type,
      warden: data.warden._id,
      note: data.note,
      address: data.address,
      contactNumber: data.contactNumber,
    });
    handleClickOpen();
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Hostel" />
      <div style={{ margin: "20px 0" }}>
        {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        > */}
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Grid item xs="auto" mb={2}>
            <TextField
              size="small"
              style={styles.textfield}
              value={search}
              onChange={handleSearch}
              label="Search by Hostel Name"
              placeholder="enter hostel name"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* <Grid item xs={12} md={2}>
                <Button
                  size="small"
                  variant="contained"
                  // sx={{ mt: 3.2 }}
                  style={styles.addbutton}
                  onClick={handleClickOpen}
                >
                  <AddIcon />
                  Add Hostel
                </Button>
              </Grid> */}
        </Grid>
        {/* </div> */}
        {/* </Paper> */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <ContainerBox>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                    onChange={handleAddForm}
                  >
                    {editHostel ? "Update Hostel" : "Add Hostel"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Hostel Name"
                    name="name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                    sx={{
                      // m: 1,
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    required
                  >
                    <TextField
                      fullWidth
                      required
                      id="filled-required"
                      size="small"
                      label="Phone No"
                      name="contactNumber"
                      onChange={handleAddForm}
                      value={addForm.contactNumber || ""}
                      sx={{
                        // minWidth: { md: 227, xs: "100%" },
                        label: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    // sx={{ m: 0.3, minWidth: { md: 227, xs: "100%" } }}
                  >
                    <InputLabel sx={{ fontSize: 8 }}>Hostel Type</InputLabel>
                    <Select
                      label="Hostel Type"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="type"
                      value={addForm.type || ""}
                      onChange={handleAddForm}
                    >
                      <MenuItem value={"Boys"}>Boys</MenuItem>
                      <MenuItem value={"Girls"}>Girls</MenuItem>
                      <MenuItem value={"Combined"}>Combined</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  >
                    <InputLabel sx={{ fontSize: 8 }}>Warden</InputLabel>
                    <Select
                      label="Warden"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="warden"
                      value={addForm.warden || ""}
                      onChange={handleAddForm}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee._id} value={employee._id}>
                          {employee.basicInfo.empName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Address"
                    name="address"
                    value={addForm.address || ""}
                    onChange={handleAddForm}
                    sx={{
                      // m: 1.7,
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    gap: "1%",
                    alignItems: "center",
                    justifyContent: { md: "right", sm: "right", xs: "center" },
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      size="medium"
                      type="button"
                      color="error"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                        // m: 1,
                      }}
                    >
                      {editHostel ? "update" : "Add"}
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </ContainerBox>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Hostel Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Hostel Type
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Address
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((hostel, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{hostel.name}</TableCell>
                    <TableCell align="center">{hostel.type}</TableCell>
                    <TableCell align="center">{hostel.address}</TableCell>
                    <TableCell align="center">
                      <div>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClassMapEdit(hostel._id, hostel)}
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                        <Button
                          onClick={() => handleClickOpenDialog(hostel._id)}
                          style={{ color: "#1b3779" }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(hostel._id, hostel)}
                        >
                          <Delete fontSize="small" />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={hostel._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((hostel, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{hostel.name}</TableCell>
                    <TableCell align="center">{hostel.type}</TableCell>
                    <TableCell align="center">{hostel.address}</TableCell>
                    <TableCell align="center">
                      <div>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClassMapEdit(hostel._id, hostel)}
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                        <Button
                          onClick={() => handleClickOpenDialog(hostel)}
                          style={{ color: "#1b3779" }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(hostel._id, hostel)}
                        >
                          <Delete fontSize="small" />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={hostel._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <Box className="add-icon">
          <Fab
            variant="contained"
            sx={{
              color: "#ffff",

              background: "rgb(27, 55, 121)",
              ":hover": { background: "rgb(27, 55, 121)" },
            }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </Box>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Hostel Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>Excellent English Medium Primary & High School</td>
                    </tr>
                    <tr>
                      <td>Hostel Name</td>
                      <td>{showDetails.name}</td>
                    </tr>
                    <tr>
                      <td>Hostel Type</td>
                      <td>{showDetails.type}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{showDetails.address}</td>
                    </tr>
                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>{showDetails.createdAt}</td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </Wrapper>
  );
};
export default Hostel;
