import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import { Box, styled } from '@mui/material'
import CustomCourse from './CustomCource'
import NavBar from '../navbar/Navbar'
import Footer from '../Footer/Footer'
import { get } from '../../services/apis'
import SettingContext from '../../context/SettingsContext'


const OverViewTitleContainer = styled(Box)(({ theme }) => ({
    color: "white",
    padding: "80px",
    // marrginTop:"20px",
    backgroundImage:
        "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
        padding: "15px",
    },
}));

const OverViewTitle = styled(Typography)(({ theme }) => ({
    textTransform: "uppercase",
    fontSize: "40px",
    [theme.breakpoints.down("md")]: {
        fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "25px",
    },
}));

const CourseDetails = () => {
    const { selectedSetting } = useContext(SettingContext);

    const [awards, setAwards] = useState([]);
    const { id } = useParams()
    // console.log(id)

    React.useEffect(() => {
    const getAllAwards = async () => {
        try {
            // console.log("Started")
          const getAwards = await get(
            `/${selectedSetting._id}/award/list/home-page`,
            {
              params: {
                search: {
                  webView: "yes",
                },
              },
            }
          );
        //   console.log(getAwards,"=====")
          if (getAwards.data.success) {
            // console.log(getAwards.data.result,"gjgjfcujf");
            setAwards(getAwards.data.result.data);
          }
        } catch (error) {
          console.log(error);
        }
      };
  
   
    //  console.log("Hiiiiii")
      getAllAwards();
    }, []);

 
    const data = awards.find((elem) => elem._id == id)
    // console.log(data, "lllll");
    
    return (
        <>
            <NavBar />
            <OverViewTitleContainer variant="h4" align="center" >
                <OverViewTitle>Awards And Achievements</OverViewTitle>
                <Typography sx={{ color: "white", textTransform: "uppercase" }}>
                    <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                        HOME
                    </Link>
                    &nbsp; / Awards And Achievements
                </Typography>
            </OverViewTitleContainer>
            {data ? <CustomCourse data={data} /> : null}
            <Footer />
        </>
    )
}

export default CourseDetails