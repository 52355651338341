// import { Delete, Edit } from "@mui/icons-material";
// import {
//   Button,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   styled,
//   Link,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Tabs,
//   TextareaAutosize,
//   TextField,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import { Box } from "@mui/system";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import React, { useContext, useState } from "react";
// import { useEffect } from "react";
// import { del, get, post, put } from "../../../services/apis";
// import { urls as url } from "../../../services/urlConstant";
// import DeleteModal from "../Academic/DeleteModal";

// import css from "../../../styles/Assignment.module.css";
// import { objHas } from "../../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import SettingContext from "../../../context/SettingsContext";
// import appendStringToUrls from "../../../utils/appendString";
// import SectionSubHeader from "../../SectionSubHeader";
// import Wrapper from "../../Wrapper";

// const FormContainer = styled(FormControl)(({ theme }) => ({
//   width: "300px",
//   [theme.breakpoints.down(400)]: {
//     width: "70%",
//   },
// }));

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }
// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }
// const Assignment = () => {
//   const { selectedSetting } = useContext(SettingContext);
//   const [tabValue, setTabValue] = React.useState(0);
//   const [formData, setFormData] = React.useState({});
//   const [sections, setSections] = React.useState([]);
//   const [searchSections, setSearchSections] = React.useState([]);
//   const [schoolClasses, setSchoolClasses] = React.useState([]);
//   const [subjects, setSubjects] = React.useState([]);
//   const [inputFile, setInputFile] = React.useState(null);
//   const [date, setDate] = React.useState(dayjs());
//   const [editDate, setEditDate] = React.useState(dayjs());
//   const [assignments, setAssignments] = React.useState([]);
//   const [editForm, setEditForm] = useState({});
//   const [showEdit, setShowEdit] = React.useState(false);
//   const [searchFormData, setSearchFormData] = useState({});
//   const [deleteModal, setDeleteModal] = React.useState(false);
//   const [loading, setLoading] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [load, setLoad] = useState(false);

//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

//   const [sort, setSort] = useState({
//     assignmentTitle: "asc",
//     createdAt: "asc",
//     deadline: "asc",
//     subjectName: "asc",
//   });

//   const sortByTitle = () => {
//     console.log("assignmentTitle");
//     if (sort.assignmentTitle === "asc") {
//       let sortedList = [
//         ...assignments.sort((a, b) =>
//           b.assignmentTitle.localeCompare(a.assignmentTitle)
//         ),
//       ];

//       setAssignments(sortedList);
//       setSort({ ...sort, assignmentTitle: "des" });
//     } else {
//       let sortedList = [
//         ...assignments.sort((a, b) =>
//           a.assignmentTitle.localeCompare(b.assignmentTitle)
//         ),
//       ];
//       setAssignments(sortedList);
//       setSort({ ...sort, assignmentTitle: "asc" });
//     }
//   };

//   const sortBySubject = () => {
//     console.log("Subject");
//     if (sort.subjectName === "asc") {
//       let sortedList = [
//         ...assignments.sort((a, b) =>
//           b.subject.subjectName.localeCompare(a.subject.subjectName)
//         ),
//       ];

//       setAssignments(sortedList);
//       setSort({ ...sort, subjectName: "des" });
//     } else {
//       let sortedList = [
//         ...assignments.sort((a, b) =>
//           a.subject.subjectName.localeCompare(b.subject.subjectName)
//         ),
//       ];
//       setAssignments(sortedList);
//       setSort({ ...sort, subjectName: "asc" });
//     }
//   };

//   const compareDatesAsc = (a, b) => {
//     let dateA = new Date(a.createdAt);
//     let dateB = new Date(b.createdAt);

//     return dateA - dateB;
//   };

//   const compareDatesDec = (a, b) => {
//     let dateA = new Date(a.createdAt);
//     let dateB = new Date(b.createdAt);

//     return dateB - dateA;
//   };

//   const compareDatesAscDate = (a, b) => {
//     let dateA = new Date(a.createdAt);
//     let dateB = new Date(b.createdAt);

//     return dateA - dateB;
//   };

//   const compareDatesDecDate = (a, b) => {
//     let dateA = new Date(a.createdAt);
//     let dateB = new Date(b.createdAt);

//     return dateB - dateA;
//   };

//   const sortByCreatedAt = () => {
//     console.log("date");
//     if (sort.createdAt === "asc") {
//       let sortedList = [...assignments.sort(compareDatesDec)];

//       setAssignments(sortedList);
//       setSort({ ...sort, createdAt: "des" });
//     } else {
//       let sortedList = [...assignments.sort(compareDatesAsc)];
//       setAssignments(sortedList);
//       setSort({ ...sort, createdAt: "asc" });
//     }
//   };

//   const sortByDeadline = () => {
//     console.log("deadline");
//     if (sort.deadline === "asc") {
//       let sortedList = [...assignments.sort(compareDatesDecDate)];

//       setAssignments(sortedList);
//       setSort({ ...sort, deadline: "des" });
//     } else {
//       let sortedList = [...assignments.sort(compareDatesAscDate)];
//       setAssignments(sortedList);
//       setSort({ ...sort, deadline: "asc" });
//     }
//   };

//   // useEffect
//   useEffect(() => {
//     setSchoolClasses([]);
//     const init = async () => {
//       try {
//         const schoolClass = await get(urls.class.getAllClass);
//         if (schoolClass.data.success) {
//           setSchoolClasses(schoolClass.data.data);
//         }
//       } catch (error) {}
//     };
//     init();
//   }, [selectedSetting]);

//   useEffect(() => {
//     setSections([]);
//     const getSections = async () => {
//       try {
//         if (searchFormData.class) {
//           const sections = await get(`${urls.schoolSection.getSection}`, {
//             params: {
//               search: {
//                 class: searchFormData.class,
//               },
//             },
//           });
//           if (sections.data.success) {
//             setSearchSections(sections.data.data);
//           }
//         }
//       } catch (error) {}
//     };
//     getSections();
//   }, [searchFormData.class, selectedSetting]);

//   useEffect(() => {
//     setSections([]);
//     setSubjects([]);
//     const classChange = async () => {
//       try {
//         const getSections = await get(urls.schoolSection.getAllSection, {
//           params: {
//             search: {
//               class: formData.class ? formData.class : editForm.class,
//             },
//           },
//         });
//         if (getSections.data.success) {
//           setSections(getSections.data.data);
//         }
//         const getSubjects = await get(urls.schoolSubject.getAllSubject, {
//           params: {
//             search: {
//               class: formData.class ? formData.class : editForm.class,
//             },
//           },
//         });
//         if (getSections.data.success) {
//           setSubjects(getSubjects.data.data);
//         }
//       } catch (error) {}
//     };
//     if (formData.class || editForm.class) {
//       classChange();
//     }
//   }, [formData.class, editForm.class, selectedSetting]);

//   useEffect(() => {
//     if (tabValue === 1) {
//       setShowEdit(false);
//       setEditForm({});
//       setInputFile(null);
//     }
//     if (tabValue === 0) {
//       setShowEdit(false);
//       setEditForm({});
//       setFormData({});
//       setInputFile(null);
//     }
//   }, [tabValue, selectedSetting]);

//   const handleEdit = (id) => {
//     const editAssignment = assignments.find(
//       (assignment) => assignment._id === id
//     );
//     console.log(editAssignment, "::::");
//     setEditForm({
//       ...editAssignment,
//       class: editAssignment.class._id,
//       section: editAssignment.section,
//       subject: editAssignment.subject._id,
//     });
//     setEditDate(dayjs(editAssignment.deadline));
//     setShowEdit(true);
//     setTabValue(2);
//     setFormData({});
//     setInputFile(null);
//   };
//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };
//   const handleFormChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };
//   const handleEditFormChange = (e) => {
//     const { name, value } = e.target;
//     setEditForm((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoader(true);
//     setLoad(true);
//     try {
//       let file = null;

//       if (inputFile) {
//         const fileForm = new FormData();
//         fileForm.append("files", inputFile);
//         const uploadFile = await post(urls.fileUpload.postFileUpload, fileForm);
//         if (uploadFile.data.result) {
//           file = uploadFile.data.result[0]._id;
//         }
//       }
//       if (showEdit) {
//         const data = {
//           ...editForm,
//           attachmentType: editForm.fileType,
//           deadline: dayjs(editDate.format("YYYY-MM-DD")),
//         };
//         if (file) {
//           data.file = file;
//         }
//         let { status } = await put(
//           `${urls.assignment.updateAssignment}/${editForm._id}`,
//           "",
//           data
//         );
//         if (status === 200) {
//           const assignments = await get(`${urls.assignment.getAssignment}`);
//           if (assignments.data.success) {
//             setAssignments(assignments.data.data);
//           }

//           setFormData({});
//           setInputFile(null);
//           setTabValue(0);
//         }
//       } else {
//         const data = {
//           ...formData,
//           attachmentType: formData.fileType,
//           deadline: dayjs(date.format("YYYY-MM-DD")),
//         };
//         if (file) {
//           data.file = file;
//         }
//         let { status } = await post(
//           `${urls.assignment.createAssignment}`,
//           data
//         );
//         if (status === 200) {
//           const assignments = await get(`${urls.assignment.getAssignment}`);
//           if (assignments.data.success) {
//             setAssignments(assignments.data.data);
//           }

//           setFormData({});
//           setInputFile(null);
//           setTabValue(0);
//         }
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     setLoader(false);
//     setLoad(false);
//   };
//   const handleAddCancel = () => {
//     setFormData({});
//     setTabValue(0);
//     setInputFile(null);
//   };
//   const handleEditCancel = () => {
//     setEditForm({});
//     setTabValue(0);
//     setInputFile(null);
//   };
//   const handleDelete = async (id) => {
//     try {
//       const delAssignment = await del(
//         `${urls.assignment.deleteAssignment}/${id}`,
//         ""
//       );
//       if (delAssignment.data.success) {
//         setAssignments((prevState) =>
//           prevState.filter(
//             (assignment) => assignment._id !== delAssignment.data.data._id
//           )
//         );
//       }
//     } catch (error) {}
//   };
//   const handleCopyLink = (link) => {
//     navigator.clipboard
//       .writeText(link)
//       .then(() => {
//         alert("Link copied to clipboard!", link);
//       })
//       .catch((error) => {
//         console.error("Failed to copy link:", error);
//       });
//   };
//   const handleSearchChange = (e) => {
//     const { name, value } = e.target;
//     setSearchFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   useEffect(() => {
//     setAssignments([]);
//   }, [selectedSetting]);

//   const handleSearchSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       if (searchFormData.section === "all") {
//         const assignment = await get(`${urls.assignment.getAssignment}`, {
//           params: {
//             search: {
//               class: searchFormData.class,
//             },
//             limit: 10,
//             page: 1,
//           },
//         });
//         if (assignment.data.success) {
//           setAssignments(assignment.data.data);
//         }
//       } else {
//         const assignment = await get(`${urls.assignment.getAssignment}`, {
//           params: {
//             search: {
//               class: searchFormData.class,
//               section: searchFormData.section,
//             },
//             limit: 10,
//             page: 1,
//           },
//         });
//         if (assignment.data.success) {
//           setAssignments(assignment.data.data);
//         }
//       }
//     } catch (error) {}
//     setLoading(false);
//   };
//   return (
//     <Wrapper>
//       <SectionSubHeader title="Assignment" />
//       <Box sx={{ marginTop: 3 }}>
//         <Paper>
//           <Tabs
//             value={tabValue}
//             onChange={handleTabChange}
//             aria-label="basic tabs example"
//           >
//             <Tab label="Assignment" {...a11yProps(0)} />
//             <Tab label="Add Assignment" {...a11yProps(1)} />
//             {showEdit && <Tab label="Edit Assignment" {...a11yProps(2)} />}
//           </Tabs>
//         </Paper>
//       </Box>
//       <TabPanel value={tabValue} index={0}>
//         <div>
//           <Paper sx={{ marginTop: 2 }}>
//             <form
//               onSubmit={handleSearchSubmit}
//               style={{
//                 padding: "1rem",
//                 borderBottom: "1px solid #E0E0E0",
//                 display: "flex",
//                 flexWrap: "wrap",
//                 gap: "1rem",
//               }}
//             >
//               <FormContainer>
//                 <InputLabel>Class</InputLabel>
//                 <Select
//                   value={searchFormData.class}
//                   onChange={handleSearchChange}
//                   label="Class"
//                   name="class"
//                   size="small"
//                 >
//                   {schoolClasses.map((schClass) => (
//                     <MenuItem key={schClass._id} value={schClass._id}>
//                       {schClass.className}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormContainer>

//               <FormContainer>
//                 <InputLabel>Sections</InputLabel>
//                 <Select
//                   name="section"
//                   value={searchFormData.section}
//                   onChange={handleSearchChange}
//                   label="Section"
//                   size="small"
//                 >
//                   {searchFormData.class && (
//                     <MenuItem value="all">-all-</MenuItem>
//                   )}
//                   {searchSections.map((section) => (
//                     <MenuItem key={section._id} value={section._id}>
//                       {section.sectionName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormContainer>
//               <LoadingButton
//                 loading={loading}
//                 size="small"
//                 variant="contained"
//                 type="submit"
//               >
//                 SEARCH
//               </LoadingButton>
//             </form>
//             <TableContainer>
//               <Table>
//                 <TableHead
//                   style={{ background: "rgb(27 55 121)", color: "white" }}
//                 >
//                   <TableRow style={{ color: "#ffff" }}>
//                     <TableCell align="center" style={{ color: "#fff" }}>
//                       #SL
//                     </TableCell>
//                     <TableCell align="center" style={{ color: "#fff" }}>
//                       <span>Assignment Title</span>
//                       <span
//                         onClick={sortByTitle}
//                         style={{ cursor: "pointer", color: "#fff" }}
//                       >
//                         {sort.assignmentTitle === "asc" ? "▲" : "▼"}
//                       </span>
//                     </TableCell>
//                     <TableCell align="center" style={{ color: "#ffff" }}>
//                       Class
//                     </TableCell>
//                     <TableCell align="center" style={{ color: "#ffff" }}>
//                       Subject
//                       <span
//                         onClick={sortBySubject}
//                         style={{ cursor: "pointer", color: "#fff" }}
//                       >
//                         {sort.subjectName === "asc" ? "▲" : "▼"}
//                       </span>
//                     </TableCell>
//                     <TableCell align="center" style={{ color: "#ffff" }}>
//                       <span> Created At</span>
//                       <span
//                         onClick={sortByCreatedAt}
//                         style={{ cursor: "pointer", color: "#fff" }}
//                       >
//                         {sort.createdAt === "asc" ? "▲" : "▼"}
//                       </span>
//                     </TableCell>
//                     <TableCell align="center" style={{ color: "#ffff" }}>
//                       <span>Deadline</span>
//                       <span
//                         onClick={sortByDeadline}
//                         style={{ cursor: "pointer", color: "#fff" }}
//                       >
//                         {sort.deadline === "asc" ? "▲" : "▼"}
//                       </span>
//                     </TableCell>
//                     <TableCell align="center" style={{ color: "#ffff" }}>
//                       Actions
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {assignments.map((assignment, index) => (
//                     <TableRow key={assignment._id}>
//                       <TableCell align="center">{index + 1}</TableCell>
//                       <TableCell align="center">
//                         {assignment.assignmentTitle}
//                       </TableCell>
//                       <TableCell align="center">
//                         {objHas(assignment, "class.className", "")}
//                       </TableCell>
//                       <TableCell align="center">
//                         {objHas(assignment, "subject.subjectName", "")}
//                       </TableCell>
//                       <TableCell align="center">
//                         {dayjs(assignment.createdAt).format("DD-MM-YYYY")}
//                       </TableCell>
//                       <TableCell align="center">
//                         {dayjs(assignment.deadline).format("DD-MM-YYYY")}
//                       </TableCell>
//                       <TableCell align="center">
//                         <div
//                           style={{
//                             display: "flex",
//                             justifyContent: "space-between",
//                             alignItems: "center",
//                           }}
//                         >
//                           <div className="edit-delete">
//                             <Tooltip title="Edit" disableInteractive>
//                               <Button
//                                 onClick={() => handleEdit(assignment._id)}
//                               >
//                                 <Edit />
//                               </Button>
//                             </Tooltip>
//                             <Tooltip title="Delete" disableInteractive>
//                               <Button
//                                 onClick={() => setDeleteModal(assignment._id)}
//                               >
//                                 <Delete />
//                               </Button>
//                             </Tooltip>
//                           </div>
//                           <DeleteModal
//                             deleteModal={deleteModal}
//                             handleDelete={handleDelete}
//                             id={assignment._id}
//                             setDeleteModal={setDeleteModal}
//                           />
//                           {assignment.file && (
//                             <>
//                               <a
//                                 href={assignment.file.link}
//                                 download
//                                 target="__window"
//                               >
//                                 <Button
//                                   size="small"
//                                   color="success"
//                                   variant="contained"
//                                 >
//                                   Download
//                                 </Button>
//                               </a>
//                               <a href={assignment.file.link} target="_blank">
//                                 <Button
//                                   size="small"
//                                   color="success"
//                                   variant="contained"
//                                   onClick={(event) => {
//                                     event.preventDefault();
//                                     handleCopyLink(assignment.file.link);
//                                   }}
//                                 >
//                                   Copy Link
//                                 </Button>
//                               </a>
//                             </>
//                           )}
//                           {assignment.link && (
//                             <a
//                               href={assignment.link}
//                               rel="noreferrer"
//                               target="_blank"
//                             >
//                               <Button
//                                 size="small"
//                                 color="success"
//                                 variant="contained"
//                               >
//                                 Link
//                               </Button>
//                             </a>
//                           )}
//                         </div>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//               {assignments.length === 0 && (
//                 <h5 style={{ textAlign: "center" }}>No data</h5>
//               )}
//             </TableContainer>
//           </Paper>
//         </div>
//       </TabPanel>
//       <TabPanel value={tabValue} index={1}>
//         <div>
//           <Paper sx={{ marginTop: 2 }}>
//             <div className={css.paperContainer}>
//               <div className={css.formContainer}>
//                 <form onSubmit={handleSubmit} className={css.form}>
//                   <div className={css.formHolder}>
//                     <TextField
//                       name="assignmentTitle"
//                       label="Assignment Title"
//                       required
//                       fullWidth
//                       value={formData.assignmentTitle || ""}
//                       size="small"
//                       onChange={handleFormChange}
//                     />
//                   </div>
//                   <div className={css.formHolder}>
//                     <FormControl fullWidth size="small">
//                       <InputLabel>Class</InputLabel>
//                       <Select
//                         name="class"
//                         value={formData.class || ""}
//                         label="Class"
//                         onChange={handleFormChange}
//                       >
//                         {schoolClasses.map((schoolClass) => (
//                           <MenuItem
//                             key={schoolClass._id}
//                             value={schoolClass._id}
//                           >
//                             {schoolClass.className}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </div>
//                   <div className={css.formHolder}>
//                     <FormControl fullWidth size="small">
//                       <InputLabel>Sections</InputLabel>
//                       <Select
//                         name="section"
//                         value={formData.section || ""}
//                         label="Sections"
//                         onChange={handleFormChange}
//                       >
//                         {formData.class && (
//                           <MenuItem value="all">-all-</MenuItem>
//                         )}
//                         {sections.map((section) => (
//                           <MenuItem key={section._id} value={section._id}>
//                             {section.sectionName}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </div>
//                   <div className={css.formHolder}>
//                     <FormControl fullWidth size="small">
//                       <InputLabel>Subject</InputLabel>
//                       <Select
//                         name="subject"
//                         value={formData.subject || ""}
//                         label="Subject"
//                         onChange={handleFormChange}
//                       >
//                         {subjects.map((subject) => (
//                           <MenuItem key={subject._id} value={subject._id}>
//                             {subject.subjectName}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </div>
//                   <div className={css.formHolder}>
//                     <FormControl fullWidth size="small">
//                       <InputLabel>Type</InputLabel>
//                       <Select
//                         name="assignmentType"
//                         value={formData.assignmentType || ""}
//                         label="Type"
//                         onChange={handleFormChange}
//                       >
//                         <MenuItem value={"class"}>Class</MenuItem>
//                         <MenuItem value={"assignment"}>Assignment</MenuItem>
//                       </Select>
//                     </FormControl>
//                   </div>
//                   <div className={css.formHolder}>
//                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                       <DatePicker
//                         label="Deadline"
//                         openTo="day"
//                         views={["year", "month", "day"]}
//                         inputFormat="DD-MM-YYYY"
//                         value={date}
//                         onChange={(newValue) => {
//                           setDate(newValue);
//                         }}
//                         renderInput={(params) => (
//                           <TextField fullWidth size="small" {...params} />
//                         )}
//                       />
//                     </LocalizationProvider>
//                   </div>
//                   <div className={css.formHolder}>
//                     <FormControl fullWidth size="small">
//                       <InputLabel>File type</InputLabel>
//                       <Select
//                         name="fileType"
//                         value={formData.fileType || ""}
//                         label="File Type"
//                         onChange={handleFormChange}
//                       >
//                         <MenuItem value={"File"}>File</MenuItem>
//                         <MenuItem value={"Link"}>Link</MenuItem>
//                       </Select>
//                     </FormControl>
//                   </div>
//                   {formData.fileType === "File" && (
//                     <div className={css.formHolder}>
//                       <input
//                         type="file"
//                         onChange={(e) => setInputFile(e.target.files[0])}
//                       />
//                     </div>
//                   )}
//                   {formData.fileType === "Link" && (
//                     <div className={css.formHolder}>
//                       <TextField
//                         size="small"
//                         name="link"
//                         label="Link"
//                         required
//                         fullWidth
//                         value={formData.link || ""}
//                         variant="outlined"
//                         onChange={handleFormChange}
//                       />
//                     </div>
//                   )}
//                   <div className={css.formHolder}>
//                     <TextareaAutosize
//                       name="note"
//                       placeholder="Note"
//                       fullWidth
//                       style={{
//                         width: "100%",
//                         borderRadius: "5px",
//                         //  width: "80%",
//                         maxHeight: 110,
//                         //  margin: 8,
//                         padding: 10,
//                         height: 70,
//                         overflow: "auto",
//                       }}
//                       value={formData.note || ""}
//                       variant="outlined"
//                       onChange={handleFormChange}
//                     />
//                   </div>
//                   <div className={css.formHolder}>
//                     <FormControl fullWidth size="small">
//                       <InputLabel>Is publish to web</InputLabel>
//                       <Select
//                         name="publishToWeb"
//                         value={formData.publishToWeb || ""}
//                         label="Is publish to web"
//                         onChange={handleFormChange}
//                       >
//                         <MenuItem value={"yes"}>Yes</MenuItem>
//                         <MenuItem value={"no"}>No</MenuItem>
//                       </Select>
//                     </FormControl>
//                   </div>
//                   <div className={css.formBtn}>
//                     <div>
//                       <Button
//                         onClick={handleAddCancel}
//                         variant="contained"
//                         color="error"
//                         sx={{ color: "#fff" }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                     <div>
//                       <LoadingButton
//                         loading={loader}
//                         type="submit"
//                         style={{ background: "#1b3779", color: "#fff" }}
//                       >
//                         Submit
//                       </LoadingButton>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </Paper>
//         </div>
//       </TabPanel>
//       {showEdit && (
//         <TabPanel value={tabValue} index={2}>
//           <div className={css.container}>
//             <Paper>
//               <div className={css.paperContainer}>
//                 <div className={css.formContainer}>
//                   <form onSubmit={handleSubmit} className={css.form}>
//                     <div className={css.formHolder}>
//                       <TextField
//                         size="small"
//                         name="assignmentTitle"
//                         label="Assignment Title"
//                         required
//                         fullWidth
//                         value={editForm.assignmentTitle || ""}
//                         variant="outlined"
//                         onChange={handleEditFormChange}
//                       />
//                     </div>
//                     <div className={css.formHolder}>
//                       <FormControl fullWidth size="small">
//                         <InputLabel>Class</InputLabel>
//                         <Select
//                           name="class"
//                           value={editForm.class || ""}
//                           label="Class"
//                           onChange={handleEditFormChange}
//                         >
//                           {schoolClasses.map((schoolClass) => (
//                             <MenuItem
//                               key={schoolClass._id}
//                               value={schoolClass._id}
//                             >
//                               {schoolClass.className}
//                             </MenuItem>
//                           ))}
//                         </Select>
//                       </FormControl>
//                     </div>
//                     <div className={css.formHolder}>
//                       <FormControl fullWidth size="small">
//                         <InputLabel>Sections</InputLabel>
//                         <Select
//                           name="section"
//                           value={editForm.section || "All"}
//                           label="Sections"
//                           onChange={handleEditFormChange}
//                         >
//                           {/* {editForm.class && (
//                             <MenuItem value="all">-all-</MenuItem>
//                           )} */}
//                           {[
//                             { _id: "All", sectionName: "All" },
//                             ...sections,
//                           ].map((section) => (
//                             <MenuItem key={section._id} value={section._id}>
//                               {section.sectionName}
//                             </MenuItem>
//                           ))}
//                         </Select>
//                       </FormControl>
//                     </div>
//                     <div className={css.formHolder}>
//                       <FormControl fullWidth size="small">
//                         <InputLabel>Subject</InputLabel>
//                         <Select
//                           name="subject"
//                           value={editForm.subject || ""}
//                           label="Subject"
//                           onChange={handleEditFormChange}
//                         >
//                           {subjects.map((subject) => (
//                             <MenuItem key={subject._id} value={subject._id}>
//                               {subject.subjectName}
//                             </MenuItem>
//                           ))}
//                         </Select>
//                       </FormControl>
//                     </div>
//                     <div className={css.formHolder}>
//                       <FormControl fullWidth size="small">
//                         <InputLabel>Type</InputLabel>
//                         <Select
//                           name="assignmentType"
//                           value={editForm.assignmentType || ""}
//                           label="Type"
//                           onChange={handleEditFormChange}
//                         >
//                           <MenuItem value={"class"}>Class</MenuItem>
//                           <MenuItem value={"assignment"}>Assignment</MenuItem>
//                         </Select>
//                       </FormControl>
//                     </div>
//                     <div className={css.formHolder}>
//                       <LocalizationProvider dateAdapter={AdapterDayjs}>
//                         <DatePicker
//                           label="Deadline"
//                           inputFormat="DD-MM-YYYY"
//                           openTo="day"
//                           views={["year", "month", "day"]}
//                           value={editDate}
//                           onChange={(newValue) => {
//                             setEditDate(newValue);
//                           }}
//                           renderInput={(params) => (
//                             <TextField
//                               fullWidth
//                               variant="outlined"
//                               {...params}
//                               size="small"
//                             />
//                           )}
//                         />
//                       </LocalizationProvider>
//                     </div>
//                     {editForm.file && (
//                       <div className={css.formHolder}>
//                         <input
//                           type="file"
//                           onChange={(e) => setInputFile(e.target.files[0])}
//                         />
//                         {editForm.file ? editForm.file.fileName : "NA"}
//                         <Link
//                           variant="inherit"
//                           target="_blank"
//                           href={editForm.file.link}
//                         >
//                           Download File
//                         </Link>
//                       </div>
//                     )}
//                     {editForm.link && (
//                       <div className={css.formHolder}>
//                         <TextField
//                           size="small"
//                           name="link"
//                           label="Link"
//                           required
//                           fullWidth
//                           value={editForm.link || ""}
//                           variant="outlined"
//                           onChange={handleEditFormChange}
//                         />
//                       </div>
//                     )}
//                     <div className={css.formHolder}>
//                       <TextareaAutosize
//                         name="note"
//                         placeholder="Note"
//                         fullWidth
//                         style={{ width: "100%" }}
//                         value={editForm.note || ""}
//                         variant="outlined"
//                         onChange={handleEditFormChange}
//                       />
//                     </div>
//                     <div className={css.formHolder}>
//                       <FormControl fullWidth size="small">
//                         <InputLabel>Is publish to web</InputLabel>
//                         <Select
//                           name="publishToWeb"
//                           value={editForm.publishToWeb || ""}
//                           label="Is publish to web"
//                           onChange={handleEditFormChange}
//                         >
//                           <MenuItem value={"yes"}>Yes</MenuItem>
//                           <MenuItem value={"no"}>No</MenuItem>
//                         </Select>
//                       </FormControl>
//                     </div>
//                     <div className={css.formBtn}>
//                       <div>
//                         <Button
//                           onClick={handleEditCancel}
//                           variant="contained"
//                           color="error"
//                           sx={{ color: "#fff" }}
//                         >
//                           Cancel
//                         </Button>
//                       </div>
//                       <div>
//                         <LoadingButton
//                           loading={load}
//                           type="submit"
//                           variant="contained"
//                         >
//                           Submit
//                         </LoadingButton>
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </Paper>
//           </div>
//         </TabPanel>
//       )}
//     </Wrapper>
//   );
// };
// export default Assignment;

import { Delete, Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../Academic/DeleteModal";

import css from "../../../styles/Assignment.module.css";
import { objHas } from "../../../utils/utils";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";

const FormContainer = styled(FormControl)(({ theme }) => ({
  width: "300px",
  [theme.breakpoints.down(400)]: {
    width: "70%",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Assignment = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [tabValue, setTabValue] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [sections, setSections] = React.useState([]);
  const [searchSections, setSearchSections] = React.useState([]);
  const [schoolClasses, setSchoolClasses] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);
  const [inputFile, setInputFile] = React.useState(null);
  const [date, setDate] = React.useState(dayjs());
  const [editDate, setEditDate] = React.useState(dayjs());
  const [assignments, setAssignments] = React.useState([]);
  const [editForm, setEditForm] = useState({});
  const [showEdit, setShowEdit] = React.useState(false);
  const [searchFormData, setSearchFormData] = useState({});
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [sort, setSort] = useState({
    assignmentTitle: "asc",
    createdAt: "asc",
    deadline: "asc",
    subjectName: "asc",
  });

  const sortByTitle = () => {
    console.log("assignmentTitle");
    if (sort.assignmentTitle === "asc") {
      let sortedList = [
        ...assignments.sort((a, b) =>
          b.assignmentTitle.localeCompare(a.assignmentTitle)
        ),
      ];

      setAssignments(sortedList);
      setSort({ ...sort, assignmentTitle: "des" });
    } else {
      let sortedList = [
        ...assignments.sort((a, b) =>
          a.assignmentTitle.localeCompare(b.assignmentTitle)
        ),
      ];
      setAssignments(sortedList);
      setSort({ ...sort, assignmentTitle: "asc" });
    }
  };

  const sortBySubject = () => {
    console.log("Subject");
    if (sort.subjectName === "asc") {
      let sortedList = [
        ...assignments.sort((a, b) =>
          b.subject.subjectName.localeCompare(a.subject.subjectName)
        ),
      ];

      setAssignments(sortedList);
      setSort({ ...sort, subjectName: "des" });
    } else {
      let sortedList = [
        ...assignments.sort((a, b) =>
          a.subject.subjectName.localeCompare(b.subject.subjectName)
        ),
      ];
      setAssignments(sortedList);
      setSort({ ...sort, subjectName: "asc" });
    }
  };

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.createdAt);
    let dateB = new Date(b.createdAt);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.createdAt);
    let dateB = new Date(b.createdAt);

    return dateB - dateA;
  };

  const compareDatesAscDate = (a, b) => {
    let dateA = new Date(a.createdAt);
    let dateB = new Date(b.createdAt);

    return dateA - dateB;
  };

  const compareDatesDecDate = (a, b) => {
    let dateA = new Date(a.createdAt);
    let dateB = new Date(b.createdAt);

    return dateB - dateA;
  };

  const sortByCreatedAt = () => {
    console.log("date");
    if (sort.createdAt === "asc") {
      let sortedList = [...assignments.sort(compareDatesDec)];

      setAssignments(sortedList);
      setSort({ ...sort, createdAt: "des" });
    } else {
      let sortedList = [...assignments.sort(compareDatesAsc)];
      setAssignments(sortedList);
      setSort({ ...sort, createdAt: "asc" });
    }
  };

  const sortByDeadline = () => {
    console.log("deadline");
    if (sort.deadline === "asc") {
      let sortedList = [...assignments.sort(compareDatesDecDate)];

      setAssignments(sortedList);
      setSort({ ...sort, deadline: "des" });
    } else {
      let sortedList = [...assignments.sort(compareDatesAscDate)];
      setAssignments(sortedList);
      setSort({ ...sort, deadline: "asc" });
    }
  };

  // useEffect
  useEffect(() => {
    setSchoolClasses([]);
    const init = async () => {
      try {
        const schoolClass = await get(urls.class.getAllClass);
        if (schoolClass.data.success) {
          setSchoolClasses(schoolClass.data.data);
        }
      } catch (error) {}
    };
    init();
  }, [selectedSetting]);

  useEffect(() => {
    setSections([]);
    const getSections = async () => {
      try {
        if (searchFormData.class) {
          const sections = await get(`${urls.schoolSection.getSection}`, {
            params: {
              search: {
                class: searchFormData.class,
              },
            },
          });
          if (sections.data.success) {
            setSearchSections(sections.data.data);
          }
        }
      } catch (error) {}
    };
    getSections();
  }, [searchFormData.class, selectedSetting]);

  useEffect(() => {
    setSections([]);
    setSubjects([]);
    const classChange = async () => {
      try {
        const getSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: formData.class ? formData.class : editForm.class,
            },
          },
        });
        if (getSections.data.success) {
          setSections(getSections.data.data);
        }
        const getSubjects = await get(urls.schoolSubject.getAllSubject, {
          params: {
            search: {
              class: formData.class ? formData.class : editForm.class,
            },
          },
        });
        if (getSections.data.success) {
          setSubjects(getSubjects.data.data);
        }
      } catch (error) {}
    };
    if (formData.class || editForm.class) {
      classChange();
    }
  }, [formData.class, editForm.class, selectedSetting]);

  useEffect(() => {
    if (tabValue === 1) {
      setShowEdit(false);
      setEditForm({});
      setInputFile(null);
    }
    if (tabValue === 0) {
      setShowEdit(false);
      setEditForm({});
      setFormData({});
      setInputFile(null);
    }
  }, [tabValue, selectedSetting]);

  const handleEdit = (id) => {
    const editAssignment = assignments.find(
      (assignment) => assignment._id === id
    );
    console.log(editAssignment, "::::");
    setEditForm({
      ...editAssignment,
      class: editAssignment.class._id,
      section: editAssignment.section,
      subject: editAssignment.subject._id,
    });
    setEditDate(dayjs(editAssignment.deadline));
    setShowEdit(true);
    setTabValue(2);
    setFormData({});
    setInputFile(null);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setLoad(true);
    try {
      let file = null;

      if (inputFile) {
        const fileForm = new FormData();
        fileForm.append("files", inputFile);
        const uploadFile = await post(urls.fileUpload.postFileUpload, fileForm);
        if (uploadFile.data.result) {
          file = uploadFile.data.result[0]._id;
        }
      }
      if (showEdit) {
        const data = {
          ...editForm,
          attachmentType: editForm.fileType,
          deadline: dayjs(editDate.format("YYYY-MM-DD")),
        };
        if (file) {
          data.file = file;
        }
        let { status } = await put(
          `${urls.assignment.updateAssignment}/${editForm._id}`,
          "",
          data
        );
        if (status === 200) {
          const assignments = await get(`${urls.assignment.getAssignment}`);
          if (assignments.data.success) {
            setAssignments(assignments.data.data);
          }

          setFormData({});
          setInputFile(null);
          setTabValue(0);
        }
      } else {
        const data = {
          ...formData,
          attachmentType: formData.fileType,
          deadline: dayjs(date.format("YYYY-MM-DD")),
        };
        if (file) {
          data.file = file;
        }
        let { status } = await post(
          `${urls.assignment.createAssignment}`,
          data
        );
        if (status === 200) {
          const assignments = await get(`${urls.assignment.getAssignment}`);
          if (assignments.data.success) {
            setAssignments(assignments.data.data);
          }

          setFormData({});
          setInputFile(null);
          setTabValue(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
    setLoad(false);
  };
  const handleAddCancel = () => {
    setFormData({});
    setTabValue(0);
    setInputFile(null);
  };
  const handleEditCancel = () => {
    setEditForm({});
    setTabValue(0);
    setInputFile(null);
  };
  const handleDelete = async (id) => {
    try {
      const delAssignment = await del(
        `${urls.assignment.deleteAssignment}/${id}`,
        ""
      );
      if (delAssignment.data.success) {
        setAssignments((prevState) =>
          prevState.filter(
            (assignment) => assignment._id !== delAssignment.data.data._id
          )
        );
      }
    } catch (error) {}
  };
  const handleCopyLink = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!", link);
      })
      .catch((error) => {
        console.error("Failed to copy link:", error);
      });
  };
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setAssignments([]);
  }, [selectedSetting]);

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (searchFormData.section === "all") {
        const assignment = await get(`${urls.assignment.getAssignment}`, {
          params: {
            search: {
              class: searchFormData.class,
            },
            limit: 10,
            page: 1,
          },
        });
        if (assignment.data.success) {
          setAssignments(assignment.data.data);
        }
      } else {
        const assignment = await get(`${urls.assignment.getAssignment}`, {
          params: {
            search: {
              class: searchFormData.class,
              section: searchFormData.section,
            },
            limit: 10,
            page: 1,
          },
        });
        if (assignment.data.success) {
          setAssignments(assignment.data.data);
        }
      }
    } catch (error) {}
    setLoading(false);
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Assignment" />
      <Box sx={{ marginTop: 3 }}>
        <Paper>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Assignment" {...a11yProps(0)} />
            <Tab label="Add Assignment" {...a11yProps(1)} />
            {showEdit && <Tab label="Edit Assignment" {...a11yProps(2)} />}
          </Tabs>
        </Paper>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <div>
          <Paper sx={{ marginTop: 2 }}>
            <form
              onSubmit={handleSearchSubmit}
              style={{
                padding: "1rem",
                borderBottom: "1px solid #E0E0E0",
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Class</InputLabel>
                    <Select
                      value={searchFormData.class}
                      onChange={handleSearchChange}
                      label="Class"
                      name="class"
                      size="small"
                    >
                      {schoolClasses.map((schClass) => (
                        <MenuItem key={schClass._id} value={schClass._id}>
                          {schClass.className}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Sections</InputLabel>
                    <Select
                      name="section"
                      value={searchFormData.section}
                      onChange={handleSearchChange}
                      label="Section"
                      size="small"
                    >
                      {searchFormData.class && (
                        <MenuItem value="all">-all-</MenuItem>
                      )}
                      {searchSections.map((section) => (
                        <MenuItem key={section._id} value={section._id}>
                          {section.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                      lg: "flex-start",
                    },
                  }}
                >
                  <LoadingButton
                    loading={loading}
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    SEARCH
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
            <TableContainer>
              <Table>
                <TableHead
                  style={{ background: "rgb(27 55 121)", color: "white" }}
                >
                  <TableRow style={{ color: "#ffff" }}>
                    <TableCell align="center" style={{ color: "#fff" }}>
                      #SL
                    </TableCell>
                    <TableCell align="center" style={{ color: "#fff" }}>
                      <span>Assignment Title</span>
                      <span
                        onClick={sortByTitle}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.assignmentTitle === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center" style={{ color: "#ffff" }}>
                      Class
                    </TableCell>
                    <TableCell align="center" style={{ color: "#ffff" }}>
                      Subject
                      <span
                        onClick={sortBySubject}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.subjectName === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center" style={{ color: "#ffff" }}>
                      <span> Created At</span>
                      <span
                        onClick={sortByCreatedAt}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.createdAt === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center" style={{ color: "#ffff" }}>
                      <span>Deadline</span>
                      <span
                        onClick={sortByDeadline}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.deadline === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center" style={{ color: "#ffff" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignments.map((assignment, index) => (
                    <TableRow key={assignment._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {assignment.assignmentTitle}
                      </TableCell>
                      <TableCell align="center">
                        {objHas(assignment, "class.className", "")}
                      </TableCell>
                      <TableCell align="center">
                        {objHas(assignment, "subject.subjectName", "")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(assignment.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(assignment.deadline).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button
                                onClick={() => handleEdit(assignment._id)}
                              >
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button
                                onClick={() => setDeleteModal(assignment._id)}
                              >
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={assignment._id}
                            setDeleteModal={setDeleteModal}
                          />
                          {assignment.file && (
                            <>
                              <a
                                href={assignment.file.link}
                                download
                                target="__window"
                              >
                                <Button
                                  size="small"
                                  color="success"
                                  variant="contained"
                                >
                                  Download
                                </Button>
                              </a>
                              <a href={assignment.file.link} target="_blank">
                                <Button
                                  size="small"
                                  color="success"
                                  variant="contained"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleCopyLink(assignment.file.link);
                                  }}
                                >
                                  Copy Link
                                </Button>
                              </a>
                            </>
                          )}
                          {assignment.link && (
                            <a
                              href={assignment.link}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <Button
                                size="small"
                                color="success"
                                variant="contained"
                              >
                                Link
                              </Button>
                            </a>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {assignments.length === 0 && (
                <h5 style={{ textAlign: "center" }}>No data</h5>
              )}
            </TableContainer>
          </Paper>
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Paper sx={{ marginTop: 3, padding: 1 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                {" "}
                <TextField
                  name="assignmentTitle"
                  label="Assignment Title"
                  required
                  fullWidth
                  value={formData.assignmentTitle || ""}
                  size="small"
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                {" "}
                <FormControl fullWidth size="small">
                  <InputLabel>Class</InputLabel>
                  <Select
                    name="class"
                    value={formData.class || ""}
                    label="Class"
                    onChange={handleFormChange}
                  >
                    {schoolClasses.map((schoolClass) => (
                      <MenuItem key={schoolClass._id} value={schoolClass._id}>
                        {schoolClass.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                {" "}
                <FormControl fullWidth size="small">
                  <InputLabel>Sections</InputLabel>
                  <Select
                    name="section"
                    value={formData.section || ""}
                    label="Sections"
                    onChange={handleFormChange}
                  >
                    {formData.class && <MenuItem value="all">-all-</MenuItem>}
                    {sections.map((section) => (
                      <MenuItem key={section._id} value={section._id}>
                        {section.sectionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                {" "}
                <FormControl fullWidth size="small">
                  <InputLabel>Subject</InputLabel>
                  <Select
                    name="subject"
                    value={formData.subject || ""}
                    label="Subject"
                    onChange={handleFormChange}
                  >
                    {subjects.map((subject) => (
                      <MenuItem key={subject._id} value={subject._id}>
                        {subject.subjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                {" "}
                <FormControl fullWidth size="small">
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="assignmentType"
                    value={formData.assignmentType || ""}
                    label="Type"
                    onChange={handleFormChange}
                  >
                    <MenuItem value={"class"}>Class</MenuItem>
                    <MenuItem value={"assignment"}>Assignment</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                {" "}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Deadline"
                    openTo="day"
                    views={["year", "month", "day"]}
                    inputFormat="DD-MM-YYYY"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                {" "}
                <FormControl fullWidth size="small">
                  <InputLabel>File type</InputLabel>
                  <Select
                    name="fileType"
                    value={formData.fileType || ""}
                    label="File Type"
                    onChange={handleFormChange}
                  >
                    <MenuItem value={"File"}>File</MenuItem>
                    <MenuItem value={"Link"}>Link</MenuItem>
                  </Select>
                </FormControl>
                {formData.fileType === "File" && (
                  // <div className={css.formHolder}>
                  <Box mt={1}>
                    <input
                      type="file"
                      onChange={(e) => setInputFile(e.target.files[0])}
                    />
                  </Box>

                  // </div>
                )}
                {formData.fileType === "Link" && (
                  // <div className={css.formHolder}>
                  <TextField
                    sx={{ marginTop: 1 }}
                    size="small"
                    name="link"
                    label="Link"
                    required
                    fullWidth
                    value={formData.link || ""}
                    variant="outlined"
                    onChange={handleFormChange}
                  />
                  // </div>
                )}
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                {" "}
                <FormControl fullWidth size="small">
                  <InputLabel>Is publish to web</InputLabel>
                  <Select
                    name="publishToWeb"
                    value={formData.publishToWeb || ""}
                    label="Is publish to web"
                    onChange={handleFormChange}
                  >
                    <MenuItem value={"yes"}>Yes</MenuItem>
                    <MenuItem value={"no"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                {" "}
                <TextareaAutosize
                  name="note"
                  placeholder="Note"
                  fullWidth
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    //  width: "80%",
                    maxHeight: 110,
                    //  margin: 8,
                    padding: 10,
                    height: 70,
                    overflow: "auto",
                  }}
                  value={formData.note || ""}
                  variant="outlined"
                  onChange={handleFormChange}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                gap={2}
              >
                <Button
                  onClick={handleAddCancel}
                  variant="contained"
                  color="error"
                  sx={{ color: "#fff" }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={loader}
                  type="submit"
                  style={{ background: "#1b3779", color: "#fff" }}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </TabPanel>
      {showEdit && (
        <TabPanel value={tabValue} index={2}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <TextField
                  size="small"
                  name="assignmentTitle"
                  label="Assignment Title"
                  required
                  fullWidth
                  value={editForm.assignmentTitle || ""}
                  variant="outlined"
                  onChange={handleEditFormChange}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Class</InputLabel>
                  <Select
                    name="class"
                    value={editForm.class || ""}
                    label="Class"
                    onChange={handleEditFormChange}
                  >
                    {schoolClasses.map((schoolClass) => (
                      <MenuItem key={schoolClass._id} value={schoolClass._id}>
                        {schoolClass.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Sections</InputLabel>
                  <Select
                    name="section"
                    value={editForm.section || "All"}
                    label="Sections"
                    onChange={handleEditFormChange}
                  >
                    {/* {editForm.class && (
                        <MenuItem value="all">-all-</MenuItem>
                      )} */}
                    {[{ _id: "All", sectionName: "All" }, ...sections].map(
                      (section) => (
                        <MenuItem key={section._id} value={section._id}>
                          {section.sectionName}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Subject</InputLabel>
                  <Select
                    name="subject"
                    value={editForm.subject || ""}
                    label="Subject"
                    onChange={handleEditFormChange}
                  >
                    {subjects.map((subject) => (
                      <MenuItem key={subject._id} value={subject._id}>
                        {subject.subjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="assignmentType"
                    value={editForm.assignmentType || ""}
                    label="Type"
                    onChange={handleEditFormChange}
                  >
                    <MenuItem value={"class"}>Class</MenuItem>
                    <MenuItem value={"assignment"}>Assignment</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Deadline"
                    inputFormat="DD-MM-YYYY"
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={editDate}
                    onChange={(newValue) => {
                      setEditDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
                {editForm.file && (
                  <div className={css.formHolder}>
                    <input
                      type="file"
                      onChange={(e) => setInputFile(e.target.files[0])}
                    />
                    {editForm.file ? editForm.file.fileName : "NA"}
                    <Link
                      variant="inherit"
                      target="_blank"
                      href={editForm.file.link}
                    >
                      Download File
                    </Link>
                  </div>
                )}
                {editForm.link && (
                  <div className={css.formHolder}>
                    <TextField
                      size="small"
                      name="link"
                      label="Link"
                      required
                      fullWidth
                      value={editForm.link || ""}
                      variant="outlined"
                      onChange={handleEditFormChange}
                    />
                  </div>
                )}
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <FormControl fullWidth size="small">
                  <InputLabel>Is publish to web</InputLabel>
                  <Select
                    name="publishToWeb"
                    value={editForm.publishToWeb || ""}
                    label="Is publish to web"
                    onChange={handleEditFormChange}
                  >
                    <MenuItem value={"yes"}>Yes</MenuItem>
                    <MenuItem value={"no"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <TextareaAutosize
                  name="note"
                  placeholder="Note"
                  fullWidth
                  style={{ width: "100%" }}
                  value={editForm.note || ""}
                  variant="outlined"
                  onChange={handleEditFormChange}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <div className={css.formBtn}>
                  <div>
                    <Button
                      onClick={handleEditCancel}
                      variant="contained"
                      color="error"
                      sx={{ color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <LoadingButton
                      loading={load}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </LoadingButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </TabPanel>
      )}
    </Wrapper>
  );
};
export default Assignment;

{
  /* <TabPanel value={tabValue} index={2}>
<div className={css.container}>
  <Paper>
    <div className={css.paperContainer}>
      <div className={css.formContainer}>
        <form onSubmit={handleSubmit} className={css.form}>
          <div className={css.formHolder}>
            <TextField
              size="small"
              name="assignmentTitle"
              label="Assignment Title"
              required
              fullWidth
              value={editForm.assignmentTitle || ""}
              variant="outlined"
              onChange={handleEditFormChange}
            />
          </div>
          <div className={css.formHolder}>
            <FormControl fullWidth size="small">
              <InputLabel>Class</InputLabel>
              <Select
                name="class"
                value={editForm.class || ""}
                label="Class"
                onChange={handleEditFormChange}
              >
                {schoolClasses.map((schoolClass) => (
                  <MenuItem
                    key={schoolClass._id}
                    value={schoolClass._id}
                  >
                    {schoolClass.className}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={css.formHolder}>
            <FormControl fullWidth size="small">
              <InputLabel>Sections</InputLabel>
              <Select
                name="section"
                value={editForm.section || "All"}
                label="Sections"
                onChange={handleEditFormChange}
              >
               
                {[
                  { _id: "All", sectionName: "All" },
                  ...sections,
                ].map((section) => (
                  <MenuItem key={section._id} value={section._id}>
                    {section.sectionName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={css.formHolder}>
            <FormControl fullWidth size="small">
              <InputLabel>Subject</InputLabel>
              <Select
                name="subject"
                value={editForm.subject || ""}
                label="Subject"
                onChange={handleEditFormChange}
              >
                {subjects.map((subject) => (
                  <MenuItem key={subject._id} value={subject._id}>
                    {subject.subjectName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={css.formHolder}>
            <FormControl fullWidth size="small">
              <InputLabel>Type</InputLabel>
              <Select
                name="assignmentType"
                value={editForm.assignmentType || ""}
                label="Type"
                onChange={handleEditFormChange}
              >
                <MenuItem value={"class"}>Class</MenuItem>
                <MenuItem value={"assignment"}>Assignment</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={css.formHolder}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Deadline"
                inputFormat="DD-MM-YYYY"
                openTo="day"
                views={["year", "month", "day"]}
                value={editDate}
                onChange={(newValue) => {
                  setEditDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          {editForm.file && (
            <div className={css.formHolder}>
              <input
                type="file"
                onChange={(e) => setInputFile(e.target.files[0])}
              />
              {editForm.file ? editForm.file.fileName : "NA"}
              <Link
                variant="inherit"
                target="_blank"
                href={editForm.file.link}
              >
                Download File
              </Link>
            </div>
          )}
          {editForm.link && (
            <div className={css.formHolder}>
              <TextField
                size="small"
                name="link"
                label="Link"
                required
                fullWidth
                value={editForm.link || ""}
                variant="outlined"
                onChange={handleEditFormChange}
              />
            </div>
          )}
          <div className={css.formHolder}>
            <TextareaAutosize
              name="note"
              placeholder="Note"
              fullWidth
              style={{ width: "100%" }}
              value={editForm.note || ""}
              variant="outlined"
              onChange={handleEditFormChange}
            />
          </div>
          <div className={css.formHolder}>
            <FormControl fullWidth size="small">
              <InputLabel>Is publish to web</InputLabel>
              <Select
                name="publishToWeb"
                value={editForm.publishToWeb || ""}
                label="Is publish to web"
                onChange={handleEditFormChange}
              >
                <MenuItem value={"yes"}>Yes</MenuItem>
                <MenuItem value={"no"}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={css.formBtn}>
            <div>
              <Button
                onClick={handleEditCancel}
                variant="contained"
                color="error"
                sx={{ color: "#fff" }}
              >
                Cancel
              </Button>
            </div>
            <div>
              <LoadingButton
                loading={load}
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Paper>
</div>


</TabPanel> */
}
