// import {
//   Box,
//   Button,
//   Card,
//   Fab,
//   FormControl,
//   Grid,
//   InputAdornment,
//   InputLabel,
//   MenuItem,
//   Modal,
//   Paper,
//   Select,
//   TextField,
//   TextareaAutosize,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import React, { useContext, useEffect, useState } from "react";
// import { del, get, post, put } from "../../services/apis";
// import { urls as url } from "../../services/urlConstant";
// import AddIcon from "@mui/icons-material/Add";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { Delete, Menu, Search } from "@mui/icons-material";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import PropTypes from "prop-types";
// import { styled } from "@mui/material/styles";
// import CircularProgress from "@mui/material/CircularProgress";
// import SettingContext from "../../context/SettingsContext";
// import appendStringToUrls from "../../utils/appendString";
// import { row } from "@syncfusion/ej2-react-grids";
// import Wrapper from "../Wrapper";
// import SectionSubHeader from "../SectionSubHeader";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));
// function BootstrapDialogTitle(props) {
//   const { children, onClose, ...other } = props;
//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }
// BootstrapDialogTitle.propTypes = {
//   children: PropTypes.node,
//   onClose: PropTypes.func.isRequired,
// };

// const StudentCheckout = () => {
//   const { selectedSetting } = useContext(SettingContext);
//   const [search, setSearch] = useState("");
//   const [searchFilter, setSearchFilter] = useState([]);
//   const [open, setOpen] = React.useState(false);
//   const [dialog, setOpenDialog] = React.useState(false);
//   const [academicYears, setAcademicYears] = useState([]);
//   const [classes, setClasses] = useState([]);
//   const [sections, setSections] = useState([]);
//   const [names, setNames] = useState([]);
//   const [formData, setFormData] = useState([]);
//   const [studentCheckOutDetais, setStudentCheckOutDetais] = useState([]);
//   const [editStudentCheckOutDetais, setEditStudentCheckOutDetais] = useState();
//   const [showDetails, setShowDetails] = useState(null);
//   const [studentName, setStudentName] = useState("");

//   const [loading, setLoading] = useState(false);
//   const [showPage, setShowPage] = useState(false);

//   const [state, setState] = useState({
//     visitorName: "",
//     relationship: "",
//     visitorPhone: "",
//     checkOut: "",
//   });

//   let { visitorName, relationship, visitorPhone, checkOut } = state;

//   const urls = appendStringToUrls(url, `${selectedSetting._id}`);

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     setShowPage(true);
//     setStudentName(formData.name);
//   };

//   useEffect(() => {
//     const getAcademicYears = async () => {
//       try {
//         const res = await get(urls.administrator.academicYearGetAll);
//         setFormData((prev) => ({
//           ...prev,
//           academicYear: res.data.data.length ? res.data.data[0]._id : "",
//         }));
//         setAcademicYears(res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getAcademicYears();
//   }, []);

//   useEffect(() => {
//     const getClasses = async () => {
//       try {
//         const res = await get(urls.class.getAllClass);
//         let search = res.data.data.length
//           ? { class: res.data.data[0]._id }
//           : {};
//         const resSections = await get(urls.schoolSection.getAllSection, {
//           params: {
//             search,
//           },
//         });
//         setSections(resSections.data.data);
//         setClasses(res.data.data);

//         setFormData((prev) => ({
//           ...prev,
//           className: res.data?.data[0]?._id,
//           section: resSections.data?.data[0]?._id,
//         }));
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getClasses();
//   }, [selectedSetting._id]);

//   useEffect(() => {
//     const getStudents = async () => {
//       console.log(formData, "Formdata");
//       if (!formData.className || !formData.section) return;
//       let payload = {};
//       if (formData.section === "all") {
//         payload = {
//           academicYear: formData.academicYear,

//           "academicInfo.class": formData.className,
//         };
//       } else {
//         payload = {
//           academicYear: formData.academicYear,
//           "academicInfo.section": formData.section,
//           "academicInfo.class": formData.className,
//         };
//       }
//       const searchStudents = await get(urls.students.getAllStudent, {
//         params: {
//           search: payload,
//         },
//       });
//       console.log(searchStudents, "stdd");
//       setNames(searchStudents.data.data);
//     };
//     getStudents();
//   }, [formData.className, formData.section, selectedSetting._id]);

//   const handleOnChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleChange = (e) => {
//     setState({ ...state, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       let payload = {
//         student: studentName,
//         visitorName,
//         relationship,
//         visitorPhone,
//       };
//       console.log(payload, "payload");

//       const res = await post(`${urls.studentCheckOuting.create}`, payload);
//       console.log(res);
//       setState({
//         visitorName: "",
//         relationship: "",
//         visitorPhone: "",
//       });
//       setStudentName("");
//       handleClose();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleClassChange = async (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     try {
//       const section = await get(urls.schoolSection.getAllSection, {
//         params: {
//           search: {
//             class: e.target.value,
//           },
//         },
//       });
//       if (section.data.data.length > 0) {
//         setFormData((prev) => ({
//           ...prev,
//           section: section.data.data[0]._id,
//         }));
//       } else {
//         setFormData((prev) => ({
//           ...prev,
//           section: "",
//         }));
//       }
//       setSections(section.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setShowPage(false);
//     setState({
//       visitorName: "",
//       relationship: "",
//       visitorPhone: "",
//     });
//     setStudentName("");
//   };
//   const handleClickOpenDialog = (value) => {
//     setOpenDialog(true);
//     setShowDetails(value);
//   };
//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };
//   const styles = {
//     button: {
//       backgroundColor: "orange",
//     },
//     card: {
//       padding: 10,
//       margin: "10px 0px",
//     },
//     cardButton: {
//       display: "flex",
//       justifyContent: "flex-end",
//       textAlign: "center",
//       gap: "10px",
//     },

//     textfield: {
//       marginTop: "25px",
//       width: "70%",
//     },
//     addbutton: {
//       paddingTop: "6.2px",
//       paddingBottom: "6.2px",
//     },
//     tab: {
//       width: "100%",
//       borderCollapse: "collapse",
//     },
//   };

//   const handleCancel = () => {
//     setShowPage(false);
//   };

//   useEffect(() => {
//     const fetchedData = async () => {
//       try {
//         const { data } = await get(`${urls.studentCheckOuting.getAll}`);
//         setStudentCheckOutDetais(data.data);
//         console.log(data.data, "all checkout");
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchedData();
//   }, [selectedSetting._id]);

//   const handleSearch = (e) => {
//     const { value } = e.target;
//     setSearch(value.trim());
//     if (value.trim() !== "") {
//       studentCheckOutDetais.length > 0 &&
//         setSearchFilter(
//           studentCheckOutDetais.searchFilter((ele) =>
//             ele.name.toLowerCase().includes(value.toLowerCase().trim())
//           )
//         );
//     } else {
//       setSearchFilter([]);
//     }
//   };

//   return (
//     <Wrapper>
//       <SectionSubHeader title="Student Checkout" />
//       <div style={{ margin: "15px 0" }}>
//         <Card style={{ margin: "15px 0px 15px 0px", padding: 10 }}>
//           <Box sx={{ flexGrow: 1 }}>
//             <form onSubmit={handleFormSubmit}>
//               <Grid container spacing={2} marginBottom={"5px"}>
//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl fullWidth required>
//                     <InputLabel>Academic Year</InputLabel>
//                     <Select
//                       label="Academic Year"
//                       id="demo-simple-select-filled"
//                       name="academicYear"
//                       variant="standard"
//                       value={formData.academicYear || ""}
//                       onChange={handleOnChange}
//                       size="small"
//                     >
//                       {academicYears &&
//                         academicYears.map((row, index) => (
//                           <MenuItem key={row._id} value={row._id}>
//                             {row.academicYearFrom} - {row.academicYearTo}
//                           </MenuItem>
//                         ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl fullWidth required>
//                     <InputLabel>Class</InputLabel>
//                     <Select
//                       label="Class"
//                       variant="standard"
//                       id="demo-simple-select-filled"
//                       name="className"
//                       value={formData.className || ""}
//                       onChange={handleClassChange}
//                       size="small"
//                     >
//                       {classes &&
//                         classes.map((row, index) => (
//                           <MenuItem key={row._id} value={row._id}>
//                             {row.className}
//                           </MenuItem>
//                         ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl fullWidth variant="standard" required>
//                     <InputLabel>Section</InputLabel>
//                     <Select
//                       label="Section"
//                       variant="standard"
//                       id="demo-simple-select-filled"
//                       name="section"
//                       value={formData.section || ""}
//                       onChange={handleOnChange}
//                       size="small"
//                     >
//                       {formData.className && (
//                         <MenuItem value="all">--all--</MenuItem>
//                       )}
//                       {sections.map((row, index) => (
//                         <MenuItem
//                           key={row._id}
//                           value={row._id}
//                           sx={{ fontSize: 12, fontWeight: 500 }}
//                         >
//                           {row.sectionName}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>

//                 <Grid
//                   item
//                   xs={12}
//                   md={6}
//                   lg={3}
//                   style={{ alignSelf: "flex-end" }}
//                 >
//                   <FormControl fullWidth variant="standard" required>
//                     <InputLabel>Student Name</InputLabel>
//                     <Select
//                       label="Student Name"
//                       variant="standard"
//                       id="demo-simple-select-filled"
//                       name="name"
//                       value={formData.name || ""}
//                       onChange={handleOnChange}
//                       size="small"
//                     >
//                       {names.map((row, index) => (
//                         <MenuItem key={index} value={row._id}>
//                           {row.basicInfo.name}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item>
//                   <Button
//                     variant="contained"
//                     sx={{
//                       background: "rgb(27, 55, 121)",
//                       ":hover": { background: "rgb(27, 55, 121)" },
//                     }}
//                     type="submit"
//                   >
//                     Find
//                   </Button>
//                 </Grid>
//               </Grid>
//             </form>
//           </Box>
//         </Card>

//         {/* {loading ? (
//             <div>
//               <CircularProgress />
//             </div>
//           ) : showPage ? ( */}
//         <Modal
//           open={showPage}
//           onClose={handleClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <form className="form-tag" onSubmit={handleSubmit}>
//             <Box
//               // sx={style}
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 backgroundColor: "white",
//                 maxWidth: "400px",
//                 flexDirection: "column",
//                 padding: "30px",
//               }}
//             >
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 textAlign="center"
//                 marginBottom="30px"
//               >
//                 {/* {editStudentCheckOutDetais
//                   ? "Update Student Checkout"
//                   : "Add Student Checkout"} */}
//                 Add Student Checkout
//               </Typography>

//               <TextField
//                 required
//                 id="filled-required"
//                 variant="standard"
//                 label="Name"
//                 name="studentName"
//                 value={studentName}
//                 onChange={handleChange}
//                 sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
//               />

//               {/* <InputLabel>Student Name</InputLabel>
//                         <Select
//                           label="Student Name"
//                           variant="standard"
//                           id="demo-simple-select-filled"
//                           name="studentName"
//                           value={studentName}
//                           onChange={handleChange}
//                           size="small"
//                           sx={{ minWidth: 227 }}
//                         >
//                           {names.map((row, index) => (
//                             <MenuItem key={index} value={row._id}>
//                               {row.basicInfo.name}
//                             </MenuItem>
//                           ))}
//                         </Select> */}
//               <TextField
//                 required
//                 id="filled-required"
//                 variant="standard"
//                 label="Relationship"
//                 name="relationship"
//                 value={relationship}
//                 onChange={handleChange}
//                 sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
//               />
//               <TextField
//                 required
//                 id="filled-required"
//                 variant="standard"
//                 label="Visitor Name"
//                 name="visitorName"
//                 value={visitorName}
//                 onChange={handleChange}
//                 sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
//               />

//               <TextField
//                 required
//                 id="filled-required"
//                 variant="standard"
//                 label="Visitor Phone number"
//                 name="visitorPhone"
//                 type="number"
//                 value={visitorPhone}
//                 onChange={handleChange}
//                 sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
//               />

//               {editStudentCheckOutDetais ? (
//                 <TextField
//                   required
//                   id="filled-required"
//                   variant="standard"
//                   label="Check Out"
//                   // type="time"
//                   name="checkOut"
//                   value={state.checkOut}
//                   onChange={handleChange}
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   // inputProps={{ readOnly: Boolean(editStudentCheckOutDetais) }}
//                   sx={{
//                     m: 1,
//                     width: { md: 227, xs: "95%" },
//                     label: { fontSize: 12 },
//                   }}
//                 />
//               ) : null}

//               <TextField
//                 id="outlined-basic"
//                 label="verified OTP"
//                 variant="outlined"
//                 size="small"
//               />

//               <Box sx={{ marginTop: "10px" }}>
//                 <Tooltip title="Cancel">
//                   <Button
//                     onClick={handleClose}
//                     variant="outlined"
//                     size="medium"
//                     type="button"
//                     sx={{ m: 1 }}
//                   >
//                     Cancel
//                   </Button>
//                 </Tooltip>
//                 <Tooltip title="Submit">
//                   <Button
//                     variant="contained"
//                     size="medium"
//                     type="submit"
//                     sx={{
//                       background: "rgb(27, 55, 121)",
//                       ":hover": { background: "rgb(27, 55, 121)" },
//                     }}
//                   >
//                     {/* {editStudentCheckOutDetais ? "Update" : "Submit"} */}
//                     Submit
//                   </Button>
//                 </Tooltip>
//               </Box>
//             </Box>
//           </form>
//         </Modal>
//         {/* )
//            : (
//             // Show the form when loading is false and showPage is false

//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   id="outlined-basic"
//                   label="Generate OTP"
//                   variant="outlined"
//                   size="small"
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   id="outlined-basic"
//                   label="verified OTP"
//                   variant="outlined"
//                   size="small"
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <Button
//                   sx={{
//                     color: "white",
//                     background: "rgb(27, 55, 121)",
//                     ":hover": { background: "rgb(27, 55, 121)" },
//                   }}
//                   // onClick={handleButtonClick}
//                 >
//                   Submit
//                 </Button>
//               </Grid>
//             </Grid>
//           )
//           }*/}

//         <Card style={{ padding: "10px", margin: "10px 0px" }}>
//           <div style={styles.cardButton}>
//             <Button variant="contained">Excel</Button>
//             <Button variant="contained">Pdf</Button>
//           </div>
//         </Card>

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             // paddingTop: "22px",
//             padding: "15px",
//           }}
//         >
//           <Grid container spacing={2} justifyContent="flex-end">
//             <Grid item xs="auto">
//               <form>
//                 <TextField
//                   size="small"
//                   value={search}
//                   onChange={handleSearch}
//                   label="Search"
//                   variant="outlined"
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton edge="end" type="submit">
//                           <Search />
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               </form>
//             </Grid>
//           </Grid>
//         </div>

//         <Box overflow="scroll">
//           <Table>
//             <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
//               <TableRow sx={{ color: "white" }}>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   #SL
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Name
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Class
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Section
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Check Out
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Parent Name
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Relation
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Parent Phone Number
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Action
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {search
//                 ? searchFilter.map((value, index) => (
//                     <TableRow key={index}>
//                       <TableCell align="center">{index + 1}</TableCell>
//                       <TableCell align="center">{value.student}</TableCell>
//                       <TableCell align="center">class</TableCell>
//                       <TableCell align="center">section</TableCell>
//                       <TableCell align="center">
//                         {new Date(value.checkOut).toLocaleString()}
//                       </TableCell>
//                       <TableCell align="center">{value.visitorName}</TableCell>
//                       <TableCell align="center">{value.relationship}</TableCell>
//                       <TableCell align="center">{value.visitorPhone}</TableCell>
//                       <TableCell align="center">
//                         <div className="edit-delete">
//                           {/* <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() => handleEdit(value._id, value)}
//                           >
//                             <EditIcon />
//                           </Button> */}
//                           <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() => handleClickOpenDialog()}
//                           >
//                             <VisibilityIcon />
//                           </Button>
//                           {/* <Button
//                              onClick={() => handleDelete(value._id)}
//                             style={{
//                               color: "#1b3779",
//                             }}
//                           >
//                             <Delete />
//                           </Button> */}
//                         </div>
//                       </TableCell>
//                     </TableRow>
//                   ))
//                 : studentCheckOutDetais.map((value, index) => (
//                     <TableRow key={index}>
//                       <TableCell align="center">{index + 1}</TableCell>
//                       <TableCell align="center">{value.student}</TableCell>
//                       <TableCell align="center">class</TableCell>
//                       <TableCell align="center">section</TableCell>
//                       <TableCell align="center">
//                         {new Date(value.checkOut).toLocaleString()}
//                       </TableCell>
//                       <TableCell align="center">{value.visitorName}</TableCell>
//                       <TableCell align="center">{value.relationship}</TableCell>
//                       <TableCell align="center">{value.visitorPhone}</TableCell>
//                       <TableCell align="center">
//                         <div className="edit-delete">
//                           {/* <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() => handleEdit(value._id, value)}
//                           >
//                             <EditIcon />
//                           </Button> */}
//                           <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() => handleClickOpenDialog(value)}
//                           >
//                             <VisibilityIcon />
//                           </Button>
//                           {/* <Button
//                             //  onClick={() => handleDelete(value._id, value)}
//                             style={{
//                               color: "#1b3779",
//                             }}
//                           >
//                             <Delete />
//                           </Button> */}
//                         </div>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//             </TableBody>
//           </Table>
//           {!studentCheckOutDetais.length && !search.trim() && (
//             <Typography
//               variant="h6"
//               style={{ textAlign: "center", margin: "5px", padding: "5px" }}
//             >
//               No data found
//             </Typography>
//           )}
//           {search.trim() && !searchFilter.length && (
//             <Typography
//               variant="h6"
//               sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
//             >
//               No data found
//             </Typography>
//           )}
//         </Box>
//         <BootstrapDialog
//           onClose={handleCloseDialog}
//           aria-labelledby="customized-dialog-title"
//           open={dialog}
//         >
//           <BootstrapDialogTitle
//             id="customized-dialog-title"
//             onClose={handleCloseDialog}
//             style={{ backgroundColor: "#1b3779", color: "white" }}
//           >
//             Visitor Information
//           </BootstrapDialogTitle>

//           <DialogContent dividers>
//             <table className="hostel" style={styles.tab}>
//               <tbody>
//                 {showDetails ? (
//                   <>
//                     <tr>
//                       <td>School Name</td>
//                       <td>{selectedSetting.schoolName}</td>
//                     </tr>
//                     <tr>
//                       <td> Name</td>
//                       <td>{showDetails.student}</td>
//                     </tr>
//                     <tr>
//                       <td>class</td>
//                       <td>8989899999</td>
//                     </tr>
//                     <tr>
//                       <td>Section</td>
//                       <td>address</td>
//                     </tr>
//                     <tr>
//                       <td>Parent Name</td>
//                       <td>{showDetails.visitorName}</td>
//                     </tr>
//                     <tr>
//                       <td>Relation</td>
//                       <td>{showDetails.relationship}</td>
//                     </tr>
//                     <tr>
//                       <td>Parent Phone</td>
//                       <td>{showDetails.visitorPhone}</td>
//                     </tr>
//                     <tr>
//                       <td>Check Out</td>
//                       <td>{new Date(showDetails.checkOut).toLocaleString()}</td>
//                     </tr>
//                   </>
//                 ) : null}
//               </tbody>
//             </table>
//           </DialogContent>
//         </BootstrapDialog>
//       </div>
//     </Wrapper>
//   );
// };

// export default StudentCheckout;

import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Menu, Search } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { row } from "@syncfusion/ej2-react-grids";
import Wrapper from "../Wrapper";
import SectionSubHeader from "../SectionSubHeader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const style = {
  position: "relative",
  width: "100%",

  height: "auto",
  bgcolor: "background.paper",
  maxWidth: { xs: "100%", sm: "350px", md: "350px", lg: "350px" },
  p: 2,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StudentCheckout = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [names, setNames] = useState([]);
  const [formData, setFormData] = useState([]);
  const [studentCheckOutDetais, setStudentCheckOutDetais] = useState([]);
  const [editStudentCheckOutDetais, setEditStudentCheckOutDetais] = useState();
  const [showDetails, setShowDetails] = useState(null);
  const [studentName, setStudentName] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(350));
  const [loading, setLoading] = useState(false);
  const [showPage, setShowPage] = useState(false);

  const [state, setState] = useState({
    visitorName: "",
    relationship: "",
    visitorPhone: "",
    checkOut: "",
  });

  let { visitorName, relationship, visitorPhone, checkOut } = state;

  const urls = appendStringToUrls(url, `${selectedSetting._id}`);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowPage(true);
    setStudentName(formData.name);
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        let search = res.data.data.length
          ? { class: res.data.data[0]._id }
          : {};
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search,
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);

        setFormData((prev) => ({
          ...prev,
          className: res.data?.data[0]?._id,
          section: resSections.data?.data[0]?._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    const getStudents = async () => {
      console.log(formData, "Formdata");
      if (!formData.className || !formData.section) return;
      let payload = {};
      if (formData.section === "all") {
        payload = {
          academicYear: formData.academicYear,

          "academicInfo.class": formData.className,
        };
      } else {
        payload = {
          academicYear: formData.academicYear,
          "academicInfo.section": formData.section,
          "academicInfo.class": formData.className,
        };
      }
      const searchStudents = await get(urls.students.getAllStudent, {
        params: {
          search: payload,
        },
      });
      console.log(searchStudents, "stdd");
      setNames(searchStudents.data.data);
    };
    getStudents();
  }, [formData.className, formData.section, selectedSetting._id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let payload = {
        student: studentName,
        visitorName,
        relationship,
        visitorPhone,
      };
      console.log(payload, "payload");

      const res = await post(`${urls.studentCheckOuting.create}`, payload);
      console.log(res);
      setState({
        visitorName: "",
        relationship: "",
        visitorPhone: "",
      });
      setStudentName("");
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setShowPage(false);
    setState({
      visitorName: "",
      relationship: "",
      visitorPhone: "",
    });
    setStudentName("");
  };
  const handleClickOpenDialog = (value) => {
    setOpenDialog(true);
    setShowDetails(value);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };

  const handleCancel = () => {
    setShowPage(false);
  };

  useEffect(() => {
    const fetchedData = async () => {
      try {
        const { data } = await get(`${urls.studentCheckOuting.getAll}`);
        setStudentCheckOutDetais(data.data);
        console.log(data.data, "all checkout");
      } catch (error) {
        console.log(error);
      }
    };
    fetchedData();
  }, [selectedSetting._id]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      studentCheckOutDetais.length > 0 &&
        setSearchFilter(
          studentCheckOutDetais.searchFilter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Student Checkout" />
      <div style={{ margin: "15px 0" }}>
        <Card style={{ margin: "15px 0px 15px 0px", padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2} marginBottom={"5px"}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      size="small"
                      id="demo-simple-select-filled"
                      name="className"
                      value={formData.className || ""}
                      onChange={handleClassChange}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      size="small"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                    >
                      {formData.className && (
                        <MenuItem value="all">--all--</MenuItem>
                      )}
                      {sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  style={{ alignSelf: "flex-end" }}
                >
                  <FormControl fullWidth size="small" required>
                    <InputLabel>Student Name</InputLabel>
                    <Select
                      label="Student Name"
                      size="small"
                      id="demo-simple-select-filled"
                      name="name"
                      value={formData.name || ""}
                      onChange={handleOnChange}
                    >
                      {names.map((row, index) => (
                        <MenuItem key={index} value={row._id}>
                          {row.basicInfo.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                    type="submit"
                  >
                    Find
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>

        {/* {loading ? (
            <div>
              <CircularProgress />
            </div>
          ) : showPage ? ( */}
        <Dialog
          fullScreen={fullScreen}
          open={showPage}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontWeight="bold"
                    fontSize="20px"
                  >
                    {/* {editStudentCheckOutDetais
                  ? "Update Student Checkout"
                  : "Add Student Checkout"} */}
                    Add Student Checkout
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Name"
                    name="studentName"
                    value={studentName}
                    onChange={handleChange}
                    // sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    fullWidth
                    label="Relationship"
                    name="relationship"
                    value={relationship}
                    onChange={handleChange}
                    // sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    fullWidth
                    label="Visitor Name"
                    name="visitorName"
                    value={visitorName}
                    onChange={handleChange}
                    // sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    fullWidth
                    label="Visitor Phone number"
                    name="visitorPhone"
                    type="number"
                    value={visitorPhone}
                    onChange={handleChange}
                    // sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                  {editStudentCheckOutDetais ? (
                    <TextField
                      required
                      id="filled-required"
                      size="small"
                      fullWidth
                      label="Check Out"
                      // type="time"
                      name="checkOut"
                      value={state.checkOut}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // inputProps={{ readOnly: Boolean(editStudentCheckOutDetais) }}
                      // sx={{
                      //   m: 1,
                      //   width: { md: 227, xs: "95%" },
                      //   label: { fontSize: 12 },
                      // }}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="verified OTP"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      size="medium"
                      color="error"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        m: 1,
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {/* {editStudentCheckOutDetais ? "Update" : "Submit"} */}
                      Submit
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>
        {/* )
           : (
            // Show the form when loading is false and showPage is false

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Generate OTP"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="verified OTP"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Button
                  sx={{
                    color: "white",
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  // onClick={handleButtonClick}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          ) 
          }*/}

        <Card style={{ padding: "10px", margin: "10px 0px" }}>
          <div style={styles.cardButton}>
            <Button variant="contained">Excel</Button>
            <Button variant="contained">Pdf</Button>
          </div>
        </Card>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // paddingTop: "22px",
            // padding: "15px",
            margin: "20px 0px",
          }}
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <form>
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </div>

        <Box overflow="scroll">
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Class
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Section
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Check Out
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Parent Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Relation
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Parent Phone Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((value, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{value.student}</TableCell>
                      <TableCell align="center">class</TableCell>
                      <TableCell align="center">section</TableCell>
                      <TableCell align="center">
                        {new Date(value.checkOut).toLocaleString()}
                      </TableCell>
                      <TableCell align="center">{value.visitorName}</TableCell>
                      <TableCell align="center">{value.relationship}</TableCell>
                      <TableCell align="center">{value.visitorPhone}</TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          {/* <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleEdit(value._id, value)}
                          >
                            <EditIcon />
                          </Button> */}
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleClickOpenDialog()}
                          >
                            <VisibilityIcon />
                          </Button>
                          {/* <Button
                             onClick={() => handleDelete(value._id)}
                            style={{
                              color: "#1b3779",
                            }}
                          >
                            <Delete />
                          </Button> */}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : studentCheckOutDetais.map((value, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{value.student}</TableCell>
                      <TableCell align="center">class</TableCell>
                      <TableCell align="center">section</TableCell>
                      <TableCell align="center">
                        {new Date(value.checkOut).toLocaleString()}
                      </TableCell>
                      <TableCell align="center">{value.visitorName}</TableCell>
                      <TableCell align="center">{value.relationship}</TableCell>
                      <TableCell align="center">{value.visitorPhone}</TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          {/* <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleEdit(value._id, value)}
                          >
                            <EditIcon />
                          </Button> */}
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleClickOpenDialog(value)}
                          >
                            <VisibilityIcon />
                          </Button>
                          {/* <Button
                            //  onClick={() => handleDelete(value._id, value)}
                            style={{
                              color: "#1b3779",
                            }}
                          >
                            <Delete />
                          </Button> */}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!studentCheckOutDetais.length && !search.trim() && (
            <Typography
              variant="h6"
              style={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Box>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Visitor Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <>
                    <tr>
                      <td>School Name</td>
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td> Name</td>
                      <td>{showDetails.student}</td>
                    </tr>
                    <tr>
                      <td>class</td>
                      <td>8989899999</td>
                    </tr>
                    <tr>
                      <td>Section</td>
                      <td>address</td>
                    </tr>
                    <tr>
                      <td>Parent Name</td>
                      <td>{showDetails.visitorName}</td>
                    </tr>
                    <tr>
                      <td>Relation</td>
                      <td>{showDetails.relationship}</td>
                    </tr>
                    <tr>
                      <td>Parent Phone</td>
                      <td>{showDetails.visitorPhone}</td>
                    </tr>
                    <tr>
                      <td>Check Out</td>
                      <td>{new Date(showDetails.checkOut).toLocaleString()}</td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </Wrapper>
  );
};

export default StudentCheckout;
