import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Box, Button, Modal, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { useRef } from "react";
import style from '../createevents.module.css'
const IconBox1 = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "rgba(0,0,0,0.4)",
}));

const IconBox2 = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "rgba(0,0,0,0.4)",
}));

const ModalMainBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  height: "500px",
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,

  [theme.breakpoints.down("md")]: {
    maxHeight: "400px",
    maxWidth: "700px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  [theme.breakpoints.down(720)]: {
    maxHeight: "300px",
    maxWidth: "500px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  [theme.breakpoints.down(550)]: {
    maxHeight: "250px",
    maxWidth: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  [theme.breakpoints.down(450)]: {
    maxHeight: "250px",
    maxWidth: "350px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    padding: 0,
  },
}));

const ModalBox = styled(Card)(({ theme }) => ({
  // maxWidth: "374px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  // border:"solid red",
  height: "500px",
  width: "900px",
  position: "absolute",
  top: 0,
  left: 0,

  [theme.breakpoints.down("md")]: {
    maxHeight: "400px",
    maxWidth: "700px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  [theme.breakpoints.down(720)]: {
    maxHeight: "300px",
    maxWidth: "500px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  [theme.breakpoints.down(550)]: {
    maxHeight: "250px",
    maxWidth: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  [theme.breakpoints.down(450)]: {
    maxHeight: "250px",
    maxWidth: "360px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const Gallery = ({ data }) => {
  let cardRef = useRef();

  let navigate = useNavigate();
  let handleClick = (data) => {
    navigate(data);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const [ModalData, setModalData] = useState(true);

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImage((prevImage) =>
        prevImage === data.dp.length - 1 ? 0 : prevImage + 1
      );
      setTimeout(() => setIsTransitioning(false), 300);
    }
  };

  const handlePrevious = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImage((prevImage) =>
        prevImage === 0 ? data.dp.length - 1 : prevImage - 1
      );
      setTimeout(() => setIsTransitioning(false), 300);
    }
  };

  const handleModal = () => {
    setModalData(!ModalData);
  };

  return (
    <>
      <Box sx={{ maxWidth: "300px", position: "relative", marginTop: "20px" }}>
        <Stack
          direction="row"
          sx={{
            zIndex: 50,
            position: "relative",
            bottom: "-20px",
            left: "10px",
            gap: "10px",
          }}
        >
          {data.title && (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleClick(data.path1)}
              sx={{
                bgcolor: "#1779f7",
                color: "white",
                border: "solid #1779f7",
                // bgcolor: "#f55214",
                "&:hover": {
                  color: "white",
                  bgcolor: "#ff4500",
                  border: "solid #ff4500",
                },
              }}
            >
              {data.title}
            </Button>
          )}

          {/* {data.role2 && (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleClick(data.path2)}
              sx={{
                bgcolor: "#1779f7",
                color: "white",
                border: "solid #1779f7",
                // bgcolor: "#f55214",
                "&:hover": {
                  color: "white",
                  bgcolor: "#ff4500",
                  border: "solid #ff4500",
                },
              }}
            >
              {data.role2}
            </Button>
          )} */}

        </Stack>

        <Card
          onClick={handleOpen}
          sx={{
            backgroundImage: `url(${data.images.map((item)=>{return item.link})})`,
            maxWidth: "374px",
            // maxHeight: "374px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <CardMedia
            component="img"
            alt="Paella dish"
            src={data.images.map((item)=>{return item.link})}
            sx={{ height: 294 }}
          />

          <CardActions className={style.text}
            sx={{
              bgcolor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {color: "red" },
            }}
          >
            <Typography className={style.typo}
              variant="h6"
              sx={{
                color: "black",
                // bgcolor: "transparent",
                // fontSize:"16px",
                // textTransform:"uppercase",
                "&:hover": { color: "#ff4500" },
              }}
            >
              {data.note}
            </Typography>
          </CardActions>
        </Card>

        <Box
          sx={{
            display: "flex",
            position: "absolute",
            zIndex: 50,
            top: "50%",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <IconBox1 onClick={handlePrevious}>
            <KeyboardArrowLeftIcon
              sx={{ cursor: "pointer", color: "#ff4500" }}
            />
          </IconBox1>
          <IconBox2 onClick={handleNext}>
            <KeyboardArrowRightIcon
              sx={{ cursor: "pointer", color: "#ff4500" }}
            />
          </IconBox2>
        </Box>
      </Box>

      {ModalData && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalMainBox>
            <ModalBox
              onClick={handleOpen}
              sx={{
                // maxWidth: "374px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // border:"solid red",
                height: "500px",
                width: "900px",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <CardMedia
                component="img"
                alt="Paella dish"
                src={data.images.map((item)=>{return item.link})}
                sx={{ height: "100%", width: "100%",objectFit:"cover",objectPosition:"center" }}
                onClick={() => handleClick(data.path)}
              />

              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  zIndex: 50,
                  top: "50%",
                  // left:0,
                  justifyContent: "space-between",
                  width: "100%",
                  // border:"solid red",
                }}
              >
                <IconBox1 onClick={handlePrevious}>
                  <KeyboardArrowLeftIcon
                    sx={{ cursor: "pointer", color: "red" }}
                  />
                </IconBox1>
                <IconBox2 onClick={handleNext}>
                  <KeyboardArrowRightIcon
                    sx={{ cursor: "pointer", color: "red" }}
                  />
                </IconBox2>
              </Box>
            </ModalBox>
          </ModalMainBox>
        </Modal>
      )}
    </>
  );
};

export default Gallery;
