let coursedata = [
  {   

      id:1,
      photo: new URL("https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/O6G3CB0-1.jpg"),
      department: "RESEARCH AND DEVELOPMENT",
      name: "Michel Smith",
      content:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima?"
  },
  {
      id:2,
      photo: new URL("https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/spain_01557.jpg"),
      department: "ANATOMY AND PHYSIOLOGY",
      name: "Michel Smith",
      content:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima?"
  },
  {
      id:3,
      photo: new URL("https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/courses23.jpg"),
      department: "AGRICULTURE AND FORESTRY",
      name: "Michel Smith",
      content:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima?"
  },
  {
      id:4,
      photo: new URL("https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/courses23.jpg"),
      department: "DIGITAL PHOTOGRAPHY",
      name: "Michel Smith",
      content:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima?"
  },
  {
      id:5,
      photo: new URL("https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/spain_01557.jpg"),
      department: "RESEARCH AND DEVELOPMENT",
      name: "Michel Smith",
      content:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur quasi architecto hic soluta corrupti inventore dolores tempora, ducimus nostrum. Minima?"
  },
]

export default coursedata;