// import Grid from '@mui/material/Grid'
import { Container, Typography, Box, Grid, styled } from "@mui/material";
// import galleryData from './galleryData'
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import zIndex from '@mui/material/styles/zIndex';
import { useRef, useState } from "react";
import ImageCarousal from "../ImageCarousel";

// import Typography from '@mui/material/Typography'

const StyledContainer = styled(Container)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  backgroundColor: "lightgray",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  height: "300px",
  marginRight: "20px",
  padding: 0,
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flex: 1,
  height: "100%",
  transition: "transform 0.3s ease-in-out",

  "&:hover .icons": {
    visibility: "visible",
  },
}));

const Grid1 = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  flex: 1,
}));

const Grid2 = styled(Grid)(({ theme }) => ({
  display: "flex",
  flex: 1,
}));

const MainBox = styled(Box)(({ theme }) => ({
  width: "96%",
  top: "50%",
  visibility: "hidden",
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
  position: "absolute",
}));

const IconBox1 = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "rgba(0,0,0,0.4)",
}));

const IconBox2 = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "rgba(0,0,0,0.4)",
}));

const images = [
  "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg",
  "https://i.pinimg.com/236x/e5/71/ed/e571ed3bf8792c59b937d1587e4a0d35.jpg",
  "https://i.pinimg.com/236x/ca/5f/59/ca5f59b3f1b253217cacb09ee5831bbc.jpg",
  "https://i.pinimg.com/236x/ae/23/65/ae2365a2c99314afa59c6431fb7bc231.jpg",
];

const Gallery = ({ data }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const imageRef = useRef(null);

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImage((prevImage) =>
        prevImage === images.length - 1 ? 0 : prevImage + 1
      );
      setTimeout(() => setIsTransitioning(false), 300);

      // transition: "transform 0.3s ease-in-out",
    }
  };

  const handlePrevious = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImage((prevImage) =>
        prevImage === 0 ? images.length - 1 : prevImage - 1
      );
      setTimeout(() => setIsTransitioning(false), 300);
    }
  };

  return (
    <StyledContainer>
      <GridContainer container spacing={1}>
        <Grid1 item md={4}>
          <Typography variant="h6">{data.title}</Typography>
          <Typography variant="body1"> {data.content}</Typography>
        </Grid1>
        <Grid item md={8}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              backgroundColor: "red",
              width: "100%",
              maxWidth: "350px",
              minWidth: "250px",
              height: "300px",

              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageCarousal
              style={{ width: "100%", height: "300px" }}
              images={data.images.map((i) => i.link)}
            />
          </Box>
        </Grid>
      </GridContainer>
    </StyledContainer>
  );
};

export default Gallery;
