import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  Fab,
  styled,
  TextareaAutosize,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { get, post } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "650px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const styles = {
  textfield: {
    width: { md: 227, xs: "100%" },
    label: { fontSize: 12 },
    m: 1,
  },
};

const AddAwards = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState("2022-04-06");

  const [eventDetails, setEventDetails] = useState({});
  const [roles, setRoles] = useState([]);
  const [imageEvent, setImageEvent] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEventDetails({ ...eventDetails, [name]: value });
  };
  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setRoles(res.data.data);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);

  useEffect(() => {
    if (props.updateEvent) {
      setEventDetails(props.updateEvent);
      setFromDate(props.updateEvent.date);
      setToDate(props.updateEvent.toDate);
    }
  }, [props.updateEvent]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // props.setLoader(true)

    const fileForm = new FormData();
    let img = undefined;
    if (imageEvent) {
      fileForm.append("files", imageEvent);
      img = await post(urls.fileUpload.postFileUpload, fileForm);
    }
    const data = {
      title: eventDetails.title,
      eventFor: eventDetails.eventFor,
      location: eventDetails.location,
      date: fromDate,
      status: eventDetails.status,
      hostedBy: eventDetails.hostedBy,
      note: eventDetails.note,
      shortEvent: eventDetails.shortEvent,
      webView: eventDetails.webView,
    };
    if (imageEvent) {
      data.image = img.data.result[0]._id;
    }
    if (props.updateEvent) {
      await props.handleUpdate(data, props.updateEvent._id);
    } else {
      await props.handleSubmit(e, data);
    }
    setEventDetails("");
    setFromDate(dayjs());
    setToDate("");
    setImageEvent(null);
    props.getEvents();
    props.handleClose();
    // props.setLoader(false)
  };

  const handleCloseModal = () => {
    setEventDetails("");
    setFromDate(dayjs());
    setToDate("");
    setImageEvent(null);
    props.handleClose();
  };
  const handleClose = () => {
    setEventDetails("");
    setFromDate(dayjs());
    setToDate("");
    setImageEvent(null);
    props.handleClose();
  };
  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Add />
      </Fab>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <div>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    Add Award
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Title"
                    name="title"
                    value={eventDetails.title || ""}
                    onChange={(event) => handleOnChange(event)}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="location"
                    name="location"
                    value={eventDetails.location || ""}
                    onChange={(event) => handleOnChange(event)}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      name="date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate}
                      onChange={(newValue) => {
                        {
                          setFromDate(newValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          size="small"
                          required
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    name="hostedBy"
                    size="small"
                    value={eventDetails.hostedBy}
                    onChange={(event) => handleOnChange(event)}
                    label="Hosted By"
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-filled-label">
                      Is View on Web?
                    </InputLabel>
                    <Select
                      fullWidth
                      label="Is View On Web"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="webView"
                      value={eventDetails.webView || ""}
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        no
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={4}
                  md={6}
                  lg={6}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",

                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ border: "1px solid grey", color: "grey" }}
                  >
                    Upload image
                    <input
                      onChange={(e) => setImageEvent(e.target.files[0])}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                  </Button>
                </Grid>

                <Grid item xs={8} md={6} lg={6}>
                  <span
                    style={{
                      marginLeft: "10xp",
                    }}
                  >
                    {imageEvent && imageEvent.name}
                  </span>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  {!imageEvent && eventDetails.image && (
                    <img
                      style={{ marginLeft: 10 }}
                      src={`${eventDetails.image.link}`}
                      alt=""
                      height={50}
                      width={50}
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    style={{
                      // width: "100%",
                      // height: "50px",

                      width: "100%",
                      maxHeight: 110,
                      // margin: 8,
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                      borderRadius: "5px",
                    }}
                    maxRows={4}
                    name="note"
                    value={eventDetails.note || ""}
                    placeholder="Note"
                    onChange={(event) => handleOnChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "right", xs: "center" },
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      size="medium"
                      type="button"
                      color="error"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={props.loader}
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{ background: "rgb(27, 55, 121)" }}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default AddAwards;
