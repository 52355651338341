import React, { useEffect, useState } from "react";
// import image1 from "../../assets/Images/slideimage1.png";
// import image2 from "../../assets/Images/slideimage2.png";
// import image3 from "../../assets/Images/slideimage3.png";
import image1 from "../../assets/Images/basavaimage.png";
import image2 from "../../assets/Images/kayakaIns.png";
import image3 from "../../assets/Images/kayakaShl.png";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MovingText from "react-moving-text";
import "./slider.css";

const backgroundImages = [image2, image1, image3];

const animationEffects = [
  // "swiper",
  // "fade-in",
  "slide-left",
  "slide-right",

  // "rotate",

  // "bounce",

  // "flip",

  // "zoom-out",
  // "fade-out",
  // "shake",
  "zoom-in",

  // "rotate-scale",

  "bounce-in",

  // "rotate-x",
  // "cube",

  "box",

  // "bricks",
];

const getRandomAnimation = () => {
  const randomIndex = Math.floor(Math.random() * animationEffects.length);
  return animationEffects[randomIndex];
};

const CarouselContainer = styled(Box)(({ theme }) => ({
  height: "90vh",
  width: "100%",
  // backgroundColor: "red",
  [theme.breakpoints.between(1279, 1281)]: {
    height: "90vh",
  },
  [theme.breakpoints.between(1000, 1120)]: {
    height: "85vh",
  },
  [theme.breakpoints.between(950, 1000)]: {
    height: "80vh",
  },
  [theme.breakpoints.between(913, 950)]: {
    height: "78vh",
  },
  [theme.breakpoints.between(911, 913)]: {
    height: "38vh",
  },
  [theme.breakpoints.between(900, 911)]: {
    height: "78vh",
  },
  [theme.breakpoints.between(800, 900)]: {
    height: "70vh",
  },
  [theme.breakpoints.between(819, 821)]: {
    height: "40vh",
  },
  [theme.breakpoints.between(769, 800)]: {
    height: "65vh",
  },
  [theme.breakpoints.between(767, 769)]: {
    height: "40vh",
  },
  [theme.breakpoints.between(750, 767)]: {
    height: "65vh",
  },
  [theme.breakpoints.between(700, 750)]: {
    height: "60vh",
  },
  [theme.breakpoints.between(650, 700)]: {
    height: "57vh",
  },
  [theme.breakpoints.between(600, 650)]: {
    height: "52vh",
  },
  [theme.breakpoints.between(550, 600)]: {
    height: "48vh",
  },
  [theme.breakpoints.between(500, 550)]: {
    height: "43vh",
  },
  [theme.breakpoints.between(450, 500)]: {
    height: "38vh",
  },
  [theme.breakpoints.between(400, 450)]: {
    height: "26vh",
  },
  [theme.breakpoints.between(350, 400)]: {
    height: "26vh",
  },
  [theme.breakpoints.between(300, 350)]: {
    height: "26vh",
  },
  [theme.breakpoints.down(300)]: {
    height: "25vh",
  },
}));

const ArrowSlideButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  // padding: "20px",
  top: "70%",
  position: "absolute",
  width: "100%",
  [theme.breakpoints.down(915)]: {
    display: "none",
  },
}));

const MovingTextContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "60%",
  left: "10%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  // [theme.breakpoints.between(1000,1120)]:{
  //   top :"70%"
  // },
  // [theme.breakpoints.between(913,1000)]:{
  //   top :"60%"
  // },
  // [theme.breakpoints.between(911,913)]:{
  //   top :"30%"
  // },
  // [theme.breakpoints.between(850,911)]:{
  //   top :"60%"
  // },
  // [theme.breakpoints.between(650,850)]:{
  //   top :"60%"
  // },
  // [theme.breakpoints.between(600,650)]:{
  //   top :"55%"
  // },
  // [theme.breakpoints.between(500,600)]:{
  //   top :"60%"
  // },
  // [theme.breakpoints.between(400,500)]:{
  //   top :"42%"
  // },
  // [theme.breakpoints.between(350,400)]:{
  //   top :"42%"
  // },
  // [theme.breakpoints.down(350)]:{
  //   top:"45%"
  // }
  [theme.breakpoints.down("md")]: {
    top: "45%",
  },
  [theme.breakpoints.down("sm")]: {
    top: "40%",
  },
  [theme.breakpoints.between(911, 913)]: {
    top: "30%",
  },
  [theme.breakpoints.between(819, 821)]: {
    top: "30%",
  },
  [theme.breakpoints.between(767, 769)]: {
    top: "38%",
  },
  [theme.breakpoints.between(411, 415)]: {
    top: "35%",
  },
  [theme.breakpoints.between(392, 394)]: {
    top: "35%",
  },
  [theme.breakpoints.between(279, 281)]: {
    top: "40%",
  },
}));

const FirstMovingText = styled(MovingText)(({ theme }) => ({
  zIndex: 20,
  position: "relative",
  color: "white",
  backgroundColor: "blue",
  fontSize: "16px",
  fontWeight: "bold",
  display: "inline-block",
  // backgroundColor: "rgba(0,0,0,0.4)",
  borderRadius: "10px",
  padding: "10px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.between(279, 281)]: {
    fontSize: "12px",
  },
}));

const SecondMovingText = styled(MovingText)(({ theme }) => ({
  zIndex: 20,
  position: "relative",
  color: "white",
  fontSize: "60px",
  display: "inline-block",
  // backgroundColor: "rgba(0,0,0,0.4)",
  // borderRadius: "10px",
  // padding: "5px",
  marginTop: "10px",
  fontFamily: "serif",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.between(279, 281)]: {
    fontSize: "16px",
  },
}));

const ThirdMovingText = styled(MovingText)(({ theme }) => ({
  zIndex: 20,
  position: "relative",
  color: "white",
  fontSize: "60px",
  fontFamily: "serif",
  fontWeight: "bold",
  display: "inline-block",
  // backgroundColor: "rgba(0,0,0,0.4)",
  // borderRadius: "10px",
  // padding: "5px",
  // marginTop: "2px",
  // fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.between(279, 281)]: {
    fontSize: "16px",
  },
}));

const LeftArrow = ({ activeIndex, onClick }) => {
  return (
    <Box
      onClick={() => onClick(activeIndex)}
      component={"div"}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "70px",
        width: "70px",
        borderRadius: "50%",
        color: "white",
        backgroundColor: "rgba(0,0,0,0.2)",
        backgroundSize: "cover",
        ":hover": {
          backgroundImage: `url(${
            activeIndex === 0
              ? backgroundImages[backgroundImages.length - 1]
              : backgroundImages[activeIndex - 1]
          })`,
          backgroundSize: "cover",
          transition: " 0.3s ease-in-out",
          animationDelay: "5s",
        },
      }}
    >
      <ArrowBackIosRoundedIcon />
    </Box>
  );
};

const RightArrow = ({ activeIndex, onClick }) => {
  return (
    <Box
      onClick={() => onClick(activeIndex)}
      component={"div"}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "70px",
        width: "70px",
        borderRadius: "50%",
        color: "white",
        backgroundColor: "rgba(0,0,0,0.2)",
        transition: "transform 0.3s ease-in-out",
        backgroundSize: "cover",
        ":hover": {
          backgroundImage: `url(${
            activeIndex === backgroundImages.length - 1
              ? backgroundImages[0]
              : backgroundImages[activeIndex + 1]
          })`,
          transition: " 0.3s ease-in-out",
        },
      }}
    >
      <ArrowForwardIosRoundedIcon />
    </Box>
  );
};

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [renderText, setRenderText] = useState(true);
  const [animationName, setAnimationName] = useState("");

  const goToPreviousSlide = () => {
    setActiveIndex(
      activeIndex === 0 ? backgroundImages.length - 1 : activeIndex - 1
    );
    setRenderText(false);
    setAnimationName(getRandomAnimation());
  };

  const goToNextSlide = () => {
    setActiveIndex(
      activeIndex === backgroundImages.length - 1 ? 0 : activeIndex + 1
    );
    setRenderText(false);
    setAnimationName(getRandomAnimation());
  };

  useEffect(() => {
    setAnimationName(getRandomAnimation());
  }, []);

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 10000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  useEffect(() => {
    setRenderText(true);
  }, [activeIndex]);

  return (
    <CarouselContainer>
      {backgroundImages.map((slideimages, index) => (
        <img
          key={index}
          src={slideimages}
          alt={`slideImage ${index + 1}`}
          style={{
            position: "absolute",
            zIndex: -1,
            width: "100%",
            height: "auto",
            opacity: index === activeIndex ? 1 : 0,
            transition: "opacity 0.8s ease-in-out",
            animation: `${animationName} 2s forwards`,
          }}
        />
      ))}

      <ArrowSlideButtonContainer>
        <LeftArrow activeIndex={activeIndex} onClick={goToPreviousSlide} />
        <RightArrow activeIndex={activeIndex} onClick={goToNextSlide} />
      </ArrowSlideButtonContainer>

      <MovingTextContainer>
        {renderText && (
          <>
            <FirstMovingText
              key={`text-1-${activeIndex}`}
              type="slideInFromBottom"
              duration="3000ms"
              delay="3s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              The Goal of Education Is The Advancement of Knowledge
            </FirstMovingText>
            <SecondMovingText
              key={`text-2-${activeIndex}`}
              type="slideInFromLeft"
              duration="3000ms"
              delay="3s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              Take The First Step
            </SecondMovingText>
            <ThirdMovingText
              key={`text-3-${activeIndex}`}
              type="slideInFromRight"
              duration="4000ms"
              delay="3s"
              direction="normal"
              timing="ease-in"
              iteration="1"
              fillMode="none"
            >
              To Knowledge with us
            </ThirdMovingText>
          </>
        )}
      </MovingTextContainer>
    </CarouselContainer>
  );
};

export default Slider;
