import {createSlice} from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name:"auth",
    initialState:false,
    reducers:{
        login:(state,action) => {
            state = action;
        },
        logout:(state) => {
            state = false
        }
    }
})

export const {login,logout} = authSlice.actions;
export default authSlice.reducer;