import { arrData } from "./AwardsAchiv";
import { Link, useLocation, useParams } from "react-router-dom";
import { CardComponent } from "./OurAchievements";
import { Box, Container, Grid, Typography, createTheme } from "@mui/material";
import styled from "@emotion/styled";
import style from "./OurBlog.module.css";
import { ThemeProvider } from "@emotion/react";
import Footer from "../Components2/Footer";
import Mainnav from "../NavbarComponents/MainNav";


const theme = createTheme();

const Text = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));
const Heading = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "2rem",
  textTransform: "uppercase",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Heading1 = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Headin = styled(Link)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
  textDecoration: "none",
}));
const BoxSize = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "20%",
  paddingTop: "2%",
  [theme.breakpoints.down("md")]: {},
}));

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://c4.wallpaperflare.com/wallpaper/869/719/717/cuisine-food-india-indian-wallpaper-preview.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  // paddingTop: "176px",
}));

const Detail = () => {

  const { id } = useParams();
   
  let {state}=useLocation()


  return (
    <>
    <OuterBox sx={{paddingTop:{md:"176px", sm:"235px", xs:"236px"}}}>
              <Mainnav/>
    </OuterBox>
    <Container>
      <Box sx={{
        marginTop:"5rem",
        width: "100%",
        height:"100%",
        overflow: "auto",
   }}>
        <ThemeProvider theme={theme}>
          <BoxSize>
            <CardComponent elem={state} />
          </BoxSize>
        </ThemeProvider>
      </Box>
      </Container>
      <Footer/>
    </>
  );
};
export default Detail;
