import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React, { useRef, useState } from "react";

import style from "./blog.module.css";
import Blog from "./Blog";

import { AppSlider } from "./AppSlider";
import { settings } from "./carousel";

import {FcNext,FcPrevious} from 'react-icons/fc'

const blogData = [
  {
    id: "1",

    role: "Library",

    color: "#02bda8",

    img: "/library.png",

    courses: "View More",

    title: "Library Facilities",
    path:"/facilities/library"

  },

  {
    id: "2",

    role: "Transport",

    color: "#02bda8",

    img: "/transport.png",

    courses: "View More",

    title: "Transport Facilities",
    path:"/facilities/transport"

  },

  {
    id: "3",

    role: "Dance and Singing",

    color: "#8dd17e",

    img: "/dance.png",

    courses: "View More",

    title: "Dance and Singing Facilities",
    path:"/facilities/dance-and-singing"

  },

  {
    id: "4",

    role: "Food",

    color: "#ffbc51",

    img: "/food.png",

    courses: "View More",

    title: "Food Facilities",
    path:"/facilities/canteen"

  },

  {
    id: "5",

    role: "Labs",

    color: "#02bda8",

    img: "/lab.png",

    courses: "View More",

    title: "Lab Facilities",
    path:"/facilities/labs"

  },
];

const Browser = styled(Typography)(({ theme }) => ({
  color: "#FF6D34",
  fontSize: "1rem",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  borderLeft: "3px solid #FFFFFF",
  padding: "0.5rem",
  height: "1rem",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down(350)]:{
    fontSize:"0.8rem"
  }
}));

const Trending = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "3rem",
  marginTop: "0.4rem",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  [theme.breakpoints.down(515)]:{
    fontSize:"2rem"
  },
  [theme.breakpoints.down(350)]:{
    fontSize:"1.3rem"
  }
}));

const CategoryBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: "bold",
  [theme.breakpoints.down(635)]: {
    flexDirection: "column",
  },
}));




const BlogMain = () => {

  let sliderRef= useRef(null)

  let handleprevious = () => {
    sliderRef.current.slickPrev();
  }
  let handlenext = () => {
    sliderRef.current.slickNext();
  }

  let [state,setstate]=useState(false)

  let handleSlide=()=>{
    setstate(!state)
  }
  
  
  return (
    <Box component="div" className={style.mainBox} >
      <Container sx={{ paddingTop: "8rem" }}>
        <Box>
          <Browser>BROWSE TOP</Browser>
          <CategoryBox>
            <Trending>Our Top Facilities</Trending>
            <Box>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#3A3F4B",
                  transition: "0.5s",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                  padding: "1rem",
                  color: "white",

                  "&:hover": { bgcolor: "white", color: "black" },
                }}
              >
                View All
              </Button>
            </Box>
          </CategoryBox>
        </Box>

        <Box  sx={{marginTop:"3rem",position:"relative",cursor:"pointer"}}
         onMouseEnter={handleSlide} onMouseLeave={handleSlide}
         >
          <Grid container spacing={3}> 
         <AppSlider ref={sliderRef} {...settings}>
              {blogData.map((data, i) => (
              <Grid item key={i}>
                <Blog
                  
                  role={data.role}
                  color={data.color}
                  image={data.img}
                  courses={data.courses}
                  title={data.title}
                  path={data.path}
                />
                 </Grid>
              ))}
           </AppSlider>
          {state && <Box component="div" sx={{marginLeft:"20px",position:"absolute",top:"45%",padding:{md:0},display:"flex",justifyContent:"space-between",width:"98.5%",transition:"all 2s",zIndex:10}}>
            <FcPrevious  onClick={handlenext} className={style.logo} size={30}/>
            <FcNext  onClick={handleprevious}  color="white" size={30}/>
          </Box>}
          </Grid>
        </Box>  
      </Container>
    </Box>
  );
};

export default BlogMain;
