import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SectionsSubHeader from "../../components/SectionSubHeader";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import {
  LocalizationProvider,
  DatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import css from "../../../src/styles/fees/QuickFeeCollectModal.module.css";
import DownloadIcon from "@mui/icons-material/Download";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const TransportFee = () => {
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({});
  const [sections, setSections] = useState([]);
  const [list, setList] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [names, setNames] = useState([]);
  const [routeDetails, setRouteDetails] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addDate, setAddDate] = useState(dayjs());
  const [paymentDetails, setPaymentDetails] = useState({});
  const [collect, setCollectData] = useState({
    payment: "",
  });

  const [classes, setClasses] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [overViewList, setOverViewList] = useState([]);

  const handleClickFindOverViewButton = async () => {
    if (!formData.className || !formData.section || !fromDate || !toDate)
      return;
    try {
      const { data } = await get(
        urls.transportFee.getOverView +
          formData.className +
          "/" +
          formData.section +
          "/" +
          new Date(fromDate) +
          "/" +
          new Date(toDate)
      );
      setOverViewList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const months = [];
  let currentDate = dayjs().startOf("year").month(6);
  for (let i = 0; i < 12; i++) {
    months.push(currentDate.format("MMMM"));
    currentDate = currentDate.add(1, "month");
  }

  const handlePaymentDetails = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setCollectData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });

        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting._id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFind = async (e) => {
    e.preventDefault();
    const response = await get(urls.transportFee.getStudentFeeStatus, {
      params: {
        studentId: formData.name,
      },
    });
    let responseData = response.data.data;
    let routeDetails =
      responseData.transportDetails.transportRouteTitle.routeDetails.filter(
        (m) => m._id == responseData.transportDetails.stopName
      )[0];
    setList(response.data.data);
    console.log(routeDetails, "routeDetails");
    setRouteDetails({
      ...routeDetails,
      routeName:
        responseData.transportDetails.transportRouteTitle.transportRouteTitle,
    });
    console.log(response.data.data, "responseeee");
  };

  useEffect(() => {
    const getStudents = async () => {
      console.log(formData, "Formdata");
      if (!formData.className || !formData.section) return;
      let payload = {};
      if (formData.section === "all") {
        payload = {
          academicYear: formData.academicYear,
          "otherInfo.transportMember": "yes",
          "academicInfo.class": formData.className,
        };
      } else {
        payload = {
          academicYear: formData.academicYear,
          "otherInfo.transportMember": "yes",
          "academicInfo.section": formData.section,
          "academicInfo.class": formData.className,
        };
      }
      const searchStudents = await get(urls.students.getAllStudent, {
        params: {
          search: payload,
        },
      });
      console.log(searchStudents, "stdd");
      setNames(searchStudents.data.data);
    };
    getStudents();
  }, [formData.className, formData.section, selectedSetting._id]);

  const handleClickDownload = async (id) => {
    try {
      const response = await get(urls.transportFee.downloadReceipt + id, {
        responseType: "blob",
      });
      const downloadUri = URL.createObjectURL(response.data);
      window.open(downloadUri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box style={{ margin: "10px" }}>
      <SectionsSubHeader title="Collect Fee" />

      <Card style={{ margin: "20px 0", padding: 10 }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Collect Fee" {...a11yProps(0)} />
            <Tab label="Overview" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card>
      <Box>
        <TabPanel value={tabValue} index={0}>
          <Card
            sx={{ padding: "10px", marginTop: "10px", marginBottom: "10px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl required fullWidth size="small">
                  <InputLabel>Class</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="className"
                    size="small"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl required fullWidth size="small">
                  <InputLabel>Section</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                  >
                    {formData.className && (
                      <MenuItem value="all">--all--</MenuItem>
                    )}
                    {sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                style={{ alignSelf: "flex-end" }}
              >
                <FormControl fullWidth size="small" required>
                  <InputLabel>Student Name</InputLabel>
                  <Select
                    label="Student Name"
                    size="small"
                    id="demo-simple-select-filled"
                    name="name"
                    value={formData.name || ""}
                    onChange={handleOnChange}
                  >
                    {names.map((row, index) => (
                      <MenuItem key={index} value={row._id}>
                        {row.basicInfo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sx={{ mt: "5px" }}>
                <Button variant="contained" size="small" onClick={handleFind}>
                  Find
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ padding: "10px" }}>
            <Typography
              sx={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              Transport Details:
            </Typography>
            <Typography>Route Name:{routeDetails?.routeName}</Typography>
            <Typography>Stop Name:{routeDetails?.stopName}</Typography>
            <Typography>Fare:{routeDetails?.fee}</Typography>
          </Card>
          {/* <Card sx={{mt:"10px"}}>
            <Box >
              {months.map((month, index) => (
                <Card key={index} style={{ flex: 1,padding:"5px"}}>
                  {month}
                </Card>
              ))}
            </Box>
          </Card> */}
          <Box>
            <Table>
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }} align="center">
                    Month
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Fare
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Collected On
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.paymentHistory?.map((month, index) => (
                  <TableRow>
                    {" "}
                    <TableCell
                      key={index}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {month.date.name}({month.date.year})
                    </TableCell>
                    <TableCell align="center">{routeDetails?.fee}</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      {month.isPaid ? (
                        <DownloadIcon />
                      ) : (
                        <Button variant="outlined" onClick={handleOpen}>
                          {" "}
                          Collect
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card
            sx={{ padding: "10px", marginTop: "10px", marginBottom: "10px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl required fullWidth size="small">
                  <InputLabel>Class</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="className"
                    size="small"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl required fullWidth size="small">
                  <InputLabel>Section</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                  >
                    {formData.className && (
                      <MenuItem value="all">--all--</MenuItem>
                    )}
                    {sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate || ""}
                      onChange={setFromDate}
                      renderInput={(params) => (
                        <TextField fullWidth size="small" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD-MM-YYYY"
                      value={toDate || ""}
                      onChange={setToDate}
                      renderInput={(params) => (
                        <TextField fullWidth size="small" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sx={{ mt: "5px" }}>
                <Button
                  onClick={handleClickFindOverViewButton}
                  variant="contained"
                  size="small"
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Box>
            <Table>
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Student Name
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Class
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Section
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Amount
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Collected Date
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {overViewList.map((o, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">
                      {o.studentDetails.name}
                    </TableCell>
                    <TableCell align="center">
                      {o.studentDetails.classNumber}
                    </TableCell>
                    <TableCell align="center">
                      {o.studentDetails.section}
                    </TableCell>
                    <TableCell align="center">{o.payableAmount}</TableCell>
                    <TableCell align="center">
                      {dayjs(new Date(o.createdAt)).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => handleClickDownload(o._id)}
                        size="small"
                        variant="outlined"
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TabPanel>
      </Box>

      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Collect Fee</DialogTitle>
        <DialogContent>
          <div className={css.dialogContainer}>
            <div>Amount Payable:- </div>
          </div>
          <div className={css.formFlex}>
            <FormControl required={true}>
              <FormLabel>Select payment method</FormLabel>
              <RadioGroup
                row
                value={collect.payment || ""}
                name="payment"
                onChange={handleFormChange}
              >
                <FormControlLabel
                  value="Cash"
                  control={<Radio />}
                  label="CASH"
                />
                <FormControlLabel
                  value="Cheque"
                  control={<Radio />}
                  label="Cheque"
                />
                <FormControlLabel value="DD" control={<Radio />} label="DD" />
                <FormControlLabel value="Upi" control={<Radio />} label="UPI" />
                <FormControlLabel
                  value="Card"
                  control={<Radio />}
                  label="Card"
                />
                <FormControlLabel
                  value="Netbanking"
                  control={<Radio />}
                  label="Netbanking"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Note"
              multiline
              rows={3}
              name="note"
              value=""
              size="small"
              fullWidth
              onChange={handleFormChange}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disabled={true}
                label="Date/Time"
                value={addDate}
                onChange={(newDate) => setAddDate(newDate)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
            {formData.payment === "Cheque" && (
              <>
                <TextField
                  size="small"
                  label="Bank Name"
                  value=""
                  fullWidth
                  name="bankName"
                  onChange={handlePaymentDetails}
                />
                <TextField
                  size="small"
                  label="Branch Name"
                  value=""
                  fullWidth
                  name="branchName"
                  onChange={handlePaymentDetails}
                />
                <TextField
                  size="small"
                  label="Cheque Number"
                  value=""
                  fullWidth
                  name="chequeNumber"
                  onChange={handlePaymentDetails}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Cheque Date"
                    value={paymentDetails.chequeDate || ""}
                    onChange={(newDate) =>
                      handlePaymentDetails({
                        target: {
                          name: "chequeDate",
                          value: newDate,
                        },
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
            {formData.payment === "DD" && (
              <>
                <TextField
                  size="small"
                  label="Bank Name"
                  value={paymentDetails.bankName || ""}
                  fullWidth
                  name="bankName"
                  onChange={handlePaymentDetails}
                />
                <TextField
                  size="small"
                  label="Branch Name"
                  value={paymentDetails.branchName || ""}
                  fullWidth
                  name="branchName"
                  onChange={handlePaymentDetails}
                />
              </>
            )}
            {formData.payment === "Upi" && (
              <>
                <TextField
                  size="small"
                  label="Bank Name"
                  value=""
                  fullWidth
                  name="bankName"
                  onChange={handlePaymentDetails}
                />
                <TextField
                  size="small"
                  label="Ref Number"
                  value={paymentDetails.refNumber || ""}
                  fullWidth
                  name="refNumber"
                  onChange={handlePaymentDetails}
                />
                <TextField
                  size="small"
                  label="Paid By"
                  value=""
                  fullWidth
                  name="paidByName"
                  onChange={handlePaymentDetails}
                />
              </>
            )}
            {formData.payment === "Card" && (
              <>
                <TextField
                  size="small"
                  label="Bank Name"
                  value=""
                  fullWidth
                  name="bankName"
                  onChange={handlePaymentDetails}
                />
                <TextField
                  size="small"
                  label="Card Type"
                  value=""
                  fullWidth
                  name="cardType"
                  onChange={handlePaymentDetails}
                />
              </>
            )}
            {formData.payment === "Netbanking" && (
              <>
                <TextField
                  size="small"
                  label="Bank Name"
                  value=""
                  fullWidth
                  name="bankName"
                  onChange={handlePaymentDetails}
                />
                <TextField
                  size="small"
                  label="Branch Name"
                  value={paymentDetails.branchName || ""}
                  fullWidth
                  name="branchName"
                  onChange={handlePaymentDetails}
                />
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            variant="contained"
            color="warning"
          >
            cancel
          </Button>
          <Button type="submit" variant="contained">
            collect
          </Button>
          <Button variant="contained">preview</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TransportFee;
