import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Person } from "@mui/icons-material";
import css from "./styles/Topnav.module.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { Divider, Grid, styled } from "@mui/material";
import SettingContext from "../context/SettingsContext";
import { useNavigate } from "react-router-dom";

const SubGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MainGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.87)",
  zIndex: 10,
  position: "absolute",
  padding: "10px",
  [theme.breakpoints.down(900)]: {
    gap: "10px",
  },
}));

const TopNav = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { settings, selectedSetting, setSelectedSetting } =
    React.useContext(SettingContext);

  const [selectedOption, setSelectedOption] = React.useState(
    selectedSetting ? selectedSetting.schoolName : "ABC School"
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    setSelectedSetting(option);
    handleClose();
  };

  return (
    <>
      <MainGrid container>
        <SubGrid item xs={12} md={6} lg={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              marginLeft: "4%",
            }}
          >
            <Box
              sx={{
                borderRight: " 0.5px solid white",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MailOutlineIcon
                fontSize="12px"
                sx={{ ml: 1, mr: 1 }}
                className={css.secHalf}
              />

              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  fontFamily: "monospace",
                  fontWeight: 10,
                  fontSize: 15,
                  color: "white",
                  textDecoration: "none",
                  mr: 2,
                }}
                className={css.firstHalf}
              >
                {selectedSetting ? selectedSetting.email : "abc@gmail.com"}
              </Typography>
            </Box>

            <Divider flexItem orientation="vertical" />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PhoneIcon
                color="white"
                fontSize="12px"
                sx={{ ml: 1, mr: 1 }}
                className={css.secHalf}
              />

              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  fontFamily: "monospace",
                  fontWeight: 10,
                  fontSize: 15,
                  color: "white",
                  textDecoration: "none",
                }}
                className={css.firstHalf}
              >
                +91 {selectedSetting ? selectedSetting.phone : 9999999999}
              </Typography>
            </Box>
          </Box>
        </SubGrid>

        <SubGrid item xs={12} md={6} lg={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              marginRight: "4%",
              gap: "20px",
            }}
          >
            <Box sx={{ marginRight: "20px" }}>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="contained"
                sx={{ color: "" }}
                size="medium"
              >
                {selectedSetting.schoolName}
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                sx={{ marginTop: "3%" }}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {settings.map((s) => {
                  return (
                    <MenuItem key={s._id} onClick={() => handleOptionSelect(s)}>
                      {s.schoolName}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
            <Box>
              <Button
                startIcon={<Person sx={{ color: "#ffff" }} />}
                onClick={() =>
                  navigate(selectedSetting ? "/dashboard" : "/login")
                }
                variant="contained"
                sx={{
                  color: "white",
                  background: "#F86F03",
                  "&:hover": { backgroundColor: "#F86F03" },
                }}
              >
                Login
              </Button>
            </Box>
          </Box>
        </SubGrid>
      </MainGrid>
    </>
  );
};

export default TopNav;
