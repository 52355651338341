import React from "react";
import { Box, Typography, Link } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { FaUserTimes } from "react-icons/fa";

const AccountSuspended = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        backgroundColor: "#f7f7f7",
        height: "100vh",
        // display: "flex",
        // justifyContent: "center",
        // flexDirection: "column",
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontWeight: "bold", fontSize: "4rem", color: "#CCCCCC" }}
      >
        <FaUserTimes fontSize="8rem" /> Account Suspended
      </Typography>
      <Box
        sx={{
          backgroundColor: "#2d3e50",
          color: "#CCCCCC",
          borderRadius: 1,
          padding: 4,
          textAlign: "center",
          width: "100%",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <WarningAmberIcon
          fontSize="inherit"
          sx={{
            fontSize: "6rem",
            marginBottom: 2,
          }}
        />
        <Box>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
            This account has been suspended.
          </Typography>

          <Typography variant="body1">
            <Link
              href="#"
              underline="hover"
              sx={{ color: "#CCCCCC", textDecoration: "underline" }}
            >
              Contact your hosting provider
            </Link>{" "}
            for more information.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountSuspended;
