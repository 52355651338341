import { Box, Typography, styled } from "@mui/material";
import React from "react";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledInfo = styled(Typography)(() => ({}));

export default function NoContent({ info = "No content" }) {
  return (
    <StyledBox>
      <StyledInfo>{info}</StyledInfo>
    </StyledBox>
  );
}
