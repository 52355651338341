import { instance } from "./axios.Config";

export const get = async (url, params) => {
  const res = await instance.get(url, params);
  return res;
};

export const post = async (url, payload) => {
  const res = await instance.post(url, payload);
  return res;
};

export const del = async (url, payload,data) => {
  const res = await instance.delete(url + payload, {
    params:{
      data
    }
  });
  return res;
};

export const put = async (url, payload="", data) => {
  const res = await instance.put(url + payload, data);
  return res;
};
