import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Container,
  Dialog,
  Fab,
  styled,
  TextareaAutosize,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { get, post } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
const styles = {
  textfield: {
    // width: { md: 227, xs: "100%" },
    label: { fontSize: 12 },
    // m: 1,
  },
};
const ContainerBox = styled(Box)(({ theme }) => ({
  // position: "absolute",
  top: "30%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "50%",
  // maxWidth: "650px",
  // maxHeight:"650px",
  borderRadius: "5px",
  // height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  // [theme.breakpoints.down("md")]: {
  //   overflow: "auto",
  //   width: "90%",
  // },
  // [theme.breakpoints.between(819, 821)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  // [theme.breakpoints.between(767, 769)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  // [theme.breakpoints.between(911, 913)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  // [theme.breakpoints.between(1023, 1025)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  // [theme.breakpoints.between(1279, 1281)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddEvent = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { url } = props;
  const [fromDate, setFromDate] = useState(window.Date.now());
  const [toDate, setToDate] = useState(window.Date.now());

  const [eventDetails, setEventDetails] = useState({});
  const [roles, setRoles] = useState([]);
  const [imageEvent, setImageEvent] = useState(null);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEventDetails({ ...eventDetails, [name]: value });
  };
  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(url.role.getAllRole);
        setRoles(res.data.data);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);

  useEffect(() => {
    if (props.updateEvent) {
      setEventDetails(props.updateEvent);
      setFromDate(props.updateEvent.fromDate);
      setToDate(props.updateEvent.toDate);
    }
  }, [props.updateEvent]);

  const handleSubmit = async (e) => {
    console.log(fromDate, toDate, "timing");
    e.preventDefault();

    const fileForm = new FormData();
    let img = undefined;
    if (imageEvent) {
      fileForm.append("files", imageEvent);
      img = await post(url.fileUpload.postFileUpload, fileForm);
    }
    const data = {
      eventTitle: eventDetails.eventTitle,
      eventFor: eventDetails.eventFor,
      location: eventDetails.location,
      fromDate: new window.Date(fromDate),
      toDate: new window.Date(toDate),
      status: eventDetails.status,
      hostedBy: eventDetails.hostedBy,
      note: eventDetails.note,
      shortEvent: eventDetails.shortEvent,
      video: eventDetails.video,
      webView: eventDetails.webView,
    };
    if (imageEvent) {
      data.image = img.data.result[0]._id;
    }
    if (props.updateEvent) {
      await props.handleUpdate(data, props.updateEvent._id);
    } else {
      await props.handleSubmit(e, data);
    }
    setEventDetails("");
    setFromDate("");
    setToDate("");
    setImageEvent(null);
    props.getEvents();
    props.handleClose();
  };

  const handleCloseModal = () => {
    setEventDetails("");
    setFromDate("");
    setToDate("");
    setImageEvent(null);
    props.handleClose();
  };
  const handleClose = () => {
    setEventDetails("");
    setFromDate("");
    setToDate("");
    setImageEvent(null);
    props.handleClose();
  };
  return (
    <Box>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Add />
      </Fab>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            overflowY: "auto",
          }}
        > */}
        <Box sx={style}>
          <form className="form-tag" onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Event
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Event Title"
                  name="eventTitle"
                  value={eventDetails.eventTitle || ""}
                  onChange={(event) => handleOnChange(event)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    EventFor
                  </InputLabel>
                  <Select
                    label="Event For"
                    required
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={eventDetails.eventFor || ""}
                    // defaultValue={props.employee && props.employee.role}
                    name="eventFor"
                    onChange={(event) => handleOnChange(event)}
                  >
                    <MenuItem
                      key={"all"}
                      value={"all"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      All
                    </MenuItem>
                    {roles &&
                      roles.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row.roleName}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.roleName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="location"
                  name="location"
                  value={eventDetails.location || ""}
                  onChange={(event) => handleOnChange(event)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    name="fromDate"
                    inputFormat="DD-MM-YYYY"
                    value={fromDate}
                    onChange={(newValue) => {
                      {
                        setFromDate(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" required {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To Date"
                    name="toDate"
                    inputFormat="DD-MM-YYYY"
                    value={toDate}
                    onChange={(newValue) => {
                      {
                        setToDate(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" required {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  name="hostedBy"
                  size="small"
                  value={eventDetails.hostedBy}
                  onChange={(event) => handleOnChange(event)}
                  label="Hosted By"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <>
                  <TextField
                    fullWidth
                    id="filled-required"
                    name="video"
                    size="small"
                    value={eventDetails.video}
                    onChange={(event) => handleOnChange(event)}
                    label="video"
                    placeholder="only embedded video"
                  />
                </>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  component="label"
                  sx={{
                    textTransform: "none",
                    color: "grey",
                    border: "1px solid grey",
                  }}
                >
                  Upload image
                  <input
                    onChange={(e) => setImageEvent(e.target.files[0])}
                    type="file"
                    accept="image/*"
                    hidden
                  />
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "flex-end",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                alignItems="center"
              >
                <span
                  style={{
                    marginLeft: "10xp",
                  }}
                >
                  {imageEvent && imageEvent.name}
                </span>
                {!imageEvent && eventDetails.image && (
                  <img
                    style={{ marginLeft: 10 }}
                    src={`${eventDetails.image.link}`}
                    alt=""
                    height={50}
                    width={50}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={12}>
                <FormControl fullWidth size="small" sx={styles.textfield}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Is View on Web?
                  </InputLabel>
                  <Select
                    label="Is view On Web"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="webView"
                    value={eventDetails.webView || ""}
                    onChange={(event) => handleOnChange(event)}
                  >
                    <MenuItem
                      value={"yes"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      value={"no"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      no
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <>
                  <TextareaAutosize
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                    required
                    name="shortEvent"
                    value={eventDetails.shortEvent || ""}
                    placeholder="Short event *"
                    onChange={(event) => handleOnChange(event)}
                  />
                </>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <>
                  <TextareaAutosize
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                    name="note"
                    value={eventDetails.note || ""}
                    placeholder="Note"
                    onChange={(event) => handleOnChange(event)}
                  />
                </>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", xs: "center" },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="medium"
                    type="button"
                    color="error"
                    sx={{
                      m: 1,
                      color: "#fff",
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={props.loader}
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{ background: "rgb(27, 55, 121)" }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
        {/* </Container> */}
      </Dialog>
    </Box>
  );
};

export default AddEvent;
