import React from 'react'

import { cards } from './SchoolCard'
import { useParams,Link, useLocation } from 'react-router-dom'
import CustomEventDetails from './CustomEventDetails'
import { Box, Typography, styled } from '@mui/material';
import NavBar from '../navbar/Navbar';
import Footer from '../Footer/Footer';


const BackGround = styled(Box)(({ theme }) => ({
    backgroundImage: "url(/LatestEvents.png)",
    backgroundRepeat: "noRepeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    height: "30vh",
    width: "100%",
  
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }));
  
  const Heading = styled(Typography)(({ theme }) => ({
    color: "#FFFFFF",
    fontSize: "2rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  }));
  
  const Heading1 = styled(Typography)(({ theme }) => ({
    color: "#FFFFFF",
    fontSize: "1rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  }));
  
  const Headinglink = styled(Link)(({ theme }) => ({
    color: "#FFFFFF",
    fontSize: "1rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    textDecoration: "none",
  }));

  const OverViewTitleContainer = styled(Box)(({ theme }) => ({
    color: "white",
    padding: "80px",
    backgroundImage:
      "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  }));
  
  const OverViewTitle = styled(Typography)(({ theme }) => ({
    fontSize: "40px",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  }));
  

const EventDetails = () => {
    let {id}=useParams()
    console.log(id);

    const {state}=useLocation()
    console.log("hello",state);

    // let data=cards.find((item)=>item.id==id)
    // console.log(data);
  return (
    <Box>

        <NavBar/>
        <OverViewTitleContainer variant="h4" align="center">
          <OverViewTitle>EVENTS</OverViewTitle>
          <Typography sx={{ color: "white" }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              HOME
            </Link>
            &nbsp; / EVENTS
          </Typography>

        </OverViewTitleContainer>
          <CustomEventDetails data={state}/>
          <Footer/>
    </Box>
  )
}

export default EventDetails