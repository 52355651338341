import React, { useRef } from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import {
  Assignment,
  Collections,
  Dashboard,
  Event,
  ExpandLess,
  ExpandMore,
  Logout,
  NotificationsActive,
  Public,
  SettingsSuggest,
  StarBorder,
  SubdirectoryArrowRight,
} from "@mui/icons-material";
import dataSidebar from "./components/Navigation/components/DataSidebar";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { urls as url } from "./services/urlConstant";
import { get } from "./services/apis";
import { objHas, subMenuPermissionChecker } from "./utils/utils";

import styles from "./NavBar.module.css";
import { toast } from "react-toastify";
import { Avatar, Box, Button, Menu, MenuItem, Stack } from "@mui/material";
import SettingContext from "./context/SettingsContext";
import { TableDrawer } from "./context/DrawerComponent";
import appendStringToUrls from "./utils/appendString";
import DrawerWidthContext from "./context/DrawerWidthContext";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const itemIndexes = {
  dashboard: 100,
  assignment: 200,
  gallery: 300,
  notifications: 400,
  event: 500,
};

const NavBar = (props) => {
  const theme = useTheme();
  let userType = window.localStorage.getItem("userType");

  const location = useLocation();

  const [selectedIndex, setSelectedIndex] = useState(100);
  const [storageData, setStorageData] = useState({});
  const [open, setOpen] = useState(true);
  const [menuOpen, setMenuOpen] = useState("");
  const [settings, setSettings] = useState(false);
  const [activeYear, setActiveYear] = useState("");
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [login, setLogin] = useState(false);
  const [profileLink, setProfileLink] = useState("");
  const [profileId, setProfileId] = useState("");
  const [sideData, setSideDate] = useState(...dataSidebar);
  const { selectedSetting } = useContext(SettingContext);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const navigate = useNavigate();
  const { newsId } = useParams();
  const { eventId } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // let handleColor = () => {
  //   setColor(!color);
  // };

  let handleClicked = () => {
    setSelectedIndex(100);
  };
  let handleClicked1 = () => {
    setSelectedIndex(200);
  };
  let handleClicked2 = () => {
    setSelectedIndex(300);
  };
  let handleClicked3 = () => {
    setSelectedIndex(400);
  };
  let handleClicked4 = () => {
    setSelectedIndex(500);
  };

  let { setDrawer } = useContext(TableDrawer);

  useEffect(() => {
    setSideDate([]);
    // setData([]);
  }, []);

  useEffect(() => {
    function setUserRole() {
      const storageData = localStorage.getItem("role");

      if (storageData) {
        setStorageData(JSON.parse(storageData));
      }
    }
    setUserRole();
    window.addEventListener("storage", setUserRole);

    return () => {
      window.removeEventListener("storage", setUserRole);
    };
  }, []);

  useEffect(() => {
    const profileLink = window.localStorage.getItem("empPhoto");
    setProfileLink(profileLink);
    const profileId = window.localStorage.getItem("profileId");
    setProfileId(profileId);
  }, []);

  const getActiveAcademicYear = async () => {
    try {
      const { data } = await get(urls.administrator.academicYearGetAll, {
        params: { search: { status: "active" } },
      });
      setActiveYear(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActiveAcademicYear();
  }, [selectedSetting]);

  useEffect(() => {
    console.log(newsId);
    const { pathname } = location;
    const excludePath = [
      "/home-assignment",
      "/home-notice",
      `/home-news/${newsId}`,
      "/home-news",
      "/home-careers",
      "/to-the-knowledge-of-parents",
      "/unique-features",
      "/home-gallery",
      "/results",
      "/contact-us",
      "/facilities/edusmart",
      "/facilities/transport",
      "/",
      "/login",
      "/forgot-password",
      "/principal",
      "/desk",
      "/facality",
      "/addmission",
      "/our-school",
      "/academics",
      "/academics/primary",
      "/academics/high-school",
      "/facilities/library",
      "/facilities/sports",
      "/facilities/canteen",
      "/about/founder",
      "/about/visionandmission",
      "/about/overview",
      "/facilities/dance-and-singing",
      `/eventdetails/${newsId}`,
      `/courseDetails/${newsId}`,
      "/unique-features",
      "/to-the-knowledge-of-parents",
    ];
    const exclude =
      excludePath.includes(pathname) ||
      pathname.startsWith("/home-news") ||
      pathname.startsWith("/home-notice") ||
      pathname.startsWith("/view-assignment") ||
      pathname.startsWith("/awards-home") ||
      pathname.startsWith("/events-home") ||
      pathname.startsWith("/eventdetails") ||
      pathname.startsWith("/courseDetails") ||
      pathname.startsWith("/facilities/labs") ||
      pathname.startsWith("/toppers-list") ||
      pathname.startsWith("/ourachievement/") ||
      pathname.startsWith("/details/");
    setIsLandingPage(exclude);
  }, [location.pathname]);

  const handleSubClick = (index) => {
    setOpen(true);
    setDrawer(true);
    setSelectedIndex(index);
    if (menuOpen === index) {
      setMenuOpen("");
    } else {
      setMenuOpen(index);
    }
  };

  const handleDrawerOpen = () => {
    // setDrawerOpen(true)
    setOpen(true);
    setDrawer(true);
  };

  const handleDrawerClose = () => {
    setMenuOpen("");
    setOpen(false);
    setDrawer(false);
  };
  const handleLogout = async () => {
    try {
      const logout = await get(`/${selectedSetting._id}/logout`);
      if (logout.data.success) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("role");
        navigate("/login");
        toast.success("Logout Successful");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const { pathname } = useLocation();

  const items = [
    {
      name: "Dashboard",
      path: "/dashboard",
      renderName: ["Dashboard"],

      icon: <SettingsSuggest sx={{ color: "#47a093" }} />,
    },
    {
      name: "Assignment",
      path: "/assignment",
      renderName: ["Assignment"],

      icon: <SettingsSuggest sx={{ color: "#47a093" }} />,
    },
    {
      name: "Gallery",
      path: "/gallery",
      renderName: ["Gallery"],

      icon: <SettingsSuggest sx={{ color: "#47a093" }} />,
    },
    {
      name: "Notifications",
      path: "/notifications",
      renderName: ["Notifications"],

      icon: <SettingsSuggest sx={{ color: "#47a093" }} />,
    },
    {
      name: "Events",
      path: "/events",
      renderName: ["Events"],

      icon: <SettingsSuggest sx={{ color: "#47a093" }} />,
    },
  ];

  const canShowMenu = (func) => {
    const value = storageData[func] ? storageData[func]["view"] : true;

    return value ? true : false;
  };

  let values = [...dataSidebar, ...items];
  return (
    <>
      {isLandingPage ? null : (
        <>
          <AppBar position="fixed" open={userType === "employee" && open}>
            <Toolbar sx={{ background: "#ffffff", color: "forestgreen" }}>
              <div className={styles.toolBarContainer}>
                {userType === "employee" ? (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      ...(open &&
                        userType === "employee" && { visibility: "hidden" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                ) : null}
                <div className={styles.barHeader}>
                  <Typography variant="h6" noWrap component="div">
                    {selectedSetting.schoolName} [
                    {activeYear ? activeYear.academicYearFrom : "-"} -{" "}
                    {activeYear ? activeYear.academicYearTo : "-"}]
                  </Typography>
                </div>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    id="basic-button"
                    aria-controls={open2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Avatar />
                  </Button>
                  <Link
                    style={{ color: "inherit", alignSelf: "center" }}
                    to="/"
                  >
                    <IconButton color="inherit">
                      <Public />
                    </IconButton>
                  </Link>
                </Stack>
              </div>
            </Toolbar>
            <div>
              <Menu
                id="basic-menu"
                sx={{ width: 227 }}
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <Link to="/profile">
                    <p style={{ color: "black" }}>Profile</p>
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/resetPassword">
                    <p style={{ color: "black" }}>Reset Password</p>
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemText> Log Out</ListItemText>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </div>
          </AppBar>
          {userType === "employee" ? (
            <Drawer variant="permanent" open={open}>
              <DrawerHeader style={{ gap: "45px", overflow: "hidden" }}>
                {selectedSetting && (
                  <img
                    src={selectedSetting.logo ? selectedSetting.logo.link : ""}
                    alt="logo"
                    style={{
                      height: "90%",
                      objectFit: "contain",
                      width: "auto",
                      maxWidth: "100px",
                    }}
                  />
                )}
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <Link
                    to={"/dashboard"}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <ListItemButton
                      onClick={handleClicked}
                      // onDoubleClick={handlesub}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        mr: !open && 3,
                        mb: 0.3,
                        backgroundColor:
                          selectedIndex === 100 ? "#2196f3" : "white",

                        "&:hover": {
                          backgroundColor: "#2196f3",
                          color: "white",
                        },
                        // backgroundColor: color === true ? "orangered" : "",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "#324a5f",
                        }}
                      >
                        <Dashboard />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Dashboard"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                {dataSidebar.map((menu, index) => {
                  const { renderName } = menu;
                  const value = subMenuPermissionChecker(
                    renderName,
                    storageData
                  )
                    ? true
                    : false;

                  return !value ? null : (
                    <ListItem
                      key={index}
                      className="side-item"
                      disablePadding
                      sx={{
                        display: "block",
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleSubClick(index)}
                        className="selected"
                        // activeClassName="selected"
                        //in
                        //in={menuOpen.includes(index) ? "v" : undefined}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,

                          backgroundColor:
                            selectedIndex === index ? "#2196f3" : "white",

                          "&:hover": {
                            // backgroundColor: "#a43fb5",
                            backgroundColor: "#2196f3",
                            color: "white",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {menu.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={menu.name}
                          sx={{ opacity: open ? 1 : 0 }}
                        />

                        {menuOpen === index ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>

                      {menu.subMenu && (
                        <Collapse
                          in={menuOpen === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {menu.subMenu.map((ele, i) => {
                              const { renderName } = ele;
                              const value = storageData[renderName]
                                ? storageData[renderName]["view"]
                                : true;
                              return (
                                value && (
                                  <NavLink
                                    style={({ isActive }) =>
                                      isActive
                                        ? {
                                            backgroundColor: "black",
                                            color: "white",
                                            ":hover": { background: "black" },
                                          }
                                        : {
                                            color: "white",
                                            backgroundColor: "#1b3779",
                                          }
                                    }
                                    key={i}
                                    to={ele.path}
                                  >
                                    <ListItemButton
                                      sx={{
                                        pl: 4,
                                        backgroundColor: "inherit",
                                        transition: "0.3s",
                                        color: "inherit",
                                        ":hover": {
                                          background: "black",
                                          color: "white",
                                        },
                                      }}
                                    >
                                      <ListItemIcon sx={{ color: "inherit" }}>
                                        <SubdirectoryArrowRight
                                          sx={{ color: "inherit" }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        style={{ whiteSpace: "pre-line" }}
                                        primary={ele.name}
                                      />
                                    </ListItemButton>
                                  </NavLink>
                                )
                              );
                            })}
                          </List>
                        </Collapse>
                      )}
                    </ListItem>
                  );
                })}

                {canShowMenu("assignment") ? (
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    className="side-item"
                  >
                    <Link
                      to={"/assignment"}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <ListItemButton
                        onClick={handleClicked1}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          mr: !open && 3,
                          backgroundColor:
                            selectedIndex === 200 ? "#2196f3" : "white",

                          "&:hover": {
                            backgroundColor: "#2196f3",
                            color: "white",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#c81d25",
                          }}
                        >
                          <Assignment />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Assignment"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ) : null}

                {canShowMenu("gallery") ? (
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    className="side-item"
                  >
                    <Link
                      to={"/gallery"}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <ListItemButton
                        onClick={handleClicked2}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          mr: !open && 3,
                          backgroundColor:
                            selectedIndex === 300 ? "#2196f3" : "white",

                          "&:hover": {
                            backgroundColor: "#2196f3",
                            color: "white",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#70d6ff",
                          }}
                        >
                          <Collections />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Gallery"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ) : null}

                {canShowMenu("sms") ? (
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    className="side-item"
                  >
                    <Link
                      to={"/text-message"}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <ListItemButton
                        onClick={handleClicked3}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          mr: !open && 3,
                          backgroundColor:
                            selectedIndex === 400 ? "#2196f3" : "white",

                          "&:hover": {
                            backgroundColor: "#2196f3",
                            color: "white",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#fb6107",
                          }}
                        >
                          <NotificationsActive />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Notifications"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ) : null}

                {canShowMenu("event") ? (
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    className="side-item"
                  >
                    <Link
                      to={"/event"}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <ListItemButton
                        onClick={handleClicked4}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          mr: !open && 3,
                          backgroundColor:
                            selectedIndex === 500 ? "#2196f3" : "white",

                          "&:hover": {
                            backgroundColor: "#2196f3",
                            color: "white",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#f72585",
                          }}
                        >
                          <Event />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Event"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ) : null}
              </List>
              <Divider />
            </Drawer>
          ) : null}
        </>
      )}
    </>
  );
};

export default NavBar;
