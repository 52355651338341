import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import NavBar from "../navbar/Navbar";
import Footer from "../Footer/Footer";
import TopNav from "../navbar/TopNav";
// import foodImg from "../../assets/food.gif"
//
const FoodTitleContainer = styled(Box)(({ theme }) => ({
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const FoodTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  padding: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 15px",
  },
}));

const VisionMission = () => {
  const Missionvalues = [
    "To advocate for value-based education.",
    "To make a positive impact on society by practising social responsibility and consciousness.",
    "To promote societal harmony with regard to fundamental human rights.",
    "To encourage unity between cultures and religions and care for the human family.",
    "To encourage student leadership and give them the tools they need to effectively participate in social emancipation.",
    "To deliver a high-quality, comprehensive, career-focused education and to foster an environment that supports both academic and personal achievement.",
    "To promote family, connectivity, and eco-justice while working to preserve the integrity of creation.",
  ];

  return (
    <React.Fragment>
      <TopNav/>
      <NavBar/>
      <FoodTitleContainer variant="h4" align="center">
        <FoodTitle>VISSION AND MISSION</FoodTitle>
        <Typography sx={{ color: "white" }}>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            Home
          </Link>
          &nbsp; / About
        </Typography>
      </FoodTitleContainer>

      <ContentContainer>
        <Box sx={{ padding: "10px 0" }}>
          {/* <Typography sx={{ fontWeight: 500 }}>
            At Kayaka, we understand the importance of providing students with
            nutritious meals to support their overall well-being and academic
            success. We are committed to ensuring that no child goes hungry and
            offer a free food facility to eligible students. Here's everything
            you need to know:
          </Typography> */}
        </Box>
        <Grid container>
          <Grid item md={8} sm={12}>
            <Box>
              <Typography variant="h6" gutterBottom sx={{ color: "#F86F03" }}>
                Our Vission:
              </Typography>
              <Typography component="ul">
                <Typography component="li" gutterBottom>
                  <b>Kayaka Foundation,</b>&nbsp;Gulbarga proposes a
                  life-oriented education that equips students to be agents of
                  transformation and growth at various levels of life through a
                  humanising and liberative process.
                </Typography>
                <Typography component="li">
                  Enabled and empowered, they actively address issues and
                  worries that are part of today's reality, particularly those
                  of students and people who are unable to exercise their right
                  to human freedom, and they fight for the integrity of the
                  natural world.
                </Typography>
                <Typography component="li">
                  The focus is on a knowledge-based civilisation that the
                  founder, Sri. Shivaraj Patil, foresaw and encouraged.
                </Typography>
                <br />
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" gutterBottom sx={{ color: "#F86F03" }}>
                Our Mission:
              </Typography>
              <ul>
                {Missionvalues.map((data, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Typography component="li" gutterBottom>
                        {data}
                      </Typography>
                    </React.Fragment>
                  );
                })}
              </ul>
              {/* <Typography component="ul">
              <Typography component="li" gutterBottom>
              To advocate for value-based education.
              </Typography>
              <Typography component="li">
                Information on the schedule and timings for breakfast, lunch,
                and any additional meal programs, including summer meal programs
                if applicable.
              </Typography>
              <br />
            </Typography> */}
            </Box>
            {/* <Box>
            <Typography variant="h6" gutterBottom sx={{ color: "#F86F03" }}>
              Allergies and Dietary Restrictions:
            </Typography>
            <Typography component="ul">
              <Typography component="li" gutterBottom>
                Instructions for parents to communicate any specific allergies
                or dietary restrictions their child may have.
              </Typography>
              <Typography component="li">
                Information on how the school accommodates and addresses these
                requirements to ensure the safety and well-being of all
                students.
              </Typography>
              <br />
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" gutterBottom sx={{ color: "#F86F03" }}>
              Food Safety and Quality:
            </Typography>
            <Typography component="ul">
              <Typography component="li" gutterBottom>
                Assurance of adherence to strict food safety and hygiene
                standards in meal preparation and distribution.
              </Typography>
              <Typography component="li">
                Overview of any certifications or partnerships with local health
                authorities to ensure the highest quality of food service.
              </Typography>
              <br />
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ color: "#F86F03" }}>
              Additional Support:
            </Typography>
            <Typography component="ul">
              <Typography component="li" gutterBottom>
                Information on any supplementary programs or resources available
                to students and families, such as nutrition education workshops,
                community referrals, or partnerships with local food banks or
                organizations.
              </Typography>
            </Typography>
          </Box> */}
          </Grid>
          <Grid item lg={4} md={4} sm={12} padding="10px">
            {/* <img src={foodImg} alt="" style={{maxWidth:"100%"}}/> */}
          </Grid>
        </Grid>
        {/* <Box sx={{ padding: "10px 0" }}>
          <Typography sx={{ fontWeight: 500 }}>
            We believe that access to healthy meals is fundamental to a
            student's ability to learn and thrive. If you have any questions
            regarding our free food facility or need assistance with the
            application process, please don't hesitate to contact our school
            administration. We are committed to ensuring that every child
            receives the nourishment they need to reach their full potential.
          </Typography>
        </Box> */}
      </ContentContainer>
      <Footer/>
    </React.Fragment>
  );
};

export default VisionMission;
