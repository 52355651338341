import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { Add } from "@mui/icons-material";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import { useState, useEffect, useContext } from "react";
import { urls as url } from "../../../services/urlConstant";
import { get, post, put, del } from "../../../services/apis";

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddSplashNews = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const [editFlash, setEditFlash] = useState();
  const [addForm, setAddForm] = useState({});
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showTextInput, setShowTextInput] = useState(false);
  useEffect(() => {
    if (props.editData) {
      setAddForm(props.editData);
      setEditFlash(props.editData._id);
    } else {
      setAddForm({});
      setEditFlash(null);
    }
  }, [props.editData]);

  const handleCloseModal = () => {
    props.handleClose();
    resetForm();
  };
  const handleClose = () => {
    props.handleClose();
    resetForm();
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "contentType") {
      setShowUploadButton(
        value === "Image" || value === "Video" || value === "Document"
      );
      setShowTextInput(value === "Text");
    }
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  const resetForm = () => {
    setAddForm({});
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editFlash) {
        const { status } = await post(
          `${urls.announceFlashNews.postFlashNews}`,
          {
            title: addForm.title,
            link: addForm.link,
            webView: addForm.webView,
            type: addForm.type,
            contentType: addForm.contentType,
            link: addForm.link,
            image: addForm.image,
            video: addForm.video,
            document: addForm.document,
          }
        );
        props.getSplashNews();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.announceFlashNews.putFlashNews}${editFlash}`,
          "",
          {
            title: addForm.title,
            link: addForm.link,
            webView: addForm.webView,
            type: addForm.type,
            contentType: addForm.contentType,
            link: addForm.link,
            image: addForm.image,
            video: addForm.video,
            document: addForm.document,
          }
        );
        props.getSplashNews();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Tooltip title="Add">
          <Add />
        </Tooltip>
      </Fab>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <ContainerBox>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="20px"
              fontSize="20px"
              fontWeight="bold"
            >
              {editFlash ? "update" : " Add"}
            </Typography>
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label=" Splash News Title"
                    name="title"
                    value={addForm.title || ""}
                    onChange={handleInputChange}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Text"
                    name="text"
                    value={addForm.text || ""}
                    onChange={handleInputChange}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel sx={{ fontSize: 12 }}>Content Type</InputLabel>
                    <Select
                      value={addForm.contentType || ""}
                      name="contentType"
                      onChange={handleInputChange}>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Link"}>
                        Link
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Text"}>
                        Text
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Video"}>
                        Video
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Image"}>
                        Image
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Document"}>
                        Document
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel sx={{ fontSize: 12 }}>Type</InputLabel>
                    <Select
                      value={addForm.type || ""}
                      name="type"
                      onChange={handleInputChange}>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Popup"}>
                        Popup
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Horizontal"}>
                        Horizontal
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label="Link"
                    name="link"
                    value={addForm.link || ""}
                    onChange={handleInputChange}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 12 }}>
                      Is view on web?
                    </InputLabel>
                    <Select
                      label="Is View On Web"
                      value={addForm.webView || ""}
                      name="webView"
                      onChange={handleInputChange}
                    >
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"yes"}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"no"}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {showUploadButton && (
                  <Grid item xs={12} md={12} lg={12}>
                    <input
                      type="file"
                      name="fileUpload"
                      accept="image/*, video/*, .pdf, .doc, .docx"
                    />
                  </Grid>
                )}
                {showTextInput && (
                  <Grid item xs={12} md={12} lg={12}>
                    <TextareaAutosize
                      fullWidth
                      required
                      size="small"
                      label="Text Content"
                      style={{
                        width: "100%",
                        maxHeight: 50,
                        borderRadius: "5px",
                        padding: 10,
                        height: 70,
                        overflow: "auto",
                      }}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "right", xs: "center" },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        variant="contained"
                        size="medium"
                        type="button"
                        color="error"
                        sx={{ m: 1, color: "#fff" }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Submit
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </>
          </ContainerBox>
        </form>
      </Modal>
    </div>
  );
};

export default AddSplashNews;
