import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Avatar,
  InputAdornment,
  IconButton,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import { objHas } from "../../utils/utils";
import React, { useContext, useEffect, useState } from "react";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import { Delete, Search } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import DownloadIcon from "@mui/icons-material/Download";
import Wrapper from "../Wrapper";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const styles = {
  cardsMargin: {
    margin: "15px o",
  },
  cardStyle: {
    margin: "5px 0",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "650px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TransportMember = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState([]);
  const [addData, setAddData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [students, setStudents] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [nonStudents, setNonStudents] = useState([]);
  const [studentToAdd, setStudentToAdd] = useState("");
  const [mode, setMode] = useState("edit");
  const [routes, setRoutes] = useState([]);

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [nonSearchFilter, setNonSearchFilter] = useState([]);
  const [nonSearch, setNonSearch] = useState("");
  const [selectStudent, setSelectStudent] = useState(null);
  let [loader, setLoader] = useState(false);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    setRoutes([]);
    const getRoutes = async () => {
      try {
        const { data, status } = await get(urls.route.getList);
        setRoutes(data.data);
        console.log(data.data, "setRoutes");
      } catch (error) {
        console.log(error);
      }
    };
    getRoutes();
  }, [selectedSetting]);
  useEffect(() => {
    setClasses([]);
    setSections([]);
    setStudents([]);
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data.length ? res.data.data[0]._id : "",
          section: resSections.data.data.length
            ? resSections.data.data[0]._id
            : "",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      students.length > 0 &&
        setSearchFilter(
          students.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleNonSearch = (e) => {
    const { value } = e.target;
    setNonSearch(value.trim());
    if (value.trim() !== "") {
      nonStudents.length > 0 &&
        setNonSearchFilter(
          nonStudents.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setNonSearchFilter([]);
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleStudentSearchSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.className,
        // status: formData.status,
      };

      let searchParams = {
        params: {
          search: searchOptions,
          limit: rowsPerPage,
          page: page === 0 ? 1 : page + 1,
        },
      };

      if (formData.section !== "all") {
        searchOptions["academicInfo.section"] = formData.section;
      }

      const searchStudents = await get(
        urls.students.getAllStudent,
        searchParams
      );
      const sortedStudents = searchStudents.data.data.sort((a, b) => {
        const aIsHostelMember = a.otherInfo.transportMember === "yes";
        const bIsHostelMember = b.otherInfo.transportMember === "yes";
        if (aIsHostelMember && !bIsHostelMember) {
          return -1; // a comes first
        } else if (!aIsHostelMember && bIsHostelMember) {
          return 1; // b comes first
        } else {
          return 0;
        }
      });

      setStudents(sortedStudents);
      console.log(sortedStudents, "sorttttttttt");
      if (searchStudents.data.count > rowsPerPage) {
        setShowPagination(true);
      }
      setTotalCount(searchStudents.data.count);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleNonStudentSearchSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.class,
        // status: formData.status,
      };

      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchOptions,
            limit: rowsPerPage,
            page: page + 1,
          },
        });

        const filteredStudents = searchStudents.data.data.filter(
          (student) => student.otherInfo.transportMember === "no"
        );

        console.log(filteredStudents);

        setNonStudents(filteredStudents);
        if (searchStudents.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: {
              ...searchOptions,
              "academicInfo.section": formData.section,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        const filteredStudents = searchStudent.data.data.filter(
          (student) => student.otherInfo.transportMember === "no"
        );
        setNonStudents(filteredStudents);
        if (searchStudent.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnChange2 = (e) => {
    const { name, value } = e.target;
    setAddData({
      ...addData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteMember = async (e, id) => {
    e.preventDefault();

    try {
      const { status } = await put(
        `${urls.transportMember.deleteMember}/`,
        id,
        {}
      );
      getstude();
      getNonStu();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddTransportButtonClick = (id) => {
    console.log(id, "ihhhhh");
    setMode("create");
    setStudentToAdd(id);
    const student = students.find((student) => student._id === id);
    setSelectStudent(student ? student.basicInfo.name : null);
    setOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getNonStu();
    getstude();
  }, []);
  const getNonStu = async () => {
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.class,
        // status: formData.status,
      };

      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchOptions,
            limit: rowsPerPage,
            page: page + 1,
          },
        });

        const filteredStudents = searchStudents.data.data.filter(
          (student) => student.otherInfo.transportMember === "no"
        );

        console.log(filteredStudents);

        setNonStudents(filteredStudents);
        if (searchStudents.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: {
              ...searchOptions,
              "academicInfo.section": formData.section,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        const filteredStudents = searchStudent.data.data.filter(
          (student) => student.otherInfo.transportMember === "no"
        );
        setNonStudents(filteredStudents);
        if (searchStudent.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getstude = async () => {
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.className,
        // status: formData.status,
      };

      let searchParams = {
        params: {
          search: searchOptions,
          limit: rowsPerPage,
          page: page === 0 ? 1 : page + 1,
        },
      };

      if (formData.section !== "all") {
        searchOptions["academicInfo.section"] = formData.section;
      }

      const searchStudents = await get(
        urls.students.getAllStudent,
        searchParams
      );
      const sortedStudents = searchStudents.data.data.sort((a, b) => {
        const aIsHostelMember = a.otherInfo.transportMember === "yes";
        const bIsHostelMember = b.otherInfo.transportMember === "yes";
        if (aIsHostelMember && !bIsHostelMember) {
          return -1; // a comes first
        } else if (!aIsHostelMember && bIsHostelMember) {
          return 1; // b comes first
        } else {
          return 0;
        }
      });

      setStudents(sortedStudents);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();

    try {
      const { status } = await put(
        `${urls.transportMember.editMember}/`,
        studentToAdd,
        {
          transportRouteTitle: addData.route,
          vehicleNumber: addData.vehicle,
          stopName: addData.busStop,
          pickType: addData.pickType,
        }
      );
      getstude();
      getNonStu();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Wrapper>
      <div style={styles.cardsMargin}>
        <SectionsSubHeader title="Transport Member" />
        <Card style={styles.cardsMargin} sx={{ marginTop: 2 }}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Student List" {...a11yProps(0)} />
              <Tab label="Non Member Student" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Card>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form
            onSubmit={(e) => {
              handleAddSubmit(e);
            }}
          >
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontWeight="bold"
                    fontSize="20px"
                  >
                    {mode === "edit" ? "Update" : "Add"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h6"
                    textAlign="start"
                    fontWeight="bold"
                  >
                    {selectStudent}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Select Route</InputLabel>
                    <Select
                      label="Select Route"
                      id="demo-simple-select-filled"
                      name="route"
                      value={addData.route || ""}
                      variant="outlined"
                      onChange={handleOnChange2}
                      size="small"
                    >
                      {routes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.transportRouteTitle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Select Vehicle</InputLabel>
                    <Select
                      label="Select Vehicle"
                      id="demo-simple-select-filled"
                      name="vehicle"
                      value={addData.vehicle || ""}
                      onChange={handleOnChange2}
                      variant="outlined"
                      size="small"
                    >
                      {routes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.vehicleNumber.vehicleNumber}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Select Bus Stop</InputLabel>
                    <Select
                      label="Select Bus Stop"
                      id="demo-simple-select-filled"
                      name="busStop"
                      value={addData.busStop || ""}
                      onChange={handleOnChange2}
                      size="small"
                      fullWidth
                    >
                      {routes.map((row, index) =>
                        row.routeDetails.map((route, routeIndex) => (
                          <MenuItem
                            key={route._id}
                            value={route._id}
                            variant="outlined"
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {route.stopName}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Select Pick Type</InputLabel>
                    <Select
                      label="Select Pick Type"
                      id="demo-simple-select-filled"
                      name="pickType"
                      value={addData.pickType || ""}
                      variant="outlined"
                      onChange={handleOnChange2}
                      size="small"
                      fullWidth
                    >
                      <MenuItem value={"Both"}>Both</MenuItem>
                      <MenuItem value={"Pick"}>Pick</MenuItem>
                      <MenuItem value={"Drop"}>Drop</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    gap: "1%",
                    alignItems: "center",
                    justifyContent: {
                      md: "right",
                      sm: "center",
                      xs: "center",
                    },
                    // marginTop: "10px",
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="error"
                      size="medium"
                      type="button"
                      sx={{ m: 1, color: "#ffff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      // sx={{
                      //   background: "rgb(27, 55, 121)",
                      //   ":hover": { background: "rgb(27, 55, 121)" },
                      // }}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
        <TabPanel value={tabValue} index={0}>
          <Card style={styles.cardStyle}>
            <Box sx={{ flexGrow: 1 }} mt={1}>
              <form onSubmit={handleStudentSearchSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Class</InputLabel>
                      <Select
                        label="Class"
                        id="demo-simple-select-filled"
                        name="className"
                        value={formData.className || ""}
                        onChange={handleClassChange}
                        size="small"
                      >
                        {classes &&
                          classes.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Section</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="section"
                        value={formData.section || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {formData.className && (
                          <MenuItem value="all">--all--</MenuItem>
                        )}
                        {sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    alignSelf="center"
                    display="flex"
                    justifyContent={{
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    }}
                  >
                    <LoadingButton
                      loading={loader}
                      type="submit"
                      size="small"
                      variant="contained"
                      style={styles.Button}
                    >
                      SEARCH
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>
          <div style={{ margin: "15px 0" }}>
            {/* <Paper sx={{ height: "60px", overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  margin: "15px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid>
                    <TextField
                      size="small"
                      style={styles.textfield}
                      value={search}
                      onChange={handleSearch}
                      placeholder="Enter student name..."
                      label="Search"
                      variant="outlined"
                      sx={{ mt: 1 }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper> */}
            {/* <div style={{ margin: "15px" }}> */}
            <Grid container spacing={2} justifyContent="flex-end" mb={1.5}>
              <Grid item xs="auto">
                <TextField
                  size="small"
                  value={search}
                  placeholder="Search By Place"
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {/* </div> */}
            <Box sx={{ overflow: { xs: "scroll", sm: "visible" } }}>
              <Table>
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Name
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Admission No
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Stop Name
                    </TableCell>

                    <TableCell sx={{ color: "white" }} align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search
                    ? searchFilter.map((data, index) => (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {data.basicInfo.name}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.admissionNo}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {data.transportInfo && data.transportInfo.stopName
                              ? data.transportInfo.stopName
                              : "NA"}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {data.otherInfo.transportMember === "yes" ? (
                              <div className="edit-delete">
                                <Button
                                  style={{ color: "#1b3779" }}
                                  onClick={() => handleClickOpen(data._id)}
                                >
                                  <EditIcon />
                                </Button>

                                <Button
                                  style={{
                                    color: "#1b3779",
                                  }}
                                  onClick={(e) =>
                                    handleDeleteMember(e, data._id)
                                  }
                                >
                                  <Delete />
                                </Button>
                                <Button>
                                  <DownloadIcon />
                                </Button>
                              </div>
                            ) : (
                              <Button
                                onClick={() =>
                                  handleAddTransportButtonClick(data._id)
                                }
                              >
                                Add Member
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    : students.map((data, index) => (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {data.basicInfo.name}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.admissionNo}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {data.transportInfo && data.transportInfo.stopName
                              ? data.transportInfo.stopName
                              : "NA"}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {data.otherInfo.transportMember === "yes" ? (
                              <div className="edit-delete">
                                <Button
                                  style={{ color: "#1b3779" }}
                                  onClick={() => handleClickOpen(data._id)}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  style={{
                                    color: "#1b3779",
                                  }}
                                  onClick={(e) =>
                                    handleDeleteMember(e, data._id)
                                  }
                                >
                                  <Delete />
                                </Button>
                                <Button>
                                  <DownloadIcon />
                                </Button>
                              </div>
                            ) : (
                              <Button
                                onClick={() =>
                                  handleAddTransportButtonClick(data._id)
                                }
                              >
                                Add Member
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Box>
            {!students.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {search.trim() && !searchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card style={styles.cardStyle}>
            <Box sx={{ flexGrow: 1 }} mt={1}>
              <form onSubmit={(e) => handleNonStudentSearchSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Class</InputLabel>
                      <Select
                        label="Class"
                        id="demo-simple-select-filled"
                        name="className"
                        value={formData.className || ""}
                        onChange={handleClassChange}
                        size="small"
                      >
                        {classes &&
                          classes.map((row) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Section</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="section"
                        value={formData.section || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {formData.className && (
                          <MenuItem value="all">--all--</MenuItem>
                        )}
                        {sections.map((row) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    alignSelf="center"
                    display="flex"
                    justifyContent={{
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    }}
                  >
                    <LoadingButton
                      loading={loader}
                      type="submit"
                      size="small"
                      variant="contained"
                      style={styles.Button}
                    >
                      SEARCH
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>
          <div style={{ margin: "15px 0" }}>
            {/* <Paper sx={{ height: "60px", overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  margin: "15px",
                }}
              > */}
            <Grid container spacing={2} justifyContent="flex-end" mb={1.5}>
              <Grid item xs="auto">
                <TextField
                  fullWidth
                  size="small"
                  // style={styles.textfield}
                  value={nonSearch}
                  onChange={handleNonSearch}
                  placeholder="Enter student name..."
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {/* </div> */}
            {/* </Paper> */}
            <Box sx={{ overflow: { xs: "scroll", sm: "visible" } }}>
              <Table>
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Photo
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Name
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Class
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Section
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Roll No
                    </TableCell>

                    <TableCell sx={{ color: "white" }} align="center">
                      Select
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nonSearch
                    ? nonSearchFilter.map((data, index) => (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            <Avatar
                              src={objHas(data, "studentPhoto.link", "")}
                            />
                          </TableCell>

                          <TableCell align="center">
                            {data.basicInfo.name}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.class.className}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {data.academicInfo.section.sectionName}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.rollNo}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() =>
                                handleAddTransportButtonClick(data._id)
                              }
                            >
                              Add Member
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    : nonStudents.map((data, index) => (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            <Avatar
                              src={objHas(data, "studentPhoto.link", "")}
                            />
                          </TableCell>

                          <TableCell align="center">
                            {data.basicInfo.name}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.class.className}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {data.academicInfo.section.sectionName}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.rollNo}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() =>
                                handleAddTransportButtonClick(data._id)
                              }
                            >
                              Add Member
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Box>

            {!nonStudents.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {nonSearch.trim() && !nonSearchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </div>
        </TabPanel>
      </div>
    </Wrapper>
  );
};

export default TransportMember;
