import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { imageCarousalSettings } from "../Data/carousal";
import { CardMedia, Box, styled } from "@mui/material";
import Slider from "react-slick";

const AppSlider = styled(Slider)`
  width: 100%;
  .slick-track {
    display: flex;
    //flex-shrink: 1;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    //margin-bottom: 1;
    outline: none;
  }
  .slick-list {
    overflow: hidden;
  }
`;

function ImageCarousal({ images = [], style }) {
  return (
    <Box sx={style}>
      <AppSlider
        lazyLoad="progressive"
        // autoplay
        // dots
        sx={{ backgroundColor: "black" }}
        {...imageCarousalSettings}
      >
        {images.map((image, index) => (
          <CardMedia
            key={index}
            component="img"
            height="100%"
            width={"100%"}
            image={image}
            alt="service"
            sx={{
              objectFit: "contain",
              backgroundColor: "black",
              width: "100%",
              height: "300px",
            }}
          />
        ))}
      </AppSlider>
    </Box>
  );
}

export default ImageCarousal;
