import {
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const VehicleMaintenanceTire = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [firmName, setFirmName] = React.useState([]);
  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [addForm, setAddForm] = useState({});
  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState("");
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [list, setList] = React.useState([]);
  const [addFormTyre, setAddFormTyre] = useState({
    toDate: "",
    fromDate: "",
  });
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editTyre, setEditTyre] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getTyre();
  }, [selectedSetting]);

  const getTyre = async () => {
    setList([]);
    setLoader(true);
    try {
      const fromDateObj = new Date(fromDate);
      fromDateObj.setDate(fromDateObj.getDate() - 1);
      const { data, status } = await get(urls.tyre.getList, {
        params: {
          vehicleNumber: addForm.vehicleNumber,
          firmName: addForm.firmName,
          fromDate: fromDateObj.toISOString(),
          toDate: toDate,
        },
      });
      setList(data.data);
      console.log(data.data, "jjjjjkkkkkkfff");
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    setFirmName([]);
    const getFirmName = async () => {
      try {
        const { data, status } = await get(`${urls.firm.getList}`);
        if (status > 199 && status < 299) {
          setFirmName(data.data);
          console.log(data.data, "firmnammmmmmmeeee");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFirmName();
  }, [selectedSetting]);

  useEffect(() => {
    setVehicleNumber([]);
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`, {});
        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
          console.log(data.data, "dattttttttttt");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, [selectedSetting]);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name == "vehicleNumber") {
      let vehicle = vehicleNumber.filter((v) => v._id == value)[0];
      setSelectedVehicleNumber(vehicle.vehicleNumber);
    }
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/tyre/downloadpdf/${addForm.vehicleNumber}/${addForm.firmName}/${fromDate}/${toDate}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editTyre) {
        const { status } = await post(`${urls.tyre.create}`, {
          vehicleNumber: addForm.vehicleNumber,
          firmName: addForm.firmName,
          date: date,
          kmReading: addForm.kmReading,
          kmRun: addForm.kmRun,
          tyre: addForm.tyre,
          amount: addForm.amount,
          rate: addForm.rate,
          tyreNo: addForm.tyreNo,
        });
        getTyre();
        handleClose();
      } else {
        const { status } = await put(`${urls.tyre.update}/${editTyre}`, "", {
          vehicleNumber: addForm.vehicleNumber,
          firmName: addForm.firmName,
          date: date,
          kmReading: addForm.kmReading,
          kmRun: addForm.kmRun,
          tyre: addForm.tyre,
          amount: addForm.amount,
          rate: addForm.rate,
          tyreNo: addForm.tyreNo,
        });
        getTyre();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRepairEdit = (id, data) => {
    setEditTyre(id);
    setAddForm({
      vehicleNumber: data.vehicleNumber,
      firmName: data.firmName,
      date: date,
      kmReading: data.kmReading,
      kmRun: data.kmRun,
      tyre: data.tyre,
      amount: data.amount,
      rate: data.rate,
      tyreNo: data.tyreNo,
    });
    handleClickOpen();
  };
  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.tyre.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickOpen = () => {
    if (!addForm.vehicleNumber)
      return alert("Please select the vehicle number");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAddForm({});
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const styles = {
    cardsMargin: {
      margin: "15px 0",
    },
    cardStyle: {
      margin: "15px 0",
      padding: "10px",
    },
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
    },
    card: {
      padding: 15,
      marginTop: 20,
      width: "100%",
    },
    textfield: {
      width: "100%",
      label: { fontSize: 12 },
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
      // marginLeft: "5px",
      // marginTop: "15px",
    },
    addbutton: {
      // paddingTop: "6.2px",
      // paddingBottom: "6.2px",
    },
  };
  return (
    <div>
      <div style={styles.cardsMargin}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid>
              <Button
                variant="contained"
                style={styles.addbutton}
                onClick={handleClickOpen}
              >
                <AddIcon />
                Add
              </Button>
            </Grid>
          </Grid>
        </div>

        <div style={{ marginTop: "5px" }}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Vehicle Number</InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="vehicle number"
                        name="vehicleNumber"
                        onChange={handleAddForm}
                        value={addForm.vehicleNumber || ""}
                      >
                        {vehicleNumber.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.vehicleNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Firm Name</InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Firm Name"
                        name="firmName"
                        onChange={handleAddForm}
                        value={addForm.firmName || ""}
                      >
                        {firmName.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.firmName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    sx={{ alignSelf: "center" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={fromDate || ""}
                          onChange={setFromDate}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    sx={{ alignSelf: "center" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="To Date"
                          inputFormat="DD-MM-YYYY"
                          value={toDate || ""}
                          onChange={setToDate}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    display="flex"
                    justifyContent={{
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    }}
                    gap={2}
                  >
                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      onClick={getTyre}
                      style={styles.Button}
                    >
                      Find
                    </LoadingButton>
                    <LoadingButton
                      onClick={handleGetPrintPdf}
                      variant="contained"
                      style={styles.Button}
                      loading={loader}
                    >
                      Print
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>

              {/* <Stack spacing={2} direction="row">
                <LoadingButton
                  loading={loader}
                  variant="contained"
                  onClick={getTyre}
                  style={styles.Button}
                >
                  Find
                </LoadingButton>
                <LoadingButton
                  onClick={handleGetPrintPdf}
                  variant="contained"
                  style={styles.Button}
                  loading={loader}
                >
                  Print
                </LoadingButton>
              </Stack> */}
            </Box>
          </Card>
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                    onChange={handleAddForm}
                  >
                    Add
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography component="h4">
                    {selectedVehicleNumber}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    // sx={{ m: 0.3, minWidth: 227 }}
                  >
                    <InputLabel>Firm</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Firm Name"
                      name="firmName"
                      onChange={handleAddForm}
                      value={addForm.firmName || ""}
                    >
                      {firmName.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.firmName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      variant="standar"
                      label="Date"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          // sx={{ m: 1.3, minWidth: 227 }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    // sx={{ m: 1, minWidth: 227 }}
                    label="Km Reading"
                    name="kmReading"
                    onChange={handleAddForm}
                    value={addForm.kmReading || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    // sx={{ m: 1, minWidth: 227 }}
                    label="Km Run"
                    name="kmRun"
                    onChange={handleAddForm}
                    value={addForm.kmRun || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Tyre"
                    name="tyre"
                    value={addForm.tyre || ""}
                    onChange={handleAddForm}
                    sx={{ label: { fontSize: 12 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Rate"
                    name="rate"
                    value={addForm.rate || ""}
                    onChange={handleAddForm}
                    sx={{ label: { fontSize: 12 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    // sx={{ m: 1, minWidth: 227 }}
                    label="Tyre No"
                    name="tyreNo"
                    onChange={handleAddForm}
                    value={addForm.tyreNo || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Amount"
                    name="amount"
                    value={addForm.amount || ""}
                    onChange={handleAddForm}
                    sx={{ label: { fontSize: 12 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                        gap: "1%",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
        {/* <Modal
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <form className="form-tag">
            <Box sx={style}>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                // onChange={handleAddForm}
              >
               Vendor Information
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="start"
                marginBottom="20px"
                // onChange={handleAddForm}
              >
                Basic Information
              </Typography>
              <div>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Name"
                  // name="contactNumber"
                  // onChange={handleAddForm}
                  // value={addForm.contactNumber || ""}
                />
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Phone No"
                  // name="contactNumber"
                  // onChange={handleAddForm}
                  // value={addForm.contactNumber || ""}
                />
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Email"
                  // name="contactNumber"
                  // onChange={handleAddForm}
                  // value={addForm.contactNumber || ""}
                />
                <div>
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    label="Gst Number"
                    // name="address"
                    // value={addForm.address || ""}
                    // onChange={handleAddForm}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />

                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    label="Vendor"
                    // name="address"
                    // value={addForm.address || ""}
                    // onChange={handleAddForm}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </div>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="start"
                  marginBottom="20px"
                  marginTop="15px">
                  Address Information
                </Typography>
                <div>
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Address"
                    // name="contactNumber"
                    // onChange={handleAddForm}
                    // value={addForm.contactNumber || ""}
                  />
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ m: 1, minWidth: 227 }}
                    label="City"
                    // name="contactNumber"
                    // onChange={handleAddForm}
                    // value={addForm.contactNumber || ""}
                  />
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ m: 1, minWidth: 227 }}
                    label="State"
                    // name="contactNumber"
                    // onChange={handleAddForm}
                    // value={addForm.contactNumber || ""}
                  />
                  <div>
                    <TextField
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="Zip Code"
                      // name="address"
                      // value={addForm.address || ""}
                      // onChange={handleAddForm}
                      sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                    />
                  </div>
                  
                  </div>
                </div>

                <div className="cancel-submit-btn">
                  <div>
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleCloseDialog}
                        variant="outlined"
                        size="medium"
                        type="button"
                        sx={{ m: 1 }}>
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}>
                        Submit
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              
            </Box>
          </form>
        </Modal> */}
        <Box overflow="scroll">
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Firm
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Km Reading
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Km run
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Tyre
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Amount
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((tyre, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>

                  <TableCell align="center">
                    {dayjs(tyre.date).format("DD-MM-YYYY")}
                  </TableCell>

                  <TableCell align="center">{tyre.firmName.firmName}</TableCell>
                  <TableCell align="center">{tyre.kmReading}</TableCell>
                  <TableCell align="center">{tyre.kmRun}</TableCell>
                  <TableCell align="center">{tyre.tyre}</TableCell>
                  <TableCell align="center">{tyre.amount}</TableCell>
                  <TableCell align="center">
                    <div className="edit-delete">
                      <Button
                        style={{ color: "#1b3779" }}
                        onClick={() => handleRepairEdit(tyre._id, tyre)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        style={{
                          color: "#1b3779",
                        }}
                        onClick={() => setDeleteModal(tyre._id, tyre)}
                      >
                        <Delete />
                      </Button>
                      <DeleteModal
                        deleteModal={deleteModal}
                        handleDelete={handleDeleteone}
                        id={tyre._id}
                        setDeleteModal={setDeleteModal}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        {!list.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </div>
    </div>
  );
};

export default VehicleMaintenanceTire;
