import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import DeleteModal from "../Academic/DeleteModal";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { objHas } from "../../../utils/utils";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { LoadingButton } from "@mui/lab";
import {
  PrintSharp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    marginTop: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Credential = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [updateStudent, setUpdateStudent] = useState(undefined);
  const [formData, setFormData] = useState({});
  const [bulkAdmitModal, setBulkAdmitModal] = useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [showPagination, setShowPagination] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = useState({ name: "asc", rollNo: "asc" });
  const [sortField, setSortField] = useState("sectionOrder");
  const [sortOrder, setSortOrder] = useState("asc");
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(false);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleIconChange = async (e) => {
    e.preventDefault();
    try {
      setShowPagination(true);

      if (formData.section === "all") {
        const res = await get(urls.students.getAllStudent, {
          params: {
            search: {
              "basicInfo.name": { $regex: search },
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
            },
          },
        });
        setSchoolStudents(res.data.data);
      } else {
        const res = await get(urls.students.getAllStudent, {
          params: {
            search: {
              "basicInfo.name": { $regex: search },
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
            },
          },
        });
        setSchoolStudents(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const [count,setCount] = useState(0);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  // useEffect(() => {
  //   const schoolStudentGet = async () => {
  //     try {
  //       const response = await get(urls.students.getAllStudent);
  //       setSchoolStudent(response.data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   schoolStudentGet();
  // }, []);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const res = await del(urls.students.delStudent, id);
      if (res.data.success) {
        const filteredStudent = schoolStudents.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolStudents(filteredStudent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
            },
          },
        });
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
        setSchoolStudents(searchStudents.data.data);
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          schoolClass: res.data.data[0]._id,
          section: "all",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  // useEffect(() => {
  //   const getSections = async () => {
  //     try {
  //       const res = await get(urls.schoolSection.getAllSection);
  //       setSections(res.data.data);
  //       setFormData((prev) => ({
  //         ...prev,
  //         section: "all",
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getSections();
  // }, []);

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getStudentById = schoolStudents.find((item) => item._id === id);
      setUpdateStudent(getStudentById);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAdmitSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const getSheet = await get(`${urls.students.getBulkAdmitSheet}`, {
        responseType: "blob",
      });
      const uri = URL.createObjectURL(getSheet.data);

      const link = document.createElement("a");
      link.href = uri;
      link.setAttribute("download", "student.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingBtn(false);
    } catch (error) {
      toast.error(error.message);
    }
    setLoadingBtn(false);
  };

  const handleGetPrintPdf = async (e) => {
    try {
      if (formData.section === "all") {
        const getPrint = await get(
          `${urls.students.getStudentsListPdf}/${formData.schoolClass}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );
        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      } else {
        const getPrint = await get(
          `${urls.students.getStudentsListBySectionPdf}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();

    setLoadingBtn(true);
    try {
      const getDownload = await get(
        `${urls.students.getStudentsListExcel}/${formData.schoolClass}/${formData.academicYear}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getDownload.data);
      const link = document.createElement("a");
      console.log(uri);
      link.href = uri;
      link.setAttribute("download", "student.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setLoadingBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStudentSearchSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }

      // setFormData({});
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleChangePage = async (event, newPage) => {
    try {
      if (formData.section === "all") {
        const res = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
            },
            page: newPage + 1,
            limit: rowsPerPage,
          },
        });
        setPage(newPage);
        setSchoolStudents(res.data.data);
      } else {
        const res = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
            },
            page: newPage + 1,
            limit: 10,
          },
        });
        setPage(newPage);
        setSchoolStudents(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSubmitBulkAdmit = async (e) => {
    e.preventDefault();
    setValue(true);
    try {
      const formDataFile = new FormData();
      formDataFile.append("sheet", e.target.bulkAdmit.files[0]);
      if (formData.section !== "all")
        await post(
          `${urls.students.postBulkAdmit}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          formDataFile
        );
    } catch (error) {
      console.log(error);
    }
    setValue(false);
  };
  const handleGetUpdateSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    try {
      if (formData.section !== "all") {
        const getSheet = await get(
          `${urls.students.getBulkUpdate}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );
        const uri = URL.createObjectURL(getSheet.data);

        const link = document.createElement("a");
        link.href = uri;
        link.setAttribute("download", "studentUpdate.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitBulkUpdate = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const formData = new FormData();
      formData.append("sheet", e.target.bulkUpdate.files[0]);
      await put(`${urls.students.putBulkUpdateStudent}`, "", formData);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };
  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0].name);
  };

  const sortByName = () => {
    console.log("name");
    if (sort.name == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const handleSort = (name) => {
    if (sortField === name) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(name);
      setSortOrder("asc");
    }
  };
  const sortByRoll = (e) => {
    console.log("jjjj");
    if (sort.rollNo == "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };
  console.log("schoolStudents", schoolStudents);
  return (
    <div style={{ margin: "15px" }}>
      <SectionSubHeader title="Credentials" />
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleStudentSearchSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    label="Class"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={formData.schoolClass || ""}
                    onChange={handleClassChange}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Section
                  </InputLabel>
                  <Select
                    label="Section"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                  >
                    {formData.schoolClass && (
                      <MenuItem value="all">----all----</MenuItem>
                    )}
                    {sections &&
                      sections
                        .slice() // Creating the copy of the array to avoid mutating the original array
                        .sort((a, b) =>
                          a.sectionName.localeCompare(b.sectionName)
                        )
                        .map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3} style={{ alignSelf: "center" }}>
                <LoadingButton
                  loading={loader}
                  type="submit"
                  size="small"
                  variant="contained"
                  style={styles.Button}
                >
                  SEARCH
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span
                    onClick={sortByName}
                    className="class-table-header"
                    // onSort={() => handleSort('name')}
                    // sortField={sortField}
                    // users={sortedUsers}
                    // sortOrder={sortOrder}
                  >
                    Name
                  </span>
                  <span
                    onClick={sortByName}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.name === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Username</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Roll No</span>
                  <span
                    onClick={sortByRoll}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.rollNo === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Father Name</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolStudents.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.basicInfo.name}
                  </TableCell>
                  <TableCell align="center">{row.username}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.academicInfo.rollNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {objHas(row, "fatherInfo.fatherName", "")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!schoolStudents.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </Paper>

      {showPagination ? (
        <TablePagination
          style={{ display: "flex", alignItems: "start" }}
          // rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}

      <Modal
        open={bulkAdmitModal}
        onClose={() => setBulkAdmitModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContainerBox>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Bulk Admit
            </span>
          </div>
          <form onSubmit={handleSubmitBulkAdmit}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Tooltip title="Get Sample">
                  <LoadingButton
                    variant="contained"
                    loading={loadingBtn}
                    // style={styles.Button}
                    onClick={handleGetAdmitSheet}
                    sx={{
                      backgroundColor: "#1b3779",
                      ":hover": { backgroundColor: "#1b3779" },
                    }}
                  >
                    Get Sample
                  </LoadingButton>
                </Tooltip>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Tooltip title="Upload">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      component="label"
                    >
                      upload
                      <input
                        name="bulkAdmit"
                        onChange={handleFileChange}
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                  </Tooltip>
                </div>
                <div>{fileChoosen && fileChoosen}</div>
              </div>
              <div>
                <Tooltip title="Submit">
                  <LoadingButton
                    loading={value}
                    variant="contained"
                    style={styles.Button}
                    type="submit"
                  >
                    submit
                  </LoadingButton>
                </Tooltip>
              </div>
            </div>
          </form>
        </ContainerBox>
      </Modal>
      <Modal
        open={bulkUpdateModal}
        onClose={() => setBulkUpdateModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContainerBox>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Bulk Update
            </span>
          </div>
          <form onSubmit={handleSubmitBulkUpdate}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Tooltip title="Get Sample">
                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="submit"
                    loading={loadingBtn}
                    onClick={handleGetUpdateSheet}
                    sx={{
                      backgroundColor: "#1b3779",
                      ":hover": { backgroundColor: "#1b3779" },
                    }}
                  >
                    Get Sample
                  </LoadingButton>
                </Tooltip>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Tooltip title="Upload">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      component="label"
                    >
                      upload
                      <input
                        name="bulkUpdate"
                        onChange={handleFileChange}
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                  </Tooltip>

                  <div>{fileChoosen && fileChoosen}</div>
                </div>
              </div>
              <div>
                <Tooltip title="Submit">
                  <LoadingButton
                    loading={loadingBtn}
                    variant="contained"
                    style={styles.Button}
                    type="submit"
                  >
                    submit
                  </LoadingButton>
                </Tooltip>
              </div>
            </div>
          </form>
        </ContainerBox>
      </Modal>
    </div>
  );
};

export default Credential;
