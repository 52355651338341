import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  IconButton,
  Fab,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import DeleteModal from "../Layout/Academic/DeleteModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "../../styles/components/Layout/Administrator/UserRole.module.css";

import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

import { Delete, Edit, Search } from "@mui/icons-material";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";
import SectionsSubHeader from "../../components/SectionSubHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const style = {
  position: "relative",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "750px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "750px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ContainerBox1 = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxWidth: "550px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const StudentLibrary = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [list, setList] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [file, setFile] = useState();
  const [dialog, setOpenDialog] = React.useState(false);
  const [editBook, setEditBook] = useState();
  const [addForm, setAddForm] = useState({});
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [issueTotalCount, setIssueTotalCount] = useState();
  const [empIssueTotalCount, setEmpIssueTotalCount] = useState();
  const [issue, setIssue] = useState([]);
  const [empIssue, setEmpIssue] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [countDownloadLoader, setCountDownloadLoader] = useState(false);
  const [stockDownloadLoader, setStockDownloadLoader] = useState(false);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getbooks();
    getEmpIssueList();
    getStuIssueList();
  }, [selectedSetting]);
  const getbooks = async () => {
    try {
      const data = await get(urls.book.getList);

      setList(data.data.data);
      // console.log(data.data.data, "ddaaaaaaattaaaaa");
    } catch (error) {
      console.log(error);
    }
  };

  const getEmpIssueList = async () => {
    console.log("issue");
    try {
      const response = await get(urls.empIssueList.get, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filterIssue = response.data.data.filter(
        (issues) => issues.submitted === "no"
      );
      setEmpIssue(filterIssue);
      console.log(filterIssue, "setEmpIssueTotalCount");
      setEmpIssueTotalCount(filterIssue.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getStuIssueList = async () => {
    try {
      // setShowPagination(true);
      const response = await get(urls.issueList.get, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filterIssue = response.data.data.filter(
        (issues) => issues.submitted === "no"
      );
      setIssue(filterIssue);
      console.log(filterIssue, "setIssueTotalCount");
      setIssueTotalCount(filterIssue.length);
    } catch (error) {
      console.log(error);
    }
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
    },
    textfield: {
      marginTop: "25px",
      // width: "70%",
      marginLeft: "5%",
      marginRight: "5%",
    },
    addbutton: {
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "-5%",
      backgroundColor: "",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.booktitle.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.book.delBook}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditBook(null);
    setOpenDialog(false);
    resetForm();
  };
  const resetForm = () => {
    setAddForm({});
  };
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleClickOpenDialog = (data) => {
    setShowDetails(data);
    setOpenDialog(true);
  };

  const handleImages = (e) => {
    setImages((prev) => [...prev, ...e.target.files]);
  };

  const handleCloseDialog = (data) => {
    setShowDetails(data);
    setOpenDialog(false);
  };
  const handleGetDownloadSheet = async (e) => {
    setDownloadLoader(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/library/downloadexcel`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `book-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setDownloadLoader(false);
  };

  const handleGetCountDownloadSheet = async (e) => {
    setCountDownloadLoader(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/library/downloadcountexcel`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `library-count.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setCountDownloadLoader(false);
  };
  const handleGetStockDownloadSheet = async (e) => {
    setStockDownloadLoader(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/library/downloadstockexcel`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `Book-Stock-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setStockDownloadLoader(false);
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      if (!editBook) {
        let imageIds = [];
        const formImage = new FormData();
        if (images.length > 0) {
          for (const img of images) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage);
          imageIds = res.data.result.map((id) => id._id);
        }
        const { status } = await post(`${urls.book.create}`, {
          booktitle: addForm.booktitle,
          booksId: addForm.booksId,
          department: addForm.department,
          number: addForm.number,
          isbnNo: addForm.isbnNo,
          edison: addForm.edison,
          author: addForm.author,
          language: addForm.language,
          price: addForm.price,
          quantity: addForm.quantity,
          almiraNo: addForm.almiraNo,
          backCover: imageIds,
        });
        getbooks();
        handleClose();
      } else {
        const { status } = await put(`${urls.book.editBook}${editBook}`, "", {
          booktitle: addForm.booktitle,
          booksId: addForm.booksId,
          department: addForm.department,
          number: addForm.number,
          isbnNo: addForm.isbnNo,
          edison: addForm.edison,
          author: addForm.author,
          language: addForm.language,
          price: addForm.price,
          quantity: addForm.quantity,
          almiraNo: addForm.almiraNo,
          // backCover: imageIds,
        });
        getbooks();
        handleClose();
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  // const handleAddSubmit = async (e) => {
  //   setLoader(true);
  //   e.preventDefault();
  //   try {
  //     if (!editBook) {
  //       const fileForm = new FormData();
  //       if (images && images.length > 0) {
  //         for (const img of images) {
  //           fileForm.append("files", img);
  //         }
  //       }
  //       const imageIds =
  //         images.length > 0
  //           ? await post(urls.fileUpload.postFileUpload, fileForm)
  //           : [];

  //       const { status } = await post(`${urls.book.create}`, {
  //         booktitle: addForm.booktitle,
  //         booksId: addForm.booksId,
  //         department: addForm.department,
  //         number: addForm.number,
  //         isbnNo: addForm.isbnNo,
  //         edison: addForm.edison,
  //         author: addForm.author,
  //         language: addForm.language,
  //         price: addForm.price,
  //         quantity: addForm.quantity,
  //         almiraNo: addForm.almiraNo,
  //         backCover: imageIds,
  //       });
  //       getbooks();
  //       handleClose();
  //     } else {
  //       const { status } = await put(`${urls.book.editBook}${editBook}`, "", {
  //         booktitle: addForm.booktitle,
  //         booksId: addForm.booksId,
  //         department: addForm.department,
  //         number: addForm.number,
  //         isbnNo: addForm.isbnNo,
  //         edison: addForm.edison,
  //         author: addForm.author,
  //         language: addForm.language,
  //         price: addForm.price,
  //         quantity: addForm.quantity,
  //         almiraNo: addForm.almiraNo,
  //         // backCover: imageIds, // Pass the imageIds to the edit request (can be undefined)
  //       });
  //       getbooks();
  //       handleClose();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoader(false);
  // };

  const handleTypeChange = (e) => {
    if (e.target.name === "bookCover") {
      setAddForm({
        ...addForm,
        bookCover: e.target.files[0],
      });
    } else {
      setAddForm({
        ...addForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleEditChange = (id, data) => {
    setEditBook(id);
    setAddForm({
      booktitle: data.booktitle,
      booksId: data.booksId,
      department: data.department,
      number: data.number,
      isbnNo: data.isbnNo,
      edison: data.edison,
      author: data.author,
      language: data.language,
      price: data.price,
      quantity: data.quantity,
      almiraNo: data.almiraNo,
      // backCover: data.backCover.link,
    });
    handleClickOpen();
  };

  const handleGetUpdateSheet = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/library/downloadsample`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `sample-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };

  const handleSheetUpload = async (e) => {
    setBulkUploadLoader(true);
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);
      const response = await put(
        `/${selectedSetting._id}/library/uploadsample`,
        "",
        formData
      );
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      getbooks();
      handleClose();
    } catch (error) {
      console.error(error);
    }
    setBulkUploadLoader(false);
  };

  return (
    <div>
      <SectionsSubHeader title="Student Library" style={{ margin: "15px" }} />
      <Box sx={{ margin: "15px" }}>
        <Modal
          open={bulkUpdateModal}
          onClose={() => setBulkUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ContainerBox1>
            <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Bulk Upload
              </span>
            </Box>
            <form onSubmit={handleSheetUpload}>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Box>
                  <Tooltip title="Get Sample">
                    <LoadingButton
                      variant="contained"
                      // size="small"
                      type="submit"
                      loading={loadingBtn}
                      onClick={handleGetUpdateSheet}
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                      }}
                    >
                      Sample
                    </LoadingButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box>
                    <Tooltip title="Upload">
                      <Button
                        variant="contained"
                        style={styles.Button}
                        component="label"
                      >
                        Upload
                        <input
                          onChange={handleFileChange}
                          name="file"
                          hidden
                          accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                          type="file"
                        />
                      </Button>
                    </Tooltip>

                    <Box>{fileChoosen && fileChoosen.name}</Box>
                  </Box>
                </Box>
                <Box>
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={bulkUploadLoader}
                      variant="contained"
                      style={styles.Button}
                      type="submit"
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Box>
              </Box>
            </form>
          </ContainerBox1>
        </Modal>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    marginBottom="15px"
                    fontSize="20px"
                    onChange={handleTypeChange}
                    sx={{ fontWeight: "bold" }}
                  >
                    {editBook ? "Update Book" : "Add Book"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Book Title"
                    name="booktitle"
                    value={addForm.booktitle || ""}
                    onChange={handleTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Book ID"
                    name="booksId"
                    onChange={handleTypeChange}
                    value={addForm.booksId || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Department"
                    name="department"
                    onChange={handleTypeChange}
                    value={addForm.department || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Call Number"
                    name="number"
                    onChange={handleTypeChange}
                    value={addForm.number || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="ISBN No"
                    name="isbnNo"
                    value={addForm.isbnNo || ""}
                    onChange={handleTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Edition"
                    name="edison"
                    value={addForm.edison || ""}
                    onChange={handleTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Author"
                    name="author"
                    onChange={handleTypeChange}
                    value={addForm.author || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Language"
                    name="language"
                    value={addForm.language || ""}
                    onChange={handleTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Price"
                    name="price"
                    value={addForm.price || ""}
                    onChange={handleTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Quantity"
                    name="quantity"
                    onChange={handleTypeChange}
                    value={addForm.quantity || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Almira No"
                    name="almiraNo"
                    onChange={handleTypeChange}
                    value={addForm.almiraNo || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  {editBook ? (
                    ""
                  ) : (
                    <Box sx={{ marginTop: "2px", display: "flex" }}>
                      <FormControl fullWidth variant="standard">
                        <label style={{ fontWeight: "bold" }}>Book Cover</label>
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={handleImages}
                        />
                      </FormControl>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <LoadingButton
                        loading={loader}
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Add
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>

        <Paper
          sx={{
            padding: "10px",
            margin: "15px 0",
            display: "flex",
            flexDirection: { xs: "column", md: "row", sm: "column" },
            justifyContent: { xs: "center", md: "space-between", sm: "center" },
            alignItems: "center",
          }}
        >
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <LoadingButton
              loading={downloadLoader}
              sx={{
                color: "#ffff",
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleGetDownloadSheet}
            >
              Download
            </LoadingButton>

            <LoadingButton
              loading={countDownloadLoader}
              sx={{
                color: "#ffff",
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleGetCountDownloadSheet}
            >
              Count By title
            </LoadingButton>

            <LoadingButton
              loading={stockDownloadLoader}
              sx={{
                color: "#ffff",
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleGetStockDownloadSheet}
            >
              Stock Verification
            </LoadingButton>

            <Button
              sx={{
                color: "#ffff",
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={() => setBulkUpdateModal(true)}
            >
              Bulk Upload
            </Button>
          </Stack>
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            placeholder="Enter book name"
            label="Search"
            variant="outlined"
            sx={{
              marginTop: {
                xs: "10px",
                sm: "10px",
                md: "0px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Paper>

        <Box overflow={{ xs: "scroll", md: "visible" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Title
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Book ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Author
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Book Cover
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Price
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Quantity
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Available
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((book, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{book.booktitle}</TableCell>
                      <TableCell align="center">{book.booksId}</TableCell>
                      <TableCell align="center">{book.author}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {book.backCover ? (
                          <img
                            src={book.backCover.link}
                            alt="NA"
                            style={{
                              width: "100px",
                              height: "60px",
                              resize: "contain",
                            }}
                          ></img>
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell align="center">{book.price}</TableCell>
                      <TableCell align="center">{book.quantity}</TableCell>
                      <TableCell align="center">{book.issue}</TableCell>
                      <TableCell align="center">{book.balance}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleEditChange(book._id, book)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleClickOpenDialog(book)}
                          >
                            <VisibilityIcon />
                          </Button>
                          <Button
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(book._id, book)}
                          >
                            <Delete color="error" fontSize="small" />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={book._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                : list.map((book, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{book.booktitle}</TableCell>
                      <TableCell align="center">{book.booksId}</TableCell>
                      <TableCell align="center">{book.author}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {book.backCover ? (
                          <img
                            src={book.backCover.link}
                            alt="NA"
                            style={{
                              width: "100px",
                              height: "60px",
                              resize: "contain",
                            }}
                          ></img>
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell align="center">{book.price}</TableCell>
                      <TableCell align="center">{book.quantity}</TableCell>

                      <TableCell align="center">{book.issue}</TableCell>
                      <TableCell align="center">{book.balance}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleEditChange(book._id, book)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleClickOpenDialog(book)}
                          >
                            <VisibilityIcon />
                          </Button>
                          <Button
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(book._id, book)}
                          >
                            <Delete color="error" fontSize="small" />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={book._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Book Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <Box>
                    <tr>
                      <td>School Name</td>
                      <td>Excellent English Medium Primary & High School</td>
                    </tr>
                    <tr>
                      <td>Book Title</td>
                      <td>{showDetails.booktitle}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>Book ID</td>
                      <td>{showDetails.booksId}</td>
                    </tr>
                    <tr>
                      <td>Author</td>
                      <td>{showDetails.author}</td>
                    </tr>
                    <tr>
                      <td>Language</td>
                      <td>{showDetails.language}</td>
                    </tr>
                    <tr>
                      <td>Quantity</td>
                      <td>{showDetails.quantity}</td>
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td>{showDetails.price}</td>
                    </tr>
                    <tr>
                      <td>Alamira No</td>
                      <td>{showDetails.almiraNo}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>
                        {dayjs(showDetails.entrydate).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </Box>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
        <Box className="add-icon">
          <Fab
            variant="contained"
            sx={{
              color: "#ffff",

              background: "rgb(27, 55, 121)",
              ":hover": { background: "rgb(27, 55, 121)" },
            }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </Box>
      </Box>
    </div>
  );
};

export default StudentLibrary;
