import React, { useContext, useRef, useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import Course from "./Cource";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from 'react-slick'
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import styled from "@emotion/styled";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { settings } from "./carousel1";
import coursedata from "./courseData";
import SettingContext from "../../context/SettingsContext";
import { get } from "../../services/apis";
import { calculateSlidersData } from "../../Data/carousal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const theme = createTheme();
const AppSlider = styled(Slider)`
  width: 100%;
  .slick-track {
    display: flex;
    //flex-shrink: 1;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    //margin-bottom: 1;
    outline: none;
  }
  .slick-list {
    overflow: hidden;
  }
`;

const MainContainer = styled(Container)(({ theme }) => ({
  marginTop: "4rem",
  [theme.breakpoints.down("md")]: {
    // margin:0,
    // padding:0,
    // width: "100%",
    // textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    // margin:"5%",
    padding: "7%",
    width: "100%",
    // textAlign: "center",
  },
}));

const TypographyMain = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: "25px",
  fontSize: "40px",
  color: "#f86f03",
  fontWeight: "bold",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    // textAlign:"center",
    // margin:0,
    // padding:"25px"
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    margin: 0,
    padding: "0",
  },
}));

const TextBox1 = styled(Box)(({ theme }) => ({
  marginTop: "4rem",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));

const CourseMain = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [awards, setAwards] = useState([]);

  useEffect(() => {
    const getAllAwards = async () => {
      try {
        const getAwards = await get(
          `/${selectedSetting._id}/award/list/home-page`,
          {
            params: {
              search: {
                webView: "yes",
              },
            },
          }
        );
        if (getAwards.data.success) {
          console.log(getAwards.data.result.data, "awards comp1");
          setAwards(getAwards.data.result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllAwards();
  }, []);

  let sliderRef = useRef(null);
  let handlePrevious = () => {
    sliderRef.current.slickPrev();
  };
  let handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <MainContainer>
          <TextBox1>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: { xs: "center" },
                flexDirection: { md: "row", xs: "column" },
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  color="black"
                  sx={{ fontWeight: "bold", fontSize: "40px" }}
                >
                  AWARDS &{" "}
                </Typography>
              </Box>
              &nbsp;&nbsp;
              <Box>
                <Typography
                  variant="h3"
                  color="#f86f03"
                  sx={{ fontWeight: "bold", fontSize: "40px" }}
                >
                  ACHIEVEMENTS
                </Typography>
              </Box>
            </Box>
          </TextBox1>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "50px",
            }}
          >
            <Typography component="p">________</Typography>
            <FiberManualRecordIcon
              sx={{ fontSize: "8px", marginTop: "15px" }}
            />
            <FiberManualRecordIcon
              sx={{
                color: "#F86F03",
                fontSize: "10px",
                marginTop: "14px",
                marginLeft: "5px",
              }}
            />
            <FiberManualRecordIcon
              sx={{ fontSize: "8px", marginTop: "15px", marginLeft: "6px" }}
            />
            <Typography component="p">________</Typography>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "3rem",
            }}
          >
            <Box
              component="div"
              onClick={handlePrevious}
              sx={{
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {" "}
              <NavigateBeforeIcon />
            </Box>
            <Box
              component="div"
              onClick={handleNext}
              ml={2}
              mr={2}
              sx={{
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {" "}
              <NavigateNextIcon />
            </Box>
          </Box> */}

          <AppSlider ref={sliderRef} {...calculateSlidersData(awards.length)}>
            {awards.map((d, i) => (
              <Course key={i} coursedata={d} />
            ))}
          </AppSlider>
        </MainContainer>
      </ThemeProvider>
    </>
  );
};

export default CourseMain;
