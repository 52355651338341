import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import MainNav from "../../NavbarComponents/MainNav";
import TopNav from "../../NavbarComponents/TopNav";

// import Footer from "../Components/Footer";

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://c4.wallpaperflare.com/wallpaper/869/719/717/cuisine-food-india-indian-wallpaper-preview.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "176px",
}));

const CustomDetails = ({ elem }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TopNav />
      <OuterBox sx={{ paddingTop: { md: "226px", sm: "235px", xs: "236px" } }}>
        <MainNav />
      </OuterBox>

      <Container>
        <Grid container spacing={2} my={5} p={{ md: 3, xs: 0 }} border={1}>
          <Grid item md={4.5}>
            <Box
              sx={{
                height: "90%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={elem.image ? elem.image.link : ""}
                alt=""
                style={{ height: "100%", width: "100%" }}
              />
            </Box>
          </Grid>
          <Grid item md={7}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <Button
                sx={{
                  color: "black",
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: 700, marginTop: "5%", color: "orange" }}
              >
                {elem.title}
              </Typography>

              <Typography
                variant="body2"
                sx={{ textAlign: "justify", marginTop: "12%" }}
              >
                {elem.news}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
export default CustomDetails;
