import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import { Delete, Edit, Search } from "@mui/icons-material";

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const defaultPopupData = {
    title:"",
    link:"",
    document:"",
    image:"",
    text: "",
    contentType:""
}

const SplashNewsDisplay = ({ sharedData=defaultPopupData, open=false, handleClose = () => {} }) => {
  return (

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
       
          <ContainerBox>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="20px">
              { sharedData.title}
            </Typography>


            {sharedData.text ? <Typography>{sharedData.text}</Typography> : null}

            {sharedData.document ? <Typography>Document : {sharedData.document}</Typography> : null}
            {sharedData.link ? <Typography>Link : {sharedData.link}</Typography> : null}
            {sharedData.contentType === "Image" ? <img src={sharedData.image} height={"100px"} width={"100px"} /> : null}
           
          </ContainerBox>
    
      </Modal>
  );
};

export default SplashNewsDisplay;
