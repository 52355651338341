import { Search } from "@material-ui/icons";
import {
  Box,
  Card,
  Fab,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Stack,
  Tooltip,
  Button,
  TableContainer,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { get, post, put, del } from "../../../services/apis";
import { urls, urls as url } from "../../../services/urlConstant";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useLocation } from "react-router-dom";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import { useTheme } from "@mui/material/styles";
const style = {
  position: "relative",
  width: "100%",
  bgcolor: "background.paper",
  p: 1,
  height: 600,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Transaction = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(300));
  const { selectedSetting } = useContext(SettingContext);

  console.log(selectedSetting, "settings");

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [search, setSearch] = useState("");
  const [fromType, setFromType] = useState("");
  const [toType, setToType] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [from, setSettingOrVendor] = useState("");
  const [to, setAdditionalDropdownValue] = useState("");
  const [roles, setRoles] = useState([]);
  const [schoolEmployee, setSchoolEmployee] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [departments, setDepartment] = useState([]);
  const [addForm, setAddForm] = useState({});
  const [student, setStudent] = useState([]);
  const [formData, setFormData] = useState({});
  const [dialog, setOpenDialog] = React.useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const { state: localState } = useLocation();
  const [settings, setSettings] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  useEffect(() => {
    if (localState) {
      if (localState == "stock") {
        setTabValue(1);
      }
    }
  }, [localState]);

  const [transactionFrom, setTransactionFrom] = useState({
    fromSchoolId: "",
    vendorId: "",
  });

  const [transactionTo, setTransactionTo] = useState({
    toSchoolId: "",
    vendorId: "",
    studentId: "",
    employeeId: "",
  });

  let [viewData, setViewData] = useState(null);

  console.log(sections, "sections");

  let { toSchoolId, vendorId, studentId, employeeId } = transactionTo;

  const [classes, setClasses] = useState([]);

  const [photo, setPhoto] = useState("");
  const [image, setImage] = useState("");

  function handleChangePhoto(e) {
    console.log(e.target.files);
    setPhoto(e.target.files[0]);
    setImage(e.target.files[0].name);
    setSelectedFile(e.target.files[0]);
  }

  const handleToTypeChange = (event) => {
    const selectedToType = event.target.value;
    setToType(selectedToType);
    setAdditionalDropdownValue("");
  };

  const handleAdditionalDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setAdditionalDropdownValue(selectedValue);
  };
  const handleFromTypeChange = (event) => {
    const selectedFromType = event.target.value;
    setFromType(selectedFromType);
    setSettingOrVendor("");
  };

  const handleSettingOrVendorChange = (event) => {
    const selectedSettingOrVendor = event.target.value;
    setSettingOrVendor(selectedSettingOrVendor);
  };

  const handleClickOpenDialog = (items) => {
    setOpenDialog(true);
    console.log(items, "view");
    setViewData(items);
  };
  const handleCloseDialog = () => {
    setViewData(null);
    setOpenDialog(false);
  };

  const styles = {
    card: {
      margin: "5px 0",
      padding: 10,
    },
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole);
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, []);

  useEffect(() => {
    const schoolEmployeeGet = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          params: {
            limit: 10,
            page: 1,
          },
        });

        setSchoolEmployee(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const schoolEmployeeGetAll = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee);
        setAllEmployees(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getAllDepartment = async () => {
      try {
        const departments = await get(urls.department.getDepartment);
        setDepartment(departments.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDepartment();
    schoolEmployeeGet();
    schoolEmployeeGetAll();
  }, []);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          //   academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          // section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getsearchStudents = async () => {
      try {
        if (!formData.className || !formData.section) return;
        const response = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.className,
              "academicInfo.section": formData.section,
            },
          },
        });
        setStudent(response.data.data);
      } catch (error) {}
    };
    getsearchStudents();
  }, [formData.className, formData.section]);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let [inventoryItem, setInventoryItem] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.inventory.getAllItem}`);
        setInventoryItem(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  let [vendorData, setVendorData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.vendor.getAll}`);
        setVendorData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  let [data, setData] = useState({
    itemId: "",
    pricePerItem: 0,
    quantity: 0,
    trasactionMode: "",
    type: "",
    purpose: "",
    status: "",
    description: "",
    invoice: "",
  });

  let {
    itemId,
    pricePerItem,
    quantity,
    trasactionMode,
    type,
    purpose,
    status,
    description,
    invoice,
  } = data;

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      itemId,
      pricePerItem,
      quantity,
      trasactionMode,
      type,
      purpose,
      status,
      description,
      invoice,
    };

    let imageRes = "ghghghghghgh";
    if (image) {
      const fileForm = new FormData();
      fileForm.append("files", photo);
      imageRes = await post(urls.fileUpload.postFileUpload, fileForm);
    } else {
      return alert("Please select an Image");
    }

    try {
      let { data } = await post(`${urls.transaction.createTransaction}`, {
        ...payload,
        fromType,
        toType,
        vendorId: transactionFrom.vendorId || transactionTo.vendorId,
        fromSchoolId: transactionFrom.fromSchoolId,
        toSchoolId: transactionTo.toSchoolId,
        employeeId: transactionTo.employeeId,
        studentId: transactionTo.studentId,
        invoice: imageRes.data.result[0]._id,
      });

      setTransactionData([...transactionData, data.data]);
      setTabValue(0);
    } catch (error) {
      console.log(error);
    }

    setData({
      itemId: "",
      pricePerItem: 0,
      quantity: 0,
      trasactionMode: "",
      type: "",
      purpose: "",
      status: "",
      description: "",
      invoice: "",
    });

    setTransactionFrom({
      fromSchoolId: "",
      vendorId: "",
    });

    setTransactionTo({
      toSchoolId: "",
      vendorId: "",
      studentId: "",
      employeeId: "",
    });

    window.scrollTo(0, 0);
  };

  let [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [selectedSetting]);
  const fetchData = async () => {
    try {
      let { data } = await get(`${urls.transaction.getAll}`);
      console.log(data.data, "transaction");
      setTransactionData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  let [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    //Employee data
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.employee.getAllEmployee}`);
        setEmployeeData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  let [studentData, setStudentData] = useState([]);

  useEffect(() => {
    //Student data
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.employee.getAllEmployee}`);
        setStudentData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  let { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabValue(1);
      let pricePerItem = state.totalPrice / state.totalStock || 0;
      setData({
        ...data,
        itemId: state._id,
        pricePerItem: pricePerItem,
      });
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.settings.getAllSettings}`);
        setSettings(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      transactionData.length > 0 &&
        setSearchFilter(
          transactionData.filter((ele) =>
            ele.item.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Transaction" />

      <Card style={styles.card}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Transaction List" {...a11yProps(0)} />
            <Tab label="Make Transaction" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card>

      <TabPanel value={tabValue} index={0}>
        <Grid container justifyContent="flex-end">
          <Grid item xs="auto" style={{ margin: "5px 0px" }}>
            <form>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
        </Grid>

        <div style={{ margin: "15px 0" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Item Id
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Item Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Total Price
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  type
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Status
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((items, index) => (
                    <React.Fragment key={items._id}>
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {items.item.itemId}
                        </TableCell>
                        <TableCell align="center">{items.item.name}</TableCell>
                        <TableCell align="center">{items.total}</TableCell>
                        <TableCell align="center">{items.type}</TableCell>
                        <TableCell align="center">
                          {items.date.slice(0, 10)}
                        </TableCell>
                        <TableCell align="center">{items.status}</TableCell>
                        <TableCell align="center">
                          <div>
                            <Button
                              onClick={() => handleClickOpenDialog(items)}
                              style={{ color: "#1b3779" }}
                            >
                              <VisibilityIcon />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                : transactionData.map((items, index) => {
                    return (
                      <React.Fragment key={items._id}>
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {items.item.itemId}
                          </TableCell>
                          <TableCell align="center">
                            {items.item.name}
                          </TableCell>
                          <TableCell align="center">{items.total}</TableCell>
                          <TableCell align="center">{items.type}</TableCell>
                          <TableCell align="center">
                            {items.date.slice(0, 10)}
                          </TableCell>
                          <TableCell align="center">{items.status}</TableCell>
                          <TableCell align="center">
                            <div>
                              <Button
                                onClick={() => handleClickOpenDialog(items)}
                                style={{ color: "#1b3779" }}
                              >
                                <VisibilityIcon />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
            </TableBody>
          </Table>
          {!transactionData.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </div>

        {viewData ? (
          // <BootstrapDialog
          //   onClose={handleCloseDialog}
          //   aria-labelledby="customized-dialog-title"
          //   open={dialog}
          // >
          //   <BootstrapDialogTitle
          //     id="customized-dialog-title"
          //     onClose={handleCloseDialog}
          //     style={{ backgroundColor: "#1b3779", color: "white" }}
          //   >
          //     Transaction Information
          //   </BootstrapDialogTitle>

          //   <DialogContent dividers>
          //     <table className="transaction">
          //       <tbody>
          //         <div>
          //           <tr>
          //             <td>Item Name</td>
          //             <td>{viewData.item.name}</td>
          //           </tr>
          //           <tr>
          //             <td>Price Per Item</td>
          //             <td>{viewData.pricePerItem}</td>
          //           </tr>
          //           <tr>
          //             <td>Quantity</td>
          //             <td>{viewData.quantity}</td>
          //           </tr>
          //           <tr>
          //             <td>Mode</td>
          //             <td>{viewData.trasactionMode}</td>
          //           </tr>
          //           <tr>
          //             <td>Type</td>
          //             <td>{viewData.type}</td>
          //           </tr>
          //           <tr>
          //             <td>Purpose</td>
          //             <td>{viewData.purpose}</td>
          //           </tr>
          //           <tr>
          //             <td>From Type</td>
          //             <td>{viewData.fromType}</td>
          //           </tr>
          //           <tr>
          //             <td>To Type</td>
          //             <td>{viewData.toType}</td>
          //           </tr>
          //           <tr>
          //             <td>
          //               Transaction <br /> Status
          //             </td>
          //             <td>{viewData.status}</td>
          //           </tr>
          //           <tr>
          //             <td>Description</td>
          //             <td>{viewData.description}</td>
          //           </tr>
          //         </div>
          //       </tbody>
          //     </table>
          //   </DialogContent>
          // </BootstrapDialog>
          <Dialog
            fullScreen={fullScreen}
            open={dialog}
            onClose={handleCloseDialog}
          >
            <Box sx={style}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "blue" }}>
                    <TableCell
                      colSpan={2}
                      sx={{
                        textAlign: "center",
                        color: "#ffff",
                        fontSize: "20px",
                        fontFamily: "cursive",
                      }}
                    >
                      Transaction Details
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      Item Name
                    </TableCell>

                    <TableCell>{viewData.item.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      Price Per Item
                    </TableCell>
                    <TableCell>{viewData.pricePerItem}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      Quantity
                    </TableCell>
                    <TableCell>{viewData.quantity}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      Mode
                    </TableCell>
                    <TableCell>{viewData.trasactionMode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      Type
                    </TableCell>
                    <TableCell>{viewData.type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      Purpose
                    </TableCell>
                    <TableCell>{viewData.purpose}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      From Type
                    </TableCell>
                    <TableCell>{viewData.fromType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      To Type
                    </TableCell>
                    <TableCell>{viewData.toType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      Transaction Status
                    </TableCell>
                    <TableCell>{viewData.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", p: 1.5 }}>
                      Description
                    </TableCell>
                    <TableCell>{viewData.description}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Dialog>
        ) : null}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Paper style={{ margin: "15px 0" }}>
          <form onSubmit={handleSubmit}>
            <Box>
              <Grid container spacing={1} p={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Item Details
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl required fullWidth size="small">
                    <InputLabel>Item </InputLabel>
                    <Select
                      name="itemId"
                      label="Item"
                      value={itemId || ""}
                      onChange={handleChange}
                    >
                      {inventoryItem.map((items) => {
                        return (
                          <MenuItem key={items._id} value={items._id}>
                            {items.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Price Per Item"
                    value={pricePerItem}
                    name="pricePerItem"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Quantity"
                    value={quantity}
                    name="quantity"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ fontSize: "15px", fontWeight: "bold", marginTop: 1 }}
                >
                  Transaction Details
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Mode</InputLabel>
                    <Select
                      label="small"
                      name="trasactionMode"
                      value={trasactionMode}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                      <MenuItem value={"Upi"}>UPI</MenuItem>
                      <MenuItem value={"Netbanking"}>Net Banking</MenuItem>
                      <MenuItem value={"Cheque"}>Cheque</MenuItem>
                      <MenuItem value={"Card"}>Card</MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                      <MenuItem value={"Null"}>Null</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl
                    size="small"
                    required
                    fullWidth
                    style={{ margin: 1 }}
                  >
                    <InputLabel>Type</InputLabel>
                    <Select
                      name="type"
                      value={type}
                      label="Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={"In"}>In</MenuItem>
                      <MenuItem value={"Out"}>Out</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Purpose</InputLabel>
                    <Select
                      label="Purpose"
                      name="purpose"
                      value={purpose}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Inhouse"}>Inhouse</MenuItem>
                      <MenuItem value={"Damage"}>Damage</MenuItem>
                      <MenuItem value={"Purchase"}>Purchase</MenuItem>
                      {/* <MenuItem value={"Sell"}>Sell</MenuItem> */}
                      <MenuItem value={"Return"}>Return</MenuItem>
                      <MenuItem value={"InventoryUpdate"}>
                        Inventory Update
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ fontSize: "15px", fontWeight: "bold", marginTop: 1 }}
                >
                  Transaction Between
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>From Type</InputLabel>
                    <Select
                      label="From Type"
                      value={fromType}
                      name="fromType"
                      onChange={handleFromTypeChange}
                    >
                      <MenuItem value={"School"}>School</MenuItem>
                      <MenuItem value={"Vendor"}>Vendor</MenuItem>
                    </Select>
                  </FormControl>
                  {fromType === "School" ? (
                    <Grid item xs={12} md={12} lg={12} mt={1}>
                      <FormControl size="small" required fullWidth>
                        <InputLabel>School</InputLabel>
                        <Select
                          label="School"
                          name="fromSchoolId"
                          value={transactionFrom.fromSchoolId || ""}
                          onChange={(e) =>
                            setTransactionFrom({
                              fromSchoolId: e.target.value,
                              vendorId: "",
                            })
                          }
                        >
                          {settings.map((data) => (
                            <MenuItem key={data._id} value={data._id}>
                              {data.schoolName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : fromType === "Vendor" ? (
                    <Grid item xs={12} md={12} lg={12} mt={1}>
                      <FormControl size="small" required fullWidth>
                        <InputLabel>Vendors</InputLabel>
                        <Select
                          label="Vendors"
                          name="from"
                          value={transactionFrom.vendorId}
                          onChange={(e) =>
                            setTransactionFrom({
                              fromSchoolId: "",
                              vendorId: e.target.value,
                            })
                          }
                        >
                          {vendorData.map((items) => {
                            return (
                              <MenuItem key={items._id} value={items._id}>
                                {items.basicInfo.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>To Type</InputLabel>
                    <Select
                      label="To Type"
                      name="to"
                      value={toType}
                      onChange={handleToTypeChange}
                    >
                      <MenuItem value={"School"}>School</MenuItem>
                      <MenuItem value={"Vendor"}>Vendor</MenuItem>
                      <MenuItem value={"Employee"}>Employee</MenuItem>
                      <MenuItem value={"Student"}>Student</MenuItem>
                    </Select>
                  </FormControl>
                  {toType === "School" ? (
                    <Grid item xs={12} md={12} lg={12} mt={1}>
                      <FormControl size="small" required fullWidth>
                        <InputLabel>School</InputLabel>
                        <Select
                          label="School"
                          name="fromSchoolId"
                          value={transactionTo.toSchoolId || ""}
                          onChange={(e) =>
                            setTransactionTo({
                              toSchoolId: e.target.value,
                              vendorId: "",
                              studentId: "",
                              employeeId: "",
                            })
                          }
                        >
                          {settings.map((data) => (
                            <MenuItem key={data._id} value={data._id}>
                              {data.schoolName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : toType === "Vendor" ? (
                    <Grid item xs={12} md={12} lg={12} mt={1}>
                      <FormControl size="small" required fullWidth>
                        <InputLabel>Vendor</InputLabel>
                        <Select
                          label="Vendor"
                          name="to"
                          value={transactionTo.vendorId}
                          onChange={(e) =>
                            setTransactionTo({
                              toSchoolId: "",
                              vendorId: e.target.value,
                              studentId: "",
                              employeeId: "",
                            })
                          }
                        >
                          {vendorData.map((items) => {
                            return (
                              <MenuItem key={items._id} value={items._id}>
                                {" "}
                                {items.basicInfo.name}{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : toType === "Employee" ? (
                    <>
                      <Grid item xs={12} md={12} lg={12} mt={1}>
                        <FormControl size="small" required fullWidth>
                          <InputLabel>Employee</InputLabel>
                          <Select
                            label="Employee"
                            name="to"
                            value={transactionTo.employeeId}
                            onChange={(e) =>
                              setTransactionTo({
                                toSchoolId: "",
                                vendorId: "",
                                studentId: "",
                                employeeId: e.target.value,
                              })
                            }
                          >
                            {employeeData.map((role) => (
                              <MenuItem key={role._id} value={role._id}>
                                {role.basicInfo.empName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {addForm.toMeetUserType && (
                          <Grid item xs={12} md={12} lg={12}>
                            <FormControl size="small" required fullWidth>
                              <InputLabel>Employee Name</InputLabel>
                              <Select
                                label="Employee Name"
                                value={addForm.toMeetUser}
                                onChange={handleAddForm}
                                name="toMeetUser"
                              >
                                {schoolEmployee.map((emp) => (
                                  <MenuItem key={emp._id} value={emp._id}>
                                    {emp.basicInfo.empName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  ) : toType === "Student" ? (
                    <>
                      <Grid item xs={12} md={12} lg={12} mt={1}>
                        <FormControl size="small" required fullWidth>
                          <InputLabel>Class</InputLabel>
                          <Select
                            label="Class"
                            name="className"
                            value={formData.className}
                            onChange={handleClassChange}
                          >
                            {classes &&
                              classes.map((row, index) => (
                                <MenuItem key={row._id} value={row._id}>
                                  {row.className}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        {formData.className && (
                          <Grid item xs={12} md={12} lg={12} mt={1}>
                            <FormControl size="small" required fullWidth>
                              <InputLabel> Section</InputLabel>
                              <Select
                                label="Section"
                                name="section"
                                value={formData.section || ""}
                                onChange={handleOnChange}
                              >
                                {sections.map((row, index) => (
                                  <MenuItem key={row._id} value={row._id}>
                                    {row.sectionName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}

                        {formData.className && (
                          <Grid item xs={12} md={12} lg={12} mt={1}>
                            <FormControl size="small" required fullWidth>
                              <InputLabel>Student Name</InputLabel>
                              <Select
                                label="Student Name"
                                name="name"
                                value={formData.name}
                                onChange={handleOnChange}
                              >
                                {student.map((row, index) => (
                                  <MenuItem key={row._id} value={row._id}>
                                    {row.basicInfo.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Transaction Status</InputLabel>
                    <Select
                      label="Transction Status"
                      value={status}
                      name="status"
                      onChange={handleChange}
                    >
                      {/* <MenuItem value={"Pending"}>Pending</MenuItem> */}
                      <MenuItem value={"Completed"}>Completed</MenuItem>
                      <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12} mt={1}>
                  <TextareaAutosize
                    InputLabelProps={{
                      shrink: true,
                    }}
                    maxRows={4}
                    name="description"
                    value={description}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      // marginLeft: 10,
                      height: 100,
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    aria-label="maximum height"
                    placeholder="Drop a note"
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* <div> */}
                      <Button
                        sx={{
                          color: "#1b3779",
                          "&:hover": { color: "#1b3779" },
                          textTransform: "none",
                          fontSize: "15px",
                          fontWeight: "bold",
                          marginLeft: "-15px",
                        }}
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          label="Invoices"
                          accept=".pdf, .doc, .docx, .txt"
                          type="file"
                          onChange={handleChangePhoto}
                        />
                        Upload Invoice
                      </Button>
                      {selectedFile && selectedFile.name && (
                        <p>Uploaded file: {selectedFile.name}</p>
                      )}
                      {/* </div> */}
                    </div>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                  gap={1}
                >
                  <Tooltip title="Cancel">
                    <Button
                      variant="contained"
                      color="error"
                      size="medium"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>

                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        m: 1,
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Done
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              ></div>
            </Box>
          </form>
        </Paper>
      </TabPanel>
    </Wrapper>
  );
};

export default Transaction;
