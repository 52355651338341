import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  styled,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import DeleteModal from "../Layout/Academic/DeleteModal";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete, Search } from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   height: 450,
//   bgcolor: "background.paper",
//   p: 4,
// };

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "750px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ContainerBox1 = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxWidth: "550px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

// const modal = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",

//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };
const Periodical = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [list, setList] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [file, setFile] = useState();
  const [openUpload, setOpenUpload] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editPerdiodical, setEditPeriodical] = useState();
  const [addForm, setAddForm] = useState({});
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [countDownloadLoader, setCountDownloadLoader] = useState(false);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(false);

  useEffect(() => {
    getperiodical();
  }, [selectedSetting]);
  const getperiodical = async () => {
    try {
      const data = await get(urls.periodical.getList);

      setList(data.data.data);
      console.log(data.data.data, "ddaaaaaaattaaaaa");
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    button: {
      color: "#ffff",
      background: "rgb(27, 55, 121)",
      ":hover": { background: "rgb(27, 55, 121)" },
    },

    textfield: {
      marginTop: "25px",
      marginRight: "5%",
    },

    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.periodicalTitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.periodical.delPeriodical}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    setDownloadLoader(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/periodical/downloadexcel`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `periodical-stock.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setDownloadLoader(false);
  };

  const handleGetCountDownloadSheet = async (e) => {
    setCountDownloadLoader(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/periodical/downloadcountexcel`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `periodical-count.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setCountDownloadLoader(false);
  };

  const handleGetSampleSheet = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/periodical/downloadsample`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `sample-periodicalfile.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditPeriodical(null);
    setOpenDialog(false);
    resetForm();
  };
  const resetForm = () => {
    setAddForm({});
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const handleImages = (e) => {
    setImages((prev) => [...prev, ...e.target.files]);
  };
  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      if (!editPerdiodical) {
        let imageIds = [];
        const formImage = new FormData();
        if (images.length > 0) {
          for (const img of images) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage);
          imageIds = res.data.result.map((id) => id._id);
        }
        const { status } = await post(`${urls.periodical.create}`, {
          periodicalTitle: addForm.periodicalTitle,
          type: addForm.type,
          department: addForm.department,
          issueNumber: addForm.issueNumber,
          volumeNumber: addForm.volumeNumber,
          quantity: addForm.quantity,
          price: addForm.price,
          entrydate: date,
          backCover: imageIds,
        });
        getperiodical();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.periodical.editPerdiodical}${editPerdiodical}`,
          "",
          {
            periodicalTitle: addForm.periodicalTitle,
            type: addForm.type,
            department: addForm.department,
            issueNumber: addForm.issueNumber,
            volumeNumber: addForm.volumeNumber,
            quantity: addForm.quantity,
            price: addForm.price,
            entrydate: date,
            // backCover: imageIds,
          }
        );
        getperiodical();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleTypeChange = (e) => {
    if (e.target.name === "bookCover") {
      setAddForm({
        ...addForm,
        bookCover: e.target.files[0],
      });
    } else {
      {
        setAddForm({
          ...addForm,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleEditChange = (id, data) => {
    setEditPeriodical(id);
    setAddForm({
      periodicalTitle: data.periodicalTitle,
      type: data.type,
      department: data.department,
      issueNumber: data.issueNumber,
      volumeNumber: data.volumeNumber,
      quantity: data.quantity,
      price: data.price,
      entrydate: date,
      // backCover: images.links,
    });
    handleClickOpen();
  };

  const handleSheetUpload = async (e) => {
    setBulkUploadLoader(true);
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);
      const response = await put(
        `/${selectedSetting._id}/periodical/uploadsample`,
        "",
        formData
      );
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      getperiodical();
      handleClose();
    } catch (error) {
      console.error(error);
    }
    setBulkUploadLoader(false);
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Periodical List" />

      <Box sx={{ margin: "15px 0" }}>
        {/* <Paper sx={{ width: "100%", p: "1%", overflow: "hidden" }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",

              alignItems: "center",
              justifyContent: "flex-end",
            }}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search"
                variant="outlined"
                placeholder="enter perodical "
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid> */}
        {/* <Grid item xs={12} md={6} lg={2}>
              <Button variant="contained" onClick={handleClickOpen}>
                <AddIcon />
                Add Periodical
              </Button>
            </Grid> */}
        {/* </Grid>
        </Paper> */}
        {/* <Paper sx={{ width: "100%", p: "1%", overflow: "hidden" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              // alignItems: "center",
            }}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  size="small"
                  fullWidth
                  style={{ width: "200px" }}
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  placeholder="enter perodical "
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  // sx={{ mt: 3.2, ml: -5,pt:"7px",pb:"7px" }}
                  // style={styles.addbutton}
                  onClick={handleClickOpen}
                  size="small"
                  style={{ marginLeft: "80px" }}>
                  Add Periodical
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper> */}

        <Modal
          open={bulkUpdateModal}
          onClose={() => setBulkUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ContainerBox1>
            <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Bulk Upload
              </span>
            </Box>
            <form onSubmit={handleSheetUpload}>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Box>
                  <Tooltip title="GetSample">
                    <LoadingButton
                      variant="contained"
                      // size="small"
                      type="submit"
                      loading={loadingBtn}
                      onClick={handleGetSampleSheet}
                    >
                      Sample
                    </LoadingButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box>
                    <Tooltip title="Upload">
                      <Button variant="contained" component="label">
                        Upload
                        <input
                          name="bulkUpdate"
                          onChange={handleFileChange}
                          hidden
                          accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                          type="file"
                        />
                      </Button>
                    </Tooltip>

                    <Box>{fileChoosen && fileChoosen.name}</Box>
                  </Box>
                </Box>
                <Box>
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={bulkUploadLoader}
                      variant="contained"
                      type="submit"
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Box>
              </Box>
            </form>
          </ContainerBox1>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <ContainerBox>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                fontSize="20px"
                fontWeight="bold"
                // onChange={handleAddForm}
              >
                {editPerdiodical ? "update" : "Add"}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Periodical Title"
                    name="periodicalTitle"
                    value={addForm.periodicalTitle || ""}
                    onChange={handleTypeChange}
                    sx={{
                      // m: 1,
                      // minWidth: { md: 227, xs: "100%" },
                      // label: { fontSize: 12 },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    // sx={{ m: 0.3, minWidth: { md: 227, xs: "100%" } }}
                  >
                    <InputLabel sx={{ fontSize: 8 }}>Type</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="type"
                      name="type"
                      value={addForm.type || ""}
                      onChange={handleTypeChange}
                    >
                      <MenuItem value={"journal"}>journal</MenuItem>
                      <MenuItem value={"magazines"}>magazines</MenuItem>
                      <MenuItem value={"newspaper"}>newspaper</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    label="Department"
                    name="department"
                    value={addForm.department || ""}
                    onChange={handleTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    label="Issue Number"
                    name="issueNumber"
                    value={addForm.issueNumber || ""}
                    onChange={handleTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Valume Number"
                    name="volumeNumber"
                    value={addForm.volumeNumber || ""}
                    onChange={handleTypeChange}
                    sx={{
                      // m: 1,
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    required
                    id="filled-required"
                    size="small"
                    label="Quantity"
                    name="quantity"
                    value={addForm.quantity || ""}
                    onChange={handleTypeChange}
                    sx={{
                      // m: 1,
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    label="Price"
                    name="price"
                    value={addForm.price || ""}
                    onChange={handleTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      value={date}
                      onChange={(newDate) => setDate(newDate)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          size="small"

                          // sx={{ marginTop: "5px",width:{md:227,xs:"100%"} }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  {editPerdiodical ? (
                    ""
                  ) : (
                    <Box sx={{ marginTop: "2px", display: "flex" }}>
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          // m: 1,
                          // minWidth: { md: 227, xs: "100%" },
                          label: { fontSize: 12 },
                        }}
                      >
                        <label style={{ fontWeight: "bold" }}>Book Cover</label>
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={handleImages}
                        />
                      </FormControl>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <LoadingButton
                        loading={loader}
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </ContainerBox>
          </form>
        </Modal>

        {/* <Paper
          sx={{
            padding: "10px",
            margin: "15px 0",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start", sm: "center" },
            alignItems: "center",
          }}>
          <Stack spacing={2} direction="row">
            <LoadingButton
              loading={downloadLoader}
              sx={styles.button}
              onClick={handleGetDownloadSheet}>
              Download
            </LoadingButton>
            <LoadingButton
              loading={countDownloadLoader}
              sx={styles.button}
              onClick={handleGetCountDownloadSheet}>
              Count By title
            </LoadingButton>
            <Button sx={styles.button} onClick={() => setBulkUpdateModal(true)}>
              Bulk Upload
            </Button>
          </Stack>
          <TextField
            size="small"
            fullWidth
            style={{ width: "200px" }}
            value={search}
            onChange={handleSearch}
            label="Search"
            variant="outlined"
            placeholder="enter perodical "
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Paper> */}
        <Paper
          sx={{
            padding: "10px",
            margin: "15px 0",
            display: "flex",
            flexDirection: { xs: "column", md: "row", sm: "column" }, // Adjusted flexDirection
            justifyContent: { xs: "center", md: "space-between", sm: "center" }, // Adjusted justifyContent
            alignItems: "center",
          }}
        >
          <Stack spacing={2} direction="row">
            <LoadingButton
              loading={downloadLoader}
              sx={styles.button}
              onClick={handleGetDownloadSheet}
            >
              Download
            </LoadingButton>
            <LoadingButton
              loading={countDownloadLoader}
              sx={styles.button}
              onClick={handleGetCountDownloadSheet}
            >
              Count By title
            </LoadingButton>
            <Button sx={styles.button} onClick={() => setBulkUpdateModal(true)}>
              Bulk Upload
            </Button>
          </Stack>
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            placeholder="Enter book name"
            label="Search"
            variant="outlined"
            sx={{
              marginTop: {
                xs: "10px",
                sm: "10px",
                md: "0px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Paper>

        <Modal
          open={openUpload}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag">
            <ContainerBox>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                // onChange={handleAddForm}
              >
                Bulk Upload
              </Typography>

              <Box sx={{ marginTop: "2px", display: "flex" }}>
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                >
                  <label>Upload File</label>
                  <input type="file" onChange={handleChange} />
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { md: "right", sm: "right", xs: "center" },
                }}
              >
                <Tooltip title="Cancel">
                  <Button
                    onClick={handleCloseUpload}
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{ m: 1 }}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={styles.button}
                  >
                    Add
                  </Button>
                </Tooltip>
              </Box>
            </ContainerBox>
          </form>
        </Modal>
        <Box overflow="scroll">
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Title
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Type
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Valume Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Book Cover
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Price
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Quantity
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Journal Entry Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {data.periodicalTitle}
                      </TableCell>
                      <TableCell align="center">{data.type}</TableCell>
                      <TableCell align="center">{data.issueNumber}</TableCell>
                      <TableCell align="center">{data.volumeNumber}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.backCover ? (
                          <img
                            src={data.backCover.link}
                            alt="NA"
                            style={{
                              width: "100px",
                              height: "60px",
                              resize: "contain",
                            }}
                          ></img>
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell align="center">{data.price}</TableCell>
                      <TableCell align="center">{data.quantity}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.entrydate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{ color: "#1b3779" }}
                            onClick={() => handleEditChange(data._id, data)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            sx={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(data._id, data)}
                          >
                            <Delete />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={data._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                : list.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {data.periodicalTitle}
                      </TableCell>
                      <TableCell align="center">{data.type}</TableCell>
                      <TableCell align="center">{data.issueNumber}</TableCell>
                      <TableCell align="center">{data.volumeNumber}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.backCover ? (
                          <img
                            src={data.backCover.link}
                            alt="NA"
                            style={{
                              width: "100px",
                              height: "60px",
                              resize: "contain",
                            }}
                          ></img>
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell align="center">{data.price}</TableCell>
                      <TableCell align="center">{data.quantity}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.entrydate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{ color: "#1b3779" }}
                            onClick={() => handleEditChange(data._id, data)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            sx={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(data._id, data)}
                          >
                            <Delete />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={data._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </Box>
      <Box className="add-icon">
        <Fab
          variant="contained"
          sx={{
            color: "#ffff",

            background: "rgb(27, 55, 121)",
            ":hover": { background: "rgb(27, 55, 121)" },
          }}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      </Box>
    </Wrapper>
  );
};

export default Periodical;
